import { axiosInstance } from "../utills"

export const getLegalChatHistory = async(userid)=>{
    try{

 const result = await axiosInstance.get(`https://legal.kuru.ai/chat/get_chats?user_id=${userid}`)
 return result.data
    }
    catch (error) {
        console.error('Error Getting data:', error);
        throw new Error('Failed to Get data. Please try again later.');
    }
}
export const getSepcificChatApi= async(session_id)=>{
    try{
        console.log(session_id)
 const result = await axiosInstance.get(`https://legal.kuru.ai/chat/get_chat_by_id/${session_id}`)
 return result.data
    }
    catch (error) {
        console.error('Error Getting data:', error);
        throw new Error('Failed to Get data. Please try again later.');
    }
}
export const createLegalChatHistory = async(userid)=>{

    try{
        const formData = new FormData();
        formData.append("user_id", userid);
        const result = await axiosInstance.post(`https://legal.kuru.ai/chat/create_new_chat`,formData,  {
            headers: {
                "Content-Type": "multipart/form-data",  // Set correct content type
            },
        })
        return result.data


    }
    catch (error) {
        console.error('Error Posting data:', error);
        throw new Error('Failed to Post data. Please try again later.');
    }
}

export const sendSpecificChat = async (currentChatId, inputMessage, files,userid) => {
    try {
 
        const formData = new FormData();
        formData.append("session_id", currentChatId);
        formData.append("user_message", inputMessage);
        formData.append("user_id", userid);

        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i]);  
            }
        }

        const result = await axiosInstance.post(
            `https://legal.kuru.ai/chat/ask_query`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",  // Set correct content type
                },
            }
        );

        return result.data;
    } catch (error) {
        console.error("Error Posting data:", error);
        throw new Error("Failed to Post data. Please try again later.");
    }
};


export const deleteLegalChatHistory = async(session_id)=>{
    try{
        const result = await axiosInstance.delete(`https://legal.kuru.ai/chat/delete_chat/${session_id}`)
        return result

    }

    catch(error){
        console.error('Error at Deleting Chat History:', error)
        throw new Error('Failed to Delete Data. Please try again later.')
    }
}

export const createLegalRule = async(user_id,rule_name,rule_description)=>{

    try{
        const formData = new FormData();
        formData.append("user_id", user_id);
        formData.append("rule_name", rule_name);
        formData.append("rule_description", rule_description);

        const result = await axiosInstance.post(`https://legal.kuru.ai/rules/add_rule`,     formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
          )
        return result.data


    }
    catch (error) {
        console.error('Error Posting data:', error);
        throw new Error('Failed to Post data. Please try again later.');
    }
}

export const getLegalRules = async(userid)=>{
    try{

 const result = await axiosInstance.get(`https://legal.kuru.ai/rules/get_rules?user_id=${userid}`)
 return result.data
    }
    catch (error) {
        console.error('Error Getting data:', error);
        throw new Error('Failed to Get data. Please try again later.');
    }
}

export const updateLegalRule = async(rule_id,rule_name,rule_description,user_id)=>{

    try{
        
        const result = await axiosInstance.put(`https://legal.kuru.ai/rules/update_rule/${rule_id}`,  {
            user_id:user_id,
            rule_name: rule_name,
            rule_description: rule_description
          }
          )
        return result.data


    }
    catch (error) {
        console.error('Error Posting data:', error);
        throw new Error('Failed to Post data. Please try again later.');
    }
}

export const deleteLegalRule = async(rule_id)=>{

    try{
        
        const result = await axiosInstance.delete(`https://legal.kuru.ai/rules/delete_rule/${rule_id}`
          )
        return result.data


    }
    catch (error) {
        console.error('Error Posting data:', error);
        throw new Error('Failed to Post data. Please try again later.');
    }
}


export const deleteAllLegalRule = async(user_id)=>{

    try{
        
        const result = await axiosInstance.post(`https://legal.kuru.ai/rules/delete_rule/${user_id}`
          )
        return result.data


    }
    catch (error) {
        console.error('Error Posting data:', error);
        throw new Error('Failed to Post data. Please try again later.');
    }
}