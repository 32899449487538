import { axiosInstance } from "../utills";

export const getSummaryAnalysis = async(fromdate,todate,role)=>{
    console.log(fromdate,todate,role)
    try{
        const response = await axiosInstance.get(`https://zoom.kuru.ai/summary_analysis?from_date=${fromdate}&to_date=${todate}&role=${role}`)
        const arrayResult = response.data[0]
        if(role=="discovery")
        {
          return arrayResult.discovery
        }
        else{
            return arrayResult.strategy

        }
      }
      catch(error)
      {
          console.error('Error getting data:', error);
          if (error.response) {
              console.error('Server responded with:', error.response.data);  
          }
          throw new Error('Failed to get data. Please try again later.');
      
      }
}

export const kpiApi = async(fromdate,todate)=>{
    try{
  const kpidata = await axiosInstance.post(`https://zoom.kuru.ai/session_kpi`,{from_date:fromdate,to_date:todate})
  return kpidata.data
    }
    catch(error)
    {
        console.error('Error getting data:', error);
        if (error.response) {
            console.error('Server responded with:', error.response.data);  
        }
        throw new Error('Failed to get data. Please try again later.');
    
    }
}

export const lastPerformanceTrend = async(fromdate,todate,sessionType)=>{
    try{
  const kpidata = await axiosInstance.post(`https://zoom.kuru.ai/session_performance_kpi`,{from_date:fromdate,to_date:todate,session_type:sessionType})
  return kpidata.data
    }
    catch(error)
    {
        console.error('Error getting data:', error);
        if (error.response) {
            console.error('Server responded with:', error.response.data);  
        }
        throw new Error('Failed to get data. Please try again later.');
    
    }
}

export const mainPerformanceTrend = async(fromdate,todate,selectedUser)=>{

    try{
console.log("this is selecteduser",selectedUser)
  const kpidata = await axiosInstance.post(`https://zoom.kuru.ai/performance_trend_kpi`,{from_date:fromdate,to_date:todate,email:selectedUser})
  return kpidata.data
    }
    catch(error)
    {
        console.error('Error getting data:', error);
        if (error.response) {
            console.error('Server responded with:', error.response.data);  
        }
        throw new Error('Failed to get data. Please try again later.');
    
    }
}