import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Users } from "lucide-react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);




export function PerformanceTrend({mainPerformanceDetails,selectedUser,setSelectedUser,performanceTrendActiveTab,setPerformanceTrendActiveTab,selectAllUser}) {
  console.log("mainperformance",mainPerformanceDetails.specialist?.map(data=> data.date))
  // const [activeTab, setPerformanceTrendActiveTab] = useState("discovery");
  console.log("heyy",mainPerformanceDetails)

const filteredSpecialist = mainPerformanceDetails.specialist?.filter(
  (data) => Object.keys(data.graphdata || {}).length > 0
);

const filteredStrategist = mainPerformanceDetails.strategist?.filter(
  (data) => Object.keys(data.graphdata || {}).length > 0
);

const discoveryMetrics = {
  introduction: { data: filteredSpecialist?.map(data => data.graphdata.introduction), color: "#FF6384" },
  cashFlowTour: { data: filteredSpecialist?.map(data => data.graphdata.cash_flow_tour), color: "#36A2EB" },
  closingAndFollowUp: { data: filteredSpecialist?.map(data => data.graphdata.closing_and_follow_up), color: "#FFCE56" },
  freedomMethod: { data: filteredSpecialist?.map(data => data.graphdata.freedom_method_presentation), color: "#4BC0C0" },
  memberUnderstanding: { data: filteredSpecialist?.map(data => data.graphdata.member_understanding_and_journey), color: "#9966FF" },
  preparation: { data: filteredSpecialist?.map(data => data.graphdata.preparation), color: "#FF9F40" },
  theGap: { data: filteredSpecialist?.map(data => data.graphdata.the_gap), color: "#FF6384" },
  unpack: { data: filteredSpecialist?.map(data => data.graphdata.unpack), color: "#4BC0C0" },
  overallScore: { data: filteredSpecialist?.map(data => data.graphdata.overall_assessment), color: "#1f9ad6" },
};

const strategistMetrics = {
  rapportBuild: { data: filteredStrategist?.map(data => data.graphdata.rapport_building), color: "#FF6384" },
  qualifyingAppointment: { data: filteredStrategist?.map(data => data.graphdata.appointment_qualification), color: "#36A2EB" },
  setToneEarly: { data: filteredStrategist?.map(data => data.graphdata.set_tone_early), color: "#FFCE56" },
  setAgenda: { data: filteredStrategist?.map(data => data.graphdata.set_agenda), color: "#4BC0C0" },
  unpackDiscoveryQuestions: { data: filteredStrategist?.map(data => data.graphdata.discovery_questions), color: "#9966FF" },
  freedomKnowledge: { data: filteredStrategist?.map(data => data.graphdata.knowledge_check), color: "#FF9F40" },
  personalStory: { data: filteredStrategist?.map(data => data.graphdata.personal_story), color: "#FF6384" },
  financeAssessment1: { data: filteredStrategist?.map(data => data.graphdata.finance_assessment_page1), color: "#36A2EB" },
  financeAssessment2: { data: filteredStrategist?.map(data => data.graphdata.finance_assessment_page2), color: "#FFCE56" },
  lazyEquity: { data: filteredStrategist?.map(data => data.graphdata.lazy_equity_smsf), color: "#4BC0C0" },
  cashflowSheet: { data: filteredStrategist?.map(data => data.graphdata.cashflow_sheet), color: "#9966FF" },
  ultimatePropertyPlanner: { data: filteredStrategist?.map(data => data.graphdata.ultimate_property_planner), color: "#FF9F40" },
  freedomCriteria: { data: filteredStrategist?.map(data => data.graphdata.freedom_criteria), color: "#FF6384" },
  recapGetMemberDecision: { data: filteredStrategist?.map(data => data.graphdata.recap_decision), color: "#36A2EB" },
  securePropertySessionTime: { data: filteredStrategist?.map(data => data.graphdata.secure_property_session), color: "#FFCE56" },
  askForReferral: { data: filteredStrategist?.map(data => data.graphdata.ask_for_referral), color: "#4BC0C0" },
  expectationDecision: { data: filteredStrategist?.map(data => data.graphdata.expectations_decision), color: "#9966FF" },
  finalRecap: { data: filteredStrategist?.map(data => data.graphdata.final_recap), color: "#FF9F40" },
};

const dateSpecialist = filteredSpecialist?.map(data => data.date);
const dateStrategist = filteredStrategist?.map(data => data.date);
const months = performanceTrendActiveTab === "discovery" ? dateSpecialist : dateStrategist;

const metrics = performanceTrendActiveTab === "discovery" ? discoveryMetrics : strategistMetrics;

  
  const chartData = {
    labels: months,
    datasets: Object.entries(metrics).map(([key, value]) => ({
      label: key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase()),
      data: value.data,
      borderColor: value.color,
      backgroundColor: `${value.color}33`,
      tension: 0.4,
      borderWidth: key === "overallScore" ? 3 : 1,
      pointRadius: key === "overallScore" ? 4 : 2,
    })),
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: { mode: "index", intersect: false },
    plugins: {
      legend: {
        position: "right",
        align: "start",
        labels: { boxWidth: 12, padding: 15, font: { size: 11 } },
      },
      tooltip: { mode: "index", intersect: false },
    },
    scales: {
      y: { min: 0, max: 5, ticks: { stepSize: 1, font: { size: 11 } } },
      x: { ticks: { font: { size: 11 } } },
    },
  };

  return (
    <div style={{"padding":"1.5rem","borderRadius":"0.5rem","backgroundColor":"#ffffff","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)"}}>
      <h2 style={{ fontSize: "1.25rem", fontWeight: "600", color: "#1a202c", marginBottom: "1rem" }}>Performance Trend</h2>
      <div style={{ display: "flex", borderBottom: "1px solid #e2e8f0", marginBottom: "1rem" }}>
        <button
           style={{
            padding: "0.5rem 1rem",
            fontSize: "0.875rem",
            fontWeight: "500",
            border: "none",
            borderBottom: performanceTrendActiveTab === "discovery" ? "2px solid #1f9ad6" : "2px solid transparent",
            color: performanceTrendActiveTab === "discovery" ? "#1f9ad6" : "#6c757d",
            backgroundColor: "transparent",
          }}
          onClick={() => {setPerformanceTrendActiveTab("discovery") ;           setSelectedUser("")
}}
        >
          Discovery Session
        </button>
        <button
        style={{
            padding: "0.5rem 1rem",
            fontSize: "0.875rem",
            fontWeight: "500",
            border: "none",
            borderBottom: performanceTrendActiveTab === "strategist" ? "2px solid #1f9ad6" : "2px solid transparent",
            color: performanceTrendActiveTab === "strategist" ? "#1f9ad6" : "#6c757d",
            backgroundColor: "transparent",
          }}
          onClick={() => {
            setPerformanceTrendActiveTab("strategist");
            setSelectedUser("");
          }}        >
          Strategist Session
        </button>
      </div>
      <div style={{"display":"flex","marginBottom":"1.5rem","justifyContent":"flex-end","alignItems":"center"}}>
        <div style={{"position":"relative"}}>
        <select
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
          style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingRight":"1rem","paddingLeft":"2rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","fontSize":"0.875rem","lineHeight":"1.25rem","backgroundColor":"#ffffff","appearance":"none",":hover":{"borderColor":"#9CA3AF"}}}
        >
          {selectAllUser.map((user)=><option key={user.email} value={user.email}>
              {user.name}
            </option>)}
          {/* {performanceTrendActiveTab === "discovery" ? mainPerformanceDetails.specialist_users?.map((user) => (
            <option key={user.email} value={user.email}>
              {user.name}
            </option>
          )) : mainPerformanceDetails.strategist_users?.map((user) => (
            <option key={user.email} value={user.email}>
              {user.name}
            </option>
          )) } */}
        </select>
        <Users style={{"position":"absolute","left":"0.5rem","top":"30%","width":"1rem","height":"1rem","color":"#9CA3AF","pointerEvents":"none"}} />
      </div>
      </div>
      <div style={{ height: "300px" }}>
        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
}
