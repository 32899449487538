// RoleFilter.js
import React from "react";
import { Briefcase, UserCircle } from "lucide-react";
export function RoleFilter({ roles, selectedRole, onRoleChange }) {
  return (
    <div className="card shadow-sm mb-3">
      <div className="card-body">
        <div
          className="d-flex align-items-center mb-3"
        >
          <UserCircle
            className="me-2" size={20} style={{"color":"#1f9ad6"}}
          />
          &nbsp;
          <label htmlFor="role" style={{ fontWeight:500  }} >Role</label>
        </div>
        <select
          id="role"
          className="form-select"
          value={selectedRole}
          onChange={(e) => onRoleChange(e.target.value)}
          style={{ fontFamily: "system-ui" }}
        >
          {roles.map((role) => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
