// ZoomAnalysis.js
import React, { useState, useEffect, useContext } from 'react';
import { SessionCard } from '../component/SessionCard';
import { SessionDetail } from '../component/SessionDetail';
import { DateRangeFilter } from '../component/DateRangeFilter';
import { RepresentativeFilter } from '../component/RepresentativeFilter';
import { RoleFilter } from '../component/RoleFilter';
import { PerformanceFilter } from '../component/PerformanceFilter';
import PaginationControl from '../component/PaginationControl';
import { ViewSelector } from '../component/viewSelector';
import ExportButton from "../assets/export.svg";
import { fetchSessionData, getTags, normalizeSessionData, representativeData } from '../api/zoomanalysisApi';
import { SessionDetailStrategist } from '../component/SessionDetailStrategist';
import ExportData from '../component/ExportData';
import ValidityFilter from '../component/ValidityFilter';
import TagFilter from '../component/TagFilter';
import { ZoomSessionContext } from '../hooks/zoomSessionContext';
function ZoomAnalysis() {
  const [sessions, setSessions] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedRepresentative, setSelectedRepresentative] = useState('All Representatives');
  const [representatives, setRepresentatives] = useState(['All Representatives']);
  const [selectedRole, setSelectedRole] = useState('All');
  const [performaceScore, setPerformaceScore] = useState('All Scores');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1)
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [tempPage, updateTempPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentView, setCurrentView] = useState('grid'); // Default to grid view
  const [colClass, setColClass] = useState("col-md-3");
  const [showExport, setShowExport] = useState(false)
  const [validity,setValidity] = useState('')
  const [flag,setflag] = useState(false)
  const [listUniqueTags,setListUniqueTags] = useState([])
  const [selectedTags,setSelectedTags] = useState([])
  const [tagsFlag,setTagsFlag] = useState(false)
  const {sessionContext, setSessionContext,selectedSession,
    setSelectedSession,
    flagValidUpdate,
    setFlagValidUpdate,
    onBackFunction } = useContext(ZoomSessionContext);

  // Adjust layout for responsiveness
  useEffect(() => {

    const handleResize = () => {
      if (window.innerWidth < 990) {
        setColClass("col-md-6 mt-1");
      } else {
        setColClass("col-md-3");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Trigger initially

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function formatDate(date) {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  }
  
  // Assuming startDate and endDate are in 'yyyy-mm-dd' format
  useEffect(()=>{
  
    setCurrentPage(1)
    updateTempPage(1)
    setflag(prev=>!prev)
  },[itemsPerPage,  startDate,
    endDate,
    selectedRole,
    performaceScore,
    selectedRepresentative,validity,flagValidUpdate,selectedTags])
    useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true);
      setError(null);
      const formattedStartDate = startDate !="" ? formatDate(startDate) : "";
      const formattedEndDate = endDate !="" ? formatDate(endDate) : "";
      try {
     
        const data = await fetchSessionData(
          currentPage,
          itemsPerPage,
          formattedStartDate,
          formattedEndDate,
          selectedRole,
          performaceScore == "All Scores" ? "" : performaceScore,
          selectedRepresentative,
          validity,
          selectedTags
        );

       

        const normalizedData = normalizeSessionData(data.Analysis);
      
       setTotalPages(data.Total_Pages)
        setSessions(normalizedData);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, [
    currentPage,
   flag
  
    
  ]);

// const onBackFunction = ()=>{
//   setSelectedSession(null)
//   setFlagValidUpdate(prev =>!prev)
//   setSessionContext("")

// }
  useEffect(() => {
    const fetchRepresentatives = async () => {
        try {
            const data = await representativeData(selectedRole);
            const allReps = ['All Representatives', ...data];

            // If the current representative is not in the new list, reset it
            if (!allReps.includes(selectedRepresentative)) {
                setSelectedRepresentative('All Representatives');
            }

            setRepresentatives(allReps);
        } catch (err) {
            console.error('Error fetching representatives:', err);
            setError('Failed to fetch representatives.');
        }
    };

    fetchRepresentatives();
}, [selectedRole]); // Only depends on `selectedRole`

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

console.log(sessions)
useEffect(()=>{
  const gettingUniqueTags = async()=>{
    const dataTags = await getTags();
          setListUniqueTags(dataTags.session_tags.filter((tag) => !selectedTags.includes(tag)))

   
  }
  gettingUniqueTags()
},[selectedTags,tagsFlag])

// useEffect(()=>{
// },[])

  return (
    <div style={{"padding": "1.5rem" }}>
      {!selectedSession ? (
        <>
          <div className="row gx-3">
            <div className={`${colClass}`} >
              <DateRangeFilter
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={(date) => {
                  setStartDate(date);
                  setCurrentPage(1);
                }}
                onEndDateChange={(date) => {
                  setEndDate(date);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className={`${colClass}`}>
              <RoleFilter
                roles={["All", "Strategist", "Specialist"]}
                selectedRole={selectedRole}
                onRoleChange={(role) => {
                  setSelectedRole(role);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className={`${colClass}`}>
              <RepresentativeFilter
                representatives={representatives}
                selectedRepresentative={selectedRepresentative}
                onRepresentativeChange={(rep) => {
                  setSelectedRepresentative(rep);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className={`${colClass}`}>
              <PerformanceFilter
                ratings={[
                  "All Scores",
                  "From 4 to 5",
                  "From 3 to 4",
                  "Over 3",
                  "Under 3",
                ]}
                performaceScore={performaceScore}
                onPerformaceScoreChange={(rate) => {
                  setPerformaceScore(rate);
                  setCurrentPage(1);
                }}
              />

            </div>
            <div className="d-flex align-items-center justify-content-between mb-1" style={{marginTop:"8px"}}>
            <div className="col-md-4 me-3">
    <TagFilter availableTags={listUniqueTags} selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>
  </div>
  <div className="col-md-4 ">
    <ValidityFilter validityFilter={validity} setValidityFilter={setValidity}/>
  </div>
 
  <div className="ms-auto">  {/* Moves the button to the far right */}
    <button
      className="px-3 py-2 text-white rounded d-flex align-items-center"
      style={{
        backgroundColor: "#1f9ad6",
        border: "none",
        fontSize: "15px",
        fontWeight: 500,
        color: "white",
        whiteSpace: "nowrap",
      }}
      onClick={() => setShowExport((prev) => !prev)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        style={{ marginRight: "8px" }}
        viewBox="0 0 24 24"
        fill="none"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-download"
      >
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
        <polyline points="7 10 12 15 17 10" />
        <line x1="12" x2="12" y1="15" y2="3" />
      </svg>
      Export Data
    </button>
  </div>
</div>
          </div>

          {loading ? (
            <div className="text-center">Loading...</div>
          ) : error ? (
            <div className="alert alert-danger">Error: {error}</div>
          ) : (
            <>
              
              {showExport && <div className="" style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", 
      zIndex: 1000, 
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}> <ExportData setShowExport={setShowExport}/> </div>}
              <div className={`row g-3 ${currentView === "list" ? "flex-column" : ""}`} >
                {sessions.length > 0 ? sessions.map((session) => (
                  <div
                  style={{"marginTop":"24px"}}
                  onClick={()=>setSessionContext(session)}
                    className={currentView === "grid" ? "col-md-6" : "col-12"}
                    key={session.id}
                  >
                    <SessionCard
                      session={session}
                      onClick={setSelectedSession}
                      viewType={currentView}
                    />
                  </div>
                )) : <div className='text-center'> No Data Available</div>}
              </div>
              <div style={{marginTop:"24px"}}>
              <PaginationControl
                currentPage={currentPage}
                totalPages={totalPages}
                tempPage={tempPage}
                setCurrentPage={setCurrentPage}
                updateTempPage={updateTempPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
              />
              </div>
            </>
          )}
        </>
      ) : sessions.find((s) => s?.id === selectedSession)?.representative.role ==
        "Specialist" ? (
        <SessionDetail
          session={sessions.find((s) => s?.id == selectedSession)}
          setTagsFlag={setTagsFlag}
          tagsFlag={tagsFlag}
          listUniqueTags={listUniqueTags}
        />
      ) : (
        <SessionDetailStrategist
          session={sessions.find((s) => s?.id == selectedSession)}
          setTagsFlag={setTagsFlag}
          tagsFlag={tagsFlag}
          listUniqueTags={listUniqueTags}

        />
      )}
    </div>
  );
}

export default ZoomAnalysis;
