import { LayoutGrid, Table as TableIcon } from 'lucide-react';
import { SessionTables } from './SessionTables';
import SessionCards from './SessionCards';
import { useState } from 'react';

const SessionViews =({ discoverySessions, strategistSessions , loading})=>{
const [viewMode, setViewMode] = useState("table");
  const [activeTab, setActiveTab] = useState("discovery");



//   const SessionTables = ({ activeTab }) => (
//     <div>Table View: {activeTab}</div>
//   );

//   const SessionCards = ({ activeTab }) => (
//     <div>Cards View: {activeTab}</div>
//   );

  return (
    <div
      style={{"borderRadius":"0.5rem","backgroundColor":"#ffffff","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)"}}
    >
      {/* Header with Tabs and View Toggle */}
      <div
        style={{
          borderBottom: "1px solid #e5e5e5",
          padding: "0rem 1.5rem",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <button
              style={{
                padding: "0.5rem 1rem",
                fontSize: "0.875rem",
                fontWeight: "500",
                border: "none",
                borderBottom: activeTab === "discovery" ? "2px solid #1f9ad6" : "2px solid transparent",
                color: activeTab === "discovery" ? "#1f9ad6" : "#6c757d",
                backgroundColor: "transparent",
              }}
              onClick={() => setActiveTab("discovery")}
            >
              Discovery Session
            </button>
            <button
              style={{
                padding: "0.5rem 1rem",
                fontSize: "0.875rem",
                fontWeight: "500",
                border: "none",
                borderBottom: activeTab === "strategist" ? "2px solid #1f9ad6" : "2px solid transparent",
                color: activeTab === "strategist" ? "#1f9ad6" : "#6c757d",
                backgroundColor: "transparent",
              }}
              onClick={() => setActiveTab("strategist")}
            >
              Strategist Session
            </button>
          </div>

          {/* View Toggle */}
          <div
            style={{
              backgroundColor: "#f3f4f6",
              padding: "0.25rem",
              borderRadius: "0.5rem",
              display: "inline-flex",
            }}
          >
            <button
              onClick={() => setViewMode("table")}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0.5rem 1rem",
                borderRadius: "0.5rem",
                fontSize: "0.875rem",
                fontWeight: "500",
                backgroundColor: viewMode === "table" ? "white" : "transparent",
                color: viewMode === "table" ? "#1f9ad6" : "#4B5563",
                boxShadow: viewMode === "table" ? "0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)" : "none",
                border: "none",
              }}
            >
              <TableIcon style={{ height: '1rem', width: '1rem', marginRight: '0.375rem' }}/>
              Table
            </button>
            <button
              onClick={() => setViewMode("cards")}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0.5rem 1rem",
                borderRadius: "0.5rem",
                fontSize: "0.875rem",
                fontWeight: "500",
                backgroundColor: viewMode === "cards" ? "white" : "transparent",
                color: viewMode === "cards" ? "#1f9ad6" : "#4B5563",
                boxShadow: viewMode === "cards" ? "0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)" : "none",
                border: "none",
              }}
            >
              <LayoutGrid style={{ height: '1rem', width: '1rem', marginRight: '0.375rem' }} />
              Cards
            </button>
          </div>
        </div>
      </div>

      {/* View Content */}
      {viewMode === "table" ? (
        <SessionTables discoverySessions={discoverySessions}
        strategistSessions={strategistSessions}
        loading={loading}
        activeTab={activeTab}
        onTabChange={setActiveTab} />
      ) : (
        <SessionCards discoverySessions={discoverySessions}
        strategistSessions={strategistSessions}
        activeTab={activeTab}
        onTabChange={setActiveTab} />
      )}
    </div>
  );
};

export default SessionViews