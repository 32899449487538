import { axiosInstance } from "../utills"

export const sendUser = async(name,email,role) =>{
    try{
        const response =  await axiosInstance.post("https://zoom.kuru.ai/add_user_access", {
            email: email,
            name: name,
            role: role
          
        })
        console.log("Response Data:", response.data);
        return response.data

    }

    catch(error)
    {
        console.error('Error exporting data:', error);
        if (error.response) {
            console.error('Server responded with:', error.response.data);  
        }
        throw new Error('Failed to export data. Please try again later.');
    
    }
}

export const getuserdata = async()=>{
    try{
      const response = await axiosInstance.get("https://zoom.kuru.ai/get_user_data")
        return response.data
    }
    catch(error)
    {
        console.error('Error exporting data:', error);
        if (error.response) {
            console.error('Server responded with:', error.response.data);  
        }
        throw new Error('Failed to export data. Please try again later.');
    
    }
}

export const deleteSettingUser = async (id) => {
    try {
        await axiosInstance.delete("https://zoom.kuru.ai/delete_user_access", {
            headers: { "Content-Type": "application/json" }, // Ensure proper headers
            data: { id } // Send `id` inside `data`
        });
    } catch (error) {
        console.error('Error deleting user:', error);
        if (error.response) {
            console.error('Server responded with:', error.response.data);
        }
        throw new Error('Failed to delete user. Please try again later.');
    }
};