import React from 'react';
import { Star } from 'lucide-react';

const PerformanceSummary = ({ averageScore, doneTasks, notDoneTasks }) => {
    return (
        <div className="rounded p-1 mb-4">
            <div className="d-flex align-items-center mb-3">
                <Star className="text-warning me-2" size={20} />
                <h6 className="mb-0">Overall Performance</h6>
            </div>
            <div className="row g-3">
                <div className="col-4">
                    <div className="small text-muted">Average Score</div>
                    <div className="h4 mb-0">{averageScore}/5</div>
                </div>
                <div className="col-4">
                    <div className="small text-muted">Done</div>
                    <div className="h4 mb-0 text-success">{doneTasks}</div>
                </div>
                <div className="col-4">
                    <div className="small text-muted">Not Done</div>
                    <div className="h4 mb-0 text-danger">{notDoneTasks}</div>
                </div>
            </div>
            <div className="progress mt-3" style={{ height: '6px' }}>
                <div
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{ width: `${(averageScore / 5) * 100}%` }}
                    aria-valuenow={averageScore}
                    aria-valuemin={0}
                    aria-valuemax={5}
                ></div>
            </div>
        </div>
    );
};

export default PerformanceSummary;
