import axios from "axios";
import { axiosInstance } from "../utills"

export const exportDataApi = async (sessionType, selectedRepresentatives, startDate, endDate, exportType) => {
    try {
        console.log({
            representative_email: selectedRepresentatives,
            session_type: sessionType,
            download_data_type: exportType,
            from_date: startDate,
            to_date: endDate,
        });

        const response = await axios.post("https://zoom.kuru.ai/download_excel", {
            representative_email: selectedRepresentatives,
            session_type: sessionType,
            download_data_type: exportType,
            from_date: startDate,
            to_date: endDate,
        }, {
            headers: {
                'Content-Type': 'application/json' 
            },
            responseType: 'blob'  
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${exportType}.xlsx`); 
        document.body.appendChild(link);
        link.click(); 
        document.body.removeChild(link); 

    } catch (error) {
        console.error('Error exporting data:', error);
        if (error.response) {
            console.error('Server responded with:', error.response.data);  
        }
        throw new Error('Failed to export data. Please try again later.');
    }
};


export const emailStrategist = async(sessionType)=>{
    
    try{
        const response = await axiosInstance.get("https://zoom.kuru.ai/list_reps")
        if(sessionType=="strategy")
        {
        return response.data[0]
        }
        else
        {
            return response.data[1]

        }
    }
    catch(error)
    {
        console.error('Error getting data:', error);
        throw new Error('Failed to get data. Please try again later.');
    }
}