import "./App.css";
import Login from "./Pages/Login/Login";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import { ZoomSessionContextProvider } from "../src/hooks/zoomSessionContext";

import ProtectedRoute from "./ProtectedRoute";
import ErrorPage from "./Pages/Error";
import Root from "./Pages/Root/Root";
import Dashboard from "./Pages/Dashboard/Dashboard";
import User from "./Pages/User/User";
import Blog from "./Pages/Blog/Blog";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy/Privacy";
import Leads from "./Pages/Lead/Leads";
import Settings from "./Pages/Settings/Settings";
import Inbox from "./Pages/Inbox";
import ZoomAnalysis from "./Pages/ZoomAnalysis";
import ZoomRoot from "./Pages/ZoomRoot/ZoomRoot";
import SpecificZoomAnalysis from "./Pages/SpecificZoomAnalysis";
import SessionSummary from "./Pages/SessionSummary";
import LegalaiChat from "./Pages/LegalaiChat";
import LegalaiDocument from "./Pages/LegalaiDocument";
import LegalaiAnalytics from "./Pages/LegalaiAnalytics";
import CallAnalysisDashboard from "./component/call-analysis/newAanalysisDashboard"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/staff",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy-policy",
    element: (
      <ProtectedRoute>
        {" "}
        <Privacy />{" "}
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/terms-conditions",
    element: (
      <ProtectedRoute>
        <Terms />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Root />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "users", element: <User /> },
      { path: "blog", element: <Blog /> },
      { path: "inbox", element: <Inbox /> },
      { path: "leads", element: <Leads /> },
      { path: "zoom-analysis", element: <ZoomAnalysis /> },
      { path: "sessionsummary", element: <SessionSummary /> },
      { path: "chat", element: <LegalaiChat /> },

      { path: "chat/:urlSessionId", element: <LegalaiChat /> },
      { path: "documenthistory", element: <LegalaiDocument /> },
      { path: "legalanalytics", element: <LegalaiAnalytics /> },

      { path: "settings", element: <Settings /> },
      { path: "call-analysis", element: <CallAnalysisDashboard /> },
    ],
  },
  {
    path: "/zoom-dashboard",
    element: (
      <ProtectedRoute>
        <ZoomRoot />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [{ path: "", element: <SpecificZoomAnalysis /> }],
  },
  {
    path: "/call-analysis",
    element: (
      <ProtectedRoute>
        <ZoomRoot />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [{ path: "", element: <CallAnalysisDashboard /> }],
  },
  {
    path: "/zoom-dashboard",
    element: <ZoomRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <ZoomAnalysis />,
      },
    ],
  },
]);

function App() {
  
  return (
    <AuthProvider>
      <ZoomSessionContextProvider>
      <RouterProvider router={router} />
      </ZoomSessionContextProvider>
    </AuthProvider>
  );
}

export default App;
