import { useEffect, useState } from "react";
import { DateRangeFilter } from "./DateRangeFilter"
import { emailStrategist, exportDataApi } from "../api/exportDataApi";
import exportLoadingGif from "../assets/exportloading.gif"; 

const ExportData = ({setShowExport}) =>{
    const [sessionType, setSessionType] = useState("strategy");
    const [representativeType, setRepresentativeType] = useState("All Representatives")
    const [exportType, setExportType] = useState("Analysis")
    const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [emailList,setEmailList] = useState([])
  const [endDate, setEndDate] = useState('');
  const [selectedRepresentatives,setSelectedRepresentatives] = useState([])
  function formatDate(date) {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  }
  
    const exportingData = async () => {
      const formattedStartDate = startDate !="" ? formatDate(startDate) : "";
      const formattedEndDate = endDate !="" ? formatDate(endDate) : "";
        setLoading(true); 
        console.log(sessionType, selectedRepresentatives, startDate, endDate, exportType)

        try {
            await exportDataApi(sessionType, selectedRepresentatives,formattedStartDate,formattedEndDate, exportType);
        } catch (error) {
            console.error("Error exporting data:", error);
        } finally {
            setLoading(false); 
        }
    };


    useEffect(()=>{
      const emaildata = async()=>{ 
        try{
       const data = await emailStrategist(sessionType)
       setEmailList(data)
      }
      catch (err) {
        console.error('Error fetching representatives:', err);
    }
  }
emaildata()
    },[sessionType])
    
  useEffect(() => {
    setSelectedRepresentatives(emailList.map((rep) => rep.email));
 
}, [representativeType, emailList]);

    console.log(selectedRepresentatives)
    return(
      <div className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 50,fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
  <div className="bg-white rounded w-100" style={{ maxWidth: '600px', margin: '0 auto' }}>
           
        {    
        !loading ? 
        <div>
             <h5 className="border-bottom p-4 fw-semibold">Export Data</h5>

         <div className="d-flex flex-column py-2 mt-1 px-4" style={{ gap: "5px" }}>
         <label className="d-block" style={{ fontSize: "14px", fontWeight: 500, color: "#374151" }}>
         Session Type
</label>
      <div className="d-flex align-items-center" style={{ gap: "20px" }}>
        <label className="d-flex align-items-center" style={{ gap: "9px", cursor: "pointer" }}>
          <input
            type="radio"
            name="sessionType"
            value="strategy"
            checked={sessionType === "strategy"}
            onChange={() => setSessionType("strategy")}
            className="form-check-input"
            style={{ margin: 0, height: "16px", // equivalent to h-4
    width: "16px",  // equivalent to w-4
    backgroundColor: sessionType === "strategy" ? "#1f9ad6" : "white", // Set background color based on selected state
    borderColor: sessionType === "strategy" ? "#1f9ad6" : "black" // Border color remains the same
     }} 
          />
          <span  style={{ fontSize: "14px", color: "#374151" }}>Strategist Session</span>
        </label>
        <label className="d-flex align-items-center" style={{ gap: "9px", cursor: "pointer" }}>
          <input
            type="radio"
            name="sessionType"
            value="specialist"
            checked={sessionType === "specialist"}
            onChange={() => setSessionType("specialist")}
            className="form-check-input"
            style={{ margin: 0, height: "16px", // equivalent to h-4
    width: "16px",  // equivalent to w-4
    backgroundColor: sessionType === "specialist" ? "#1f9ad6" : "white", // Set background color based on selected state
    borderColor: sessionType === "specialist" ? "#1f9ad6" : "black" // Border color remains the same
     }} 
          />
          <span  style={{ fontSize: "14px", color: "#374151" }}>Discovery Session</span>
        </label>
      </div>
    </div>
  <div className="d-flex gap-3 py-2 mt-1 px-4">
          <div className="w-100">
            <div className="input-group">
            <label className="d-block" style={{ fontSize: "14px", fontWeight: 500, color: "#374151" }}>From Date</label>

              <input
                type="date"
                id="startDate"
                className="form-control"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  
                  width: '100%',
                  padding: '0.375rem 0.75rem',
                  fontSize: '0.875rem',
                  borderRadius: '0.375rem'
                }}
              />
            </div>
          </div>
          <div className="w-100">
            <div className="input-group">
            <label className="d-block" style={{ fontSize: "14px", fontWeight: 500, color: "#374151" }}>To Date</label>

              <input
                type="date"
                id="endDate"
                className="form-control"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  
                  width: '100%',
                  padding: '0.375rem 0.75rem',
                  fontSize: '0.875rem',
                  borderRadius: '0.375rem'
                }}
              />
            </div>
          </div>
        </div>  

        <div className="d-flex flex-column py-2 mt-1 px-4" style={{ gap: "5px" }}>
      <label className="d-block" style={{ fontSize: "14px", fontWeight: 500, color: "#374151" }}>Representatives</label>
      <div className="d-flex align-items-center" style={{ gap: "20px" }}>
        <label className="d-flex align-items-center" style={{ gap: "9px", cursor: "pointer" }}>
          <input
            type="radio"
            name="representativeType"
            value="All Representatives"
            checked={representativeType === "All Representatives"}
            onChange={() => setRepresentativeType("All Representatives")}
            className="form-check-input"
            style={{ margin: 0, height: "16px", // equivalent to h-4
    width: "16px",  // equivalent to w-4
    backgroundColor: representativeType === "All Representatives" ? "#1f9ad6" : "white", // Set background color based on selected state
    borderColor: representativeType === "All Representatives" ? "#1f9ad6" : "black" // Border color remains the same
     }} 
          />
          <span style={{ fontSize: "14px", color: "#374151" }}>All Representatives</span>
        </label>
        <label className="d-flex align-items-center" style={{ gap: "9px", cursor: "pointer" }}>
          <input
            type="radio"
            name="representativeType"
            value="Custom"
            checked={representativeType === "Custom"}
            onChange={() => setRepresentativeType("Custom")}
            className="form-check-input"
            style={{ margin: 0, height: "16px", // equivalent to h-4
    width: "16px",  // equivalent to w-4
    backgroundColor: representativeType === "Custom" ? "#1f9ad6" : "white", // Set background color based on selected state
    borderColor: representativeType === "Custom" ? "#1f9ad6" : "black" // Border color remains the same
     }} 
          />
          <span style={{ fontSize: "14px", color: "#374151" }}>Custom</span>
        </label>
      </div>
    </div>

    {representativeType === 'Custom' && (
  <div className="d-flex flex-column  mt-1 border rounded overflow-auto mx-4" style={{ maxHeight: '13.5rem' }}>
    {emailList.map((rep) => (
      <div
        key={rep.email}
        className="d-flex align-items-center p-3 border-bottom"
        style={{ cursor: 'pointer', backgroundColor: 'transparent' }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#f8f9fa')}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
      >
        <input
          type="checkbox"
          checked={selectedRepresentatives.includes(rep.email)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRepresentatives([...selectedRepresentatives, rep.email]);
            } else {
              setSelectedRepresentatives(
                selectedRepresentatives.filter((email) => email !== rep.email)
              );
            }
          }}
          className="form-check-input me-3"
          style={{ height: '1rem', width: '1rem', accentColor: '#1f9ad6' }}
        />
        <div className="d-flex align-items-center ms-3">
          <div
            className="rounded-circle d-flex align-items-center justify-content-center text-white text-center"
            style={{
              width: '2rem',
              height: '2rem',
              backgroundColor: '#1f9ad6',
              fontSize: '0.875rem',
            }}
          >
            {rep.name
              .split(' ')
              .map((n) => n[0])
              .join('')}
          </div>
          <div className="ms-3">
            <p className="mb-0 fw-medium text-dark fs-6">{rep.name}</p>
            <p className="mb-0 fw-normal fs-6" style={{color:"#6B7280"}}>{rep.email}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
)}


    <div className="d-flex flex-column py-2 mt-1 px-4" style={{ gap: "5px" }}>
      <label className="d-block" style={{ fontSize: "14px", fontWeight: 500, color: "#374151" }}>Export Type</label>
      <div className="d-flex align-items-center" style={{ gap: "33px" }}>
        <label className="d-flex align-items-center" style={{ gap: "9px", cursor: "pointer" }}>
          <input
            type="radio"
            name="exportType"
            value="Analysis"
            checked={exportType === "Analysis"}
            onChange={() => setExportType("Analysis")}
            className="form-check-input"
            style={{ margin: 0, height: "16px", // equivalent to h-4
    width: "16px",  // equivalent to w-4
    backgroundColor: exportType === "Analysis" ? "#1f9ad6" : "white", // Set background color based on selected state
    borderColor: exportType === "Analysis" ? "#1f9ad6" : "black" // Border color remains the same
     }} 
          />
          <span style={{ fontSize: "14px", color: "#374151" }}>Analysis Report</span>
        </label>
        <label className="d-flex align-items-center" style={{ gap: "9px", cursor: "pointer" }}>
          <input
            type="radio"
            name="exportType"
            value="Rating"
            checked={exportType === "Rating"}
            onChange={() => setExportType("Rating")}
            className="form-check-input"
            style={{ margin: 0, height: "16px", // equivalent to h-4
    width: "16px",  // equivalent to w-4
    backgroundColor: exportType === "Rating" ? "#1f9ad6" : "white", // Set background color based on selected state
    borderColor: exportType === "Rating" ? "#1f9ad6" : "black" // Border color remains the same
     }} 
          />
          <span style={{ fontSize: "14px", color: "#374151" }}>Summary Report</span>
        </label>
        </div>
      
    </div>

     <div className="d-flex justify-content-end p-3 rounded-bottom" style={{ backgroundColor: '#f9fafb', gap: '0.75rem' }}>
        <button onClick={()=>setShowExport(false)} className="px-4 py-2 mt-1 fs-6 fw-medium rounded" style={{ backgroundColor: 'transparent',border:"none",fontSize: "14px", fontWeight: 500, color: "#374151" }} onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f8f9fa'} onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent' }
        >Cancel</button>
        <button onClick={exportingData} className="px-4 py-2 mt-1 text-white rounded d-flex align-items-center" 
style={{ backgroundColor: '#1f9ad6',border:"none",fontSize: "14px", fontWeight: 500, color: "white" }} 
onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1785bd'} 
onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1f9ad6'}> 

<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{marginRight:"8px"}} viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/><polyline points="7 10 12 15 17 10"/><line x1="12" x2="12" y1="15" y2="3"/></svg>
Export</button>
     </div>
     </div> : 
     <div className="p-4 d-flex flex-column align-items-center justify-content-center" >
        <img src={exportLoadingGif} alt="Loading..." width="40"  />
        <h6 style={{color:"#374151",fontSize: "18px"}} className="mt-3">Exporting data...</h6>
         </div>
}
        </div>
        </div>
    )
}

export default ExportData