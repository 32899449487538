import React, { useState } from 'react';
import { Star, ChevronLeft, ChevronRight, Info } from 'lucide-react';
import "./PerformanceDetails.css"




const ITEMS_PER_PAGE = 14; // 7 items per column * 2 columns

export function PerformanceDetails({criteriaBreakdown, topPerformers,setActiveTab,activeTab, worstPerformers }) {

  const [performerTab, setPerformerTab] = useState('top');
  const [currentPage, setCurrentPage] = useState(0);
  const discoveryCriteria = [
    { name: 'Introduction', score: criteriaBreakdown?.introduction || 0 },
    { name: 'Cash Flow Tour', score: criteriaBreakdown?.cash_flow_tour || 0 },
    { name: 'Closing & Follow Up', score: criteriaBreakdown?.closing_and_follow_up || 0},
    { name: 'Freedom Method', score: criteriaBreakdown?.freedom_method_presentation || 0 },
    { name: 'Member Understanding', score: criteriaBreakdown?.member_understanding_and_journey || 0 },
    { name: 'Preparation', score: criteriaBreakdown?.preparation || 0  },
    { name: 'The Gap', score: criteriaBreakdown?.the_gap || 0 },
    { name: 'Unpack', score: criteriaBreakdown?.unpack || 0 },
    { name: 'Overall Score', score: criteriaBreakdown?.overall_assessment || 0 }
  ];
  
  const strategistCriteria = [
    { name: 'Rapport Build', score: criteriaBreakdown?.rapport_building || 0 },
    { name: 'Qualifying Appointment', score: criteriaBreakdown?.appointment_qualification || 0  },
    { name: 'Set Tone Early', score: criteriaBreakdown?.set_tone_early || 0  },
    { name: 'Set Agenda', score: criteriaBreakdown?.set_agenda || 0  },
    { name: 'UNPACK - Discovery Questions', score: criteriaBreakdown?.discovery_questions || 0  },
    { name: 'Freedom Knowledge', score: criteriaBreakdown?.knowledge_check || 0  },
    { name: 'Personal Story', score: criteriaBreakdown?.personal_story || 0  },
    { name: 'Finance Assessment 1', score: criteriaBreakdown?.finance_assessment_page1 || 0  },
    { name: 'Finance Assessment 2', score: criteriaBreakdown?.finance_assessment_page2 || 0  },
    { name: 'Lazy Equity', score: criteriaBreakdown?.lazy_equity_smsf || 0  },
    { name: 'Cashflow Sheet', score: criteriaBreakdown?.cashflow_sheet || 0  },
    { name: 'Ultimate Property Planner', score: criteriaBreakdown?.ultimate_property_planner || 0  },
    { name: 'Freedom Criteria', score: criteriaBreakdown?.freedom_criteria || 0  },
    { name: 'Recap + Get Member Decision', score: criteriaBreakdown?.recap_decision || 0  },
    { name: 'Secure Property Session Time', score: criteriaBreakdown?.secure_property_session || 0  },
    { name: 'Ask for Referral', score: criteriaBreakdown?.ask_for_referral || 0 },
    { name: 'Expectation Decision', score: criteriaBreakdown?.rapport_building || 0 },
    { name: 'Final Recap', score: criteriaBreakdown?.final_recap || 0 },
  ];
  const currentCriteria = activeTab === 'specialist' ? discoveryCriteria : strategistCriteria;
  const totalPages = Math.ceil(currentCriteria.length / ITEMS_PER_PAGE);
  
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const visibleCriteria = currentCriteria.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  
  const leftColumnCriteria = visibleCriteria.slice(0, 7);  // Show 7 items in left column
  const rightColumnCriteria = visibleCriteria.slice(7);    // Show remaining items in right column

 
  const currentPerformers = performerTab === 'top' ? topPerformers : worstPerformers;

  const Tooltip = ({ content }) => (
<div style={{ position: "relative", display: "inline-block"}}>
    <div className="tooltip-wrapper">
      <Info
        style={{
          width: "1rem",
          height: "1rem",
          color: "#9CA3AF",
          cursor: "help",
          marginBottom: 4,
        }}
        className="info-icon"
      />
      <div className="tooltip-contentthis" style={{}}>
        {content}
        <div className="tooltip-arrow" style={{left:"150px"}}></div>
      </div>
    </div>
  </div>
);
    
  const handleNextPage = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  return (
    <div style={{"padding":"1.5rem","borderRadius":"0.5rem","backgroundColor":"#ffffff","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)"}}>
      {/* Session Type Tabs */}
      <div style={{ display: 'flex', borderBottom: '1px solid #e5e7eb', marginBottom: '24px' }}>
        <button
         style={{
          padding: "0.5rem 1rem",
          fontSize: "0.875rem",
          fontWeight: "500",
          border: "none",
          borderBottom: activeTab === "specialist" ? "2px solid #1f9ad6" : "2px solid transparent",
          color: activeTab === "specialist" ? "#1f9ad6" : "#6c757d",
          backgroundColor: "transparent",
        }}
          onClick={() => {
            setActiveTab('specialist');
            setCurrentPage(0);
          }}
        >
          Discovery Session
        </button>
        <button
         style={{
          padding: "0.5rem 1rem",
          fontSize: "0.875rem",
          fontWeight: "500",
          border: "none",
          borderBottom: activeTab === "strategist" ? "2px solid #1f9ad6" : "2px solid transparent",
          color: activeTab === "strategist" ? "#1f9ad6" : "#6c757d",
          backgroundColor: "transparent",
        }}
          onClick={() => {
            setActiveTab('strategist');
            setCurrentPage(0);
          }}
        >
          Strategist Session
        </button>
      </div>

      <div className='mainperformancediv'>
        <div className='mainperformancepartdiv'>
          <div style={{ position: 'relative' }}>
            <div className='mainperformancedivpart2'>
              <div >
                {leftColumnCriteria.map((criteria) => (
                  <div key={criteria.name} style={{"marginTop":"1rem"}}>
                    <div style={{"display":"flex","marginBottom":"0.25rem","justifyContent":"space-between"}}>
                      <span style={{"fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,"color":"#374151"}}>{criteria.name}</span>
                      <span style={{"fontSize":"0.875rem","lineHeight":"1.25rem","color":"#4B5563"}}>{criteria.score}/5</span>
                    </div>
                    <div style={{"borderRadius":"9999px","width":"100%","height":"0.5rem","backgroundColor":"#E5E7EB"}}>
                      <div
                        style={{
                          backgroundColor: '#1f9ad6',
                          height: '8px',
                          borderRadius: '9999px',
                          width: `${(criteria.score / 5) * 100}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>

              <div >
                {rightColumnCriteria.map((criteria) => (
                  <div key={criteria.name} style={{"marginTop":"1rem"}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
                      <span style={{ fontSize: '14px', fontWeight: '500', color: '#374151' }}>{criteria.name}</span>
                      <span style={{ fontSize: '14px', color: '#4b5563' }}>{criteria.score}/5</span>
                    </div>
                    <div style={{ width: '100%', backgroundColor: '#e5e7eb', borderRadius: '9999px', height: '8px' }}>
                      <div
                        style={{
                          backgroundColor: '#1f9ad6',
                          height: '8px',
                          borderRadius: '9999px',
                          width: `${(criteria.score / 5) * 100}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {totalPages > 1 && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '16px', gap: '16px' }}>
                <button
                  onClick={handlePrevPage}
                  style={{
                    border:"none",
                    padding: '8px',
                    borderRadius: '9999px',
                    backgroundColor: 'transparent',
                    cursor: currentPage === 0 ? 'not-allowed' : 'pointer',
                    opacity: currentPage === 0 ? '0.5' : '1',
                  }}
                  disabled={currentPage === 0}
                >
                  <ChevronLeft style={{ height: '20px', width: '20px', color: '#4b5563' }} />
                </button>
                <span style={{ fontSize: '14px', color: '#4b5563' }}>
                  Page {currentPage + 1} of {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  style={{
                    border:"none",
                    padding: '8px',
                    borderRadius: '9999px',
                    backgroundColor: 'transparent',
                    cursor: currentPage === totalPages - 1 ? 'not-allowed' : 'pointer',
                    opacity: currentPage === totalPages - 1 ? '0.5' : '1',
                  }}
                  disabled={currentPage === totalPages - 1}
                >
                  <ChevronRight style={{ height: '20px', width: '20px', color: '#4b5563' }} />
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Performers Section - Takes up 1 column */}
        <div style={{ gridColumn: 'span 1' }}>
          {/* Performer Type Tabs */}
          <div style={{ display: 'flex', borderBottom: '1px solid #e5e7eb', marginBottom: '16px' }}>
            <button
               style={{
                padding: "0.5rem 1rem",
                fontSize: "0.875rem",
                fontWeight: "500",
                border: "none",
                borderBottom: performerTab === "top" ? "2px solid #1f9ad6" : "2px solid transparent",
                color: performerTab === "top" ? "#1f9ad6" : "#6c757d",
                backgroundColor: "transparent",
              }}
              onClick={() => setPerformerTab('top')}
            >
              Top Performers
            </button>
            <button
              style={{
                padding: "0.5rem 1rem",
                fontSize: "0.875rem",
                fontWeight: "500",
                border: "none",
                borderBottom: performerTab === "worst" ? "2px solid #1f9ad6" : "2px solid transparent",
                color: performerTab === "worst" ? "#1f9ad6" : "#6c757d",
                backgroundColor: "transparent",
              }}
              onClick={() => setPerformerTab('worst')}
            >
              Worst Performers
            </button>
            <div style={{ marginLeft: "auto", marginRight:"" , marginTop:"6px" }}>

            <Tooltip content={`Based On:
1. Average Rating
2. Number of sessions
3. Average Duration`}/>
            </div>
          </div>

          <div style={{ marginBottom: '16px' }}>
            {currentPerformers?.map((data, index) => (
              <div key={data.Name} style={{"marginTop":"1rem"}}>
              <div  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{"display":"flex","justifyContent":"center","alignItems":"center","borderRadius":"9999px","width":"2.5rem","height":"2.5rem","fontWeight":500,"color":"#ffffff","background":"#1f9ad6"}}>
                    {data.Name.split(' ').map((n) => n[0]).join('')}
                  </div>
                  <div style={{"marginLeft":"0.75rem"}}>
                    <div style={{"fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,"color":"#111827"}}>{data.Name}</div>
                    <div style={{"fontSize":"0.75rem","lineHeight":"1rem","color":"#6B7280"}}>
                      {performerTab === 'top' 
                        ? index === 0 ? 'Senior Specialist' : index === 1 ? 'Specialist' : 'Junior Specialist'
                        : 'Needs Improvement'}
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Star style={{ height: '16px', width: '16px', color: '#ffd700', marginRight: '4px' }} />
                  <span style={{ fontSize: '14px', fontWeight: '500' }}>
                    {data.Score}
                  </span>
                </div>
              </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
