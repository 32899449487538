import {

    Link as LinkIcon,
    User,
    Mail,
    Users,
    Clock,
    Video,
    Calendar,
    Briefcase,

} from "lucide-react";
import { formatDistanceToNow, format } from "date-fns";
import { useEffect, useState } from "react";
import { postValidity } from "../../api/zoomanalysisApi";

const SessionVideo = ({ session }) => {
const [validity,setValidity] = useState(session.validity)
useEffect(()=>{
    if (validity !== session.validity) {
const validityUpdate = async()=>{
    let mainSeassionId= session.id
    let resulId = mainSeassionId.replace(/(strategist-|specialist-)/, "");
    await postValidity(resulId,session.representative.email,validity)
}
validityUpdate()
    }
},[validity])
    return (
        <div className="card mb-4">
            <div className="card-body">
                <div className="row gx-4">
                    
                    {/* added new line */}
                    <div style={{"display":"flex","justifyContent":"space-between",marginBottom:4, paddingLeft:15}}>
        <h2 style={{"fontSize":"1.25rem","lineHeight":"1.75rem","fontWeight":500,"color":"#111827"}}>Session Details</h2>
        <select
          style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","fontSize":"0.875rem","lineHeight":"1.25rem","backgroundColor":"#ffffff","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)"}}
          value={validity == "valid" ? 'valid' : 'invalid'}
          onChange={(e) =>
            setValidity(e.target.value )
          }
        >
          <option value="valid">Valid</option>
          <option value="invalid">Invalid</option>
        </select>
      </div>
      
                    {/* Left Column */}
                    <div className="col-md-6">
                        <div className="d-flex align-items-center mb-3">
                            <User className="text-primary me-2" size={20} />
                            <div>
                                <div className="text-muted small">Representative</div>
                                <div className="fw-medium">{session.representative.name}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <Users className="text-primary me-2" size={20} />
                            <div>
                                <div className="text-muted small">Role</div>
                                <div className="fw-medium">{session.representative.role}</div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <Mail className="text-primary me-2" size={20} />
                            <div>
                                <div className="text-muted small">Email</div>
                                <div className="fw-medium">{session.representative.email}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center mb-3">

                            <Users className="text-primary me-2" size={20} />
                            <div>
                            <div className="text-muted small">Participants</div>

                                <div className="fw-medium">
                                    {session.participants.map((p) => p.name).join(", ")}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <Clock className="text-primary me-2" size={20} />
                            <div>
                                <div className="text-muted small">Duration</div>
                                <div className="fw-medium">
                                    {session.duration} minutes</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <Calendar className="text-primary me-2" size={20} />
                            <div>
                                <div className="text-muted small">Session Date:</div>
                                <div className="fw-medium">
                                {format(new Date(session.date), "do MMMM, yyyy")}

                                    (
                                    {formatDistanceToNow(new Date(session.date), {
                                        addSuffix: true,
                                    })}
                                    )
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default SessionVideo