import React, { useState, useRef, useEffect } from 'react';
import { Tag, X, Plus, Search } from 'lucide-react';
import { deleteTag, postTag } from '../api/zoomanalysisApi';


export function TagsSections({ tags,setTagsFlag, onUpdateTags,sessionType, sessionId, setTags,listUniqueTags }) {
  const suggestedTags = listUniqueTags;

  const [newTag, setNewTag] = useState('');
  const [showTagInput, setShowTagInput] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  console.log('tags is:', tags, 'and its type is:', typeof tags);

  const filteredSuggestions = suggestedTags
    .filter(tag => tag?.toLowerCase()?.includes(newTag.toLowerCase()))
    .slice(0, 6);

    const handleAddTag = async (tagToAdd = newTag) => {
      if (tagToAdd.trim() && !tags?.includes(tagToAdd.trim())) {
        // Assuming postTag creates and returns a new tag or some response
        setNewTag('');
        const createdTag = await postTag(tagToAdd, sessionId, sessionType);
        if (createdTag) {
          onUpdateTags(tagToAdd)
          // Do something if needed after the tag is created
          setShowSuggestions(false);
        }
      }
    };
  const handleRemoveTag = async(tagToRemove) => {
    const deletingTag = await deleteTag(tagToRemove, sessionId, sessionType)
    if(deletingTag)
    {
      setTags(tags.filter(tag => tag !== tagToRemove));
      setTagsFlag(prev=>!prev)
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && newTag.trim()) {
      handleAddTag();
    } else if (e.key === 'Escape') {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target) &&
        !inputRef.current?.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className='card mb-4' style={{"padding":"1.5rem","marginBottom":"1.5rem","borderRadius":"0.5rem","backgroundColor":"#ffffff","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)"}}>
      <div style={{"display":"flex","marginBottom":"1rem","justifyContent":"space-between","alignItems":"center"}}>
        <div style={{"display":"flex","alignItems":"center"}}>
          <Tag style={{"marginRight":"0.5rem","width":"1.25rem","height":"1.25rem","color":"#9CA3AF"}} />
          <h2 style={{"fontSize":"1.25rem","lineHeight":"1.75rem","fontWeight":600,"color":"#111827"}}>Tags</h2>
        </div>
        {!showTagInput && (
          <button
            onClick={() => {
              setShowTagInput(true);
              setTimeout(() => inputRef.current?.focus(), 100);
            }}
           style={{"display":"flex","alignItems":"center","fontSize":"0.875rem","lineHeight":"1.25rem","color":"#1f9ad6",":hover":{"color":"#1785bd"}, "border":"none", "backgroundColor" : "white"}}
          >
            <Plus style={{"marginRight":"0.25rem","width":"1rem","height":"1rem"}}/>
            Add Tag
          </button>
        )}
      </div>
      
      <div style={{"display":"flex","flexWrap":"wrap","gap":"0.5rem"}}>
        {tags?.map((tag) => (
          <div key={tag} style={{"display":"flex","paddingTop":"0.25rem","paddingBottom":"0.25rem","paddingLeft":"0.5rem","paddingRight":"0.5rem","gap":"0.25rem","alignItems":"center","borderRadius":"9999px","fontSize":"0.875rem","lineHeight":"1.25rem","color":"#1D4ED8","backgroundColor":"#EFF6FF"}}>
            <span>{tag}</span>
            <div onClick={() => handleRemoveTag(tag)} style={{"cursor":"pointer","padding":"0.125rem","borderRadius":"9999px",":hover":{"backgroundColor":"#DBEAFE"}}}>
              <X style={{"width":"0.75rem","height":"0.75rem"}}/>
            </div>
          </div>
        ))}
        {showTagInput && (
          <div style={{"display":"flex","position":"relative","gap":"0.5rem","alignItems":"center"}}>
            <div style={{"position":"relative"}}>
              <input
                ref={inputRef}
                type="text"
                value={newTag}
                onChange={(e) => {
                  setNewTag(e.target.value);
                  setShowSuggestions(true);
                }}
                onKeyDown={handleKeyPress}
                placeholder="Enter tag name"
              style={{"paddingTop":"0.25rem","paddingBottom":"0.25rem","paddingRight":"0.5rem","paddingLeft":"2rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","width":"16rem","fontSize":"0.875rem","lineHeight":"1.25rem"}}
                autoFocus
              />
              <Search style={{"position":"absolute","top":"0.375rem","left":"0.5rem","width":"1rem","height":"1rem","color":"#9CA3AF"}} />
              {showSuggestions && filteredSuggestions.length > 0 && (
                <div ref={suggestionsRef} style={{"position":"absolute","zIndex":10,"marginTop":"0.25rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#E5E7EB","width":"100%","backgroundColor":"#ffffff","boxShadow":"0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"}}>
                  {filteredSuggestions.map((suggestion) => (
                    <div
                      key={suggestion}
                      onClick={() => handleAddTag(suggestion)}
                      style={{"cursor":"pointer","paddingTop":"0.5rem","paddingBottom":"0.5rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","width":"100%","fontSize":"0.875rem","lineHeight":"1.25rem","textAlign":"left",":hover":{"backgroundColor":"#F3F4F6"}}}
                    >
                      {suggestion}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button onClick={() => handleAddTag()} style={{"paddingTop":"0.25rem","paddingBottom":"0.25rem","paddingLeft":"0.5rem","paddingRight":"0.5rem","borderRadius":"0.375rem","fontSize":"0.875rem","lineHeight":"1.25rem","color":"#ffffff","background":"#1f9ad6",":hover":{"background":"#1785bd"},"border":"none"}}>
              Add
            </button>
            <button onClick={() => {
                setShowTagInput(false);
                setNewTag('');
                setShowSuggestions(false);
              }}
              style={{"paddingTop":"0.25rem","paddingBottom":"0.25rem","paddingLeft":"0.5rem","paddingRight":"0.5rem","fontSize":"0.875rem","lineHeight":"1.25rem","color":"#4B5563",":hover":{"color":"#1F2937"},"border":"none","backgroundColor" : "white"}}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
