import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UploadData from "../../component/user-components/UploadData";
import DeteletData from "../../component/user-components/DeteletData";
import Loader from "../../assets/loader.gif";
import { fetchUsers, selectBroadcast } from "../../api/userApi";
import PaginationControl from "../../component/PaginationControl";
import "../../component/ui-components/Button.css"
import "../../component/ui-components/Input.css"
import "../../component/ui-components/filterString.css"
import "./User.css"
import UserTable from "../../component/user-components/UserTable";
import BroadcastUser from "../../component/user-components/BroadcastUser";
import Searching from "../../component/Searching";

function User() {
  let navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [userData, userDataUpdate] = useState(true);
  const [tempPage, updateTempPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [opt, updateOpt] = useState("Opt-In");
  const [load, updateLoad] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [broadcastName, setBroadcastName] = useState("");

  function handleFind() {
    console.log("handle find.");
    navigate("../inbox");
  }

  const tableColumns = [
    "Select",
    "ID",
    "Contact Id",
    "Name",
    "Phone",
    "Status",
    "Broadcast Date",
    "Last Activity Date",
    "Created At",
  ];

  const fetchUser = async () => {
    if (userData === true) {
      try {
        updateLoad(true);
        const extractedData = await fetchUsers()
        setTableData(extractedData);
        userDataUpdate(false);
        updateLoad(false);
      }
      catch (error) {
        console.log(error);
      };
    }
  };

  useEffect(() => {
    fetchUser();
  }, [userData]);

  useEffect(() => {
    if (tableData) {
      setMaxValue(tableData.length);
    }
  }, [tableData]);

  const columns = tableColumns.length > 0 ? tableColumns : [];

  const getUsers = async (broadcastName) => {
    try {
      const successMessage = await selectBroadcast(selectedUsers, broadcastName);
      alert(successMessage);
      handleFind();
      setBroadcastName("");
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  // Custom Pagination Logic
  const handlePagination = () => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = currentPage * itemsPerPage;
    setStartIndex(start);
    setEndIndex(end);
    const paginatedData = filteredTableData.slice(start, end);
    setPaginatedData(paginatedData);
    setTotalPages(Math.ceil(filteredTableData.length / itemsPerPage));
  };

  useEffect(() => {
    console.log("Min Max Value is: ", minValue, " & ", maxValue);
    if (minValue === "" || maxValue === "") {
      console.log("No Update");
    } else {
      const filteredData = tableData.filter((item) => {
        const id = item.ID;
        const min = parseInt(minValue, 10);
        const max = parseInt(maxValue, 10);
        return (
          ((item["Contact Id"] || "").toString().includes(searchQuery) ||
            (item.Name || "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            (item.Phone || "").toString().includes(searchQuery)) &&
          id >= min &&
          id <= max &&
          (opt === "All" || item.Status === opt)
        );
      });

      setFilteredTableData(filteredData);
    }
  }, [
    currentPage,
    itemsPerPage,
    tableData,
    searchQuery,
    minValue,
    maxValue,
    opt,
  ]);

  useEffect(() => {
    handlePagination();
  }, [filteredTableData, currentPage, itemsPerPage]);

  return (
    <>
      <div className="main" style={{"padding": "1.5rem" }}>
        <div className="row" style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
          <Searching
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Contact Id, Phone Number, Name..."
          />
          <div className="col-md-7 id-filter-by-range" style={{height:"32px"}}>
            <p className="filter-string">ID:</p>
            &nbsp;&nbsp;
            <input
              type="number"
              className="form-control min-input idinput"
              placeholder="Min"
              value={minValue}
              onChange={(e) => {
                setMinValue(e.target.value);
              }}
            />
            <input
              type="number"
              className="form-control max-input idinput"
              placeholder="Max"
              value={maxValue}
              onChange={(e) => {
                setMaxValue(e.target.value);
              }}
            />
            &nbsp;
            <button
              style={{ backgroundColor: "#9ECFE8", fontSize: 12 }}
              className="btn"
              onClick={(e) => {
                setMinValue(1);
                setMaxValue(tableData.length);
              }}
            >
              Clear
            </button>
            &nbsp; &nbsp; &nbsp;
            <p className="filter-string">Status:&nbsp;</p>
            <select
              value={opt}
              onChange={(e) => updateOpt(e.target.value)}
              className="form-control"
              style={{ width: 90, fontSize: 12 }}
            >
              <option value="All">All</option>
              <option value="Opt-In">Opt-In</option>
              <option value="Opt-Out">Opt-Out</option>
            </select>
          </div>

          <div className="col-md-2 delete-div">
            <DeteletData
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              userDataUpdate={userDataUpdate}
              setCurrentPage={setCurrentPage}
              updateTempPage={updateTempPage}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 action-div">
            <button
              style={{ whiteSpace: "nowrap" }}
              className="btn send-message-button"
              onClick={() => setShowModal(true)}
              disabled={selectedUsers.length === 0 || selectedUsers.some(
                (id) =>
                  tableData.find((user) => user.Select === id).Status ===
                  "Opt-Out"
              )}
            >
              Send Message
            </button>
            <UploadData userDataUpdate={userDataUpdate} />
          </div>
          <div className="col-md-6 selected-users-div">
            <p className="filter-string">
              Selected: {selectedUsers?.length} of {tableData?.length}
            </p>
          </div>
        </div>
        {load && (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Loader}
              alt="Loading..."
              style={{ width: 30, height: 30 }}
            />
          </div>
        )}
        {!load && (
          <UserTable
            columns={columns}
            tableData={tableData}
            filteredTableData={filteredTableData}
            paginatedFilteredData={paginatedData}
            isCheckedAll={isCheckedAll}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            setIsCheckedAll={setIsCheckedAll}
          />
        )}
        <div style={{marginTop:"24px"}}>
        <PaginationControl
          currentPage={currentPage}
          totalPages={totalPages}
          tempPage={tempPage}
          setCurrentPage={setCurrentPage}
          updateTempPage={updateTempPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
        />
        </div>
      </div>
      {showModal && (
        <BroadcastUser
          broadcastName={broadcastName}
          setBroadcastName={setBroadcastName}
          setShowModal={setShowModal}
          getUsers={getUsers}
        />
      )}
    </>
  );
}

export default User;
