import React, { useState } from 'react';
import { MessageSquare, X, Send, Trash2, Minimize2, Maximize2 } from 'lucide-react';

export function ChatbotPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');

  const handleSendMessage = () => {
    if (!inputMessage.trim()) return;

    const newMessage = {
      id: Date.now().toString(),
      type: 'user',
      text: inputMessage,
      timestamp: new Date()
    };

    setMessages(prev => [...prev, newMessage]);
    setInputMessage('');

    // Simulate bot response
    setTimeout(() => {
      const botResponse = {
        id: (Date.now() + 1).toString(),
        type: 'bot',
        text: 'Thanks for your message! I\'m here to help with any questions about property investment.',
        timestamp: new Date()
      };
      setMessages(prev => [...prev, botResponse]);
    }, 1000);
  };

  const clearChat = () => {
    setMessages([]);
  };

  // Custom styles
  const styles = {
    chatButton: {
      position: 'fixed',
      bottom: '24px',
      right: '24px',
      width: '56px',
      height: '56px',
      borderRadius: '50%',
      backgroundColor: '#1f9ad6',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'background-color 0.3s'
    },
    chatPopup: {
      position: 'fixed',
      right: '24px',
      bottom: '0',
      backgroundColor: 'white',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s',
      display: 'flex',
      flexDirection: 'column',
      borderTopLeftRadius: '0.5rem',
      borderTopRightRadius: '0.5rem',
      width: isMinimized ? '288px' : '384px',
      height: isMinimized ? 'auto' : '500px',
      maxHeight: 'calc(100vh - 2rem)'
    },
    messageContainer: {
      flex: '1',
      overflowY: 'auto',
      padding: '16px'
    },
    userMessage: {
      backgroundColor: '#1f9ad6',
      color: 'white',
      borderRadius: '0.5rem',
      padding: '12px',
      maxWidth: '80%',
      marginLeft: 'auto',
      marginBottom: '16px'
    },
    botMessage: {
      backgroundColor: '#f8f9fa',
      borderRadius: '0.5rem',
      padding: '12px',
      maxWidth: '80%',
      marginRight: 'auto',
      marginBottom: '16px'
    },
    timestamp: {
      fontSize: '0.75rem',
      marginTop: '4px',
      opacity: '0.75'
    }
  };

  if (!isOpen) {
    return (
      <button
        onClick={() => setIsOpen(true)}
        className="btn p-0"
        style={styles.chatButton}
      >
        <MessageSquare size={24} />
      </button>
    );
  }

  return (
    <div style={styles.chatPopup}>
      {/* Header */}
      <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
        <div className="d-flex align-items-center">
          <MessageSquare size={20} color="#1f9ad6" className="me-2" />
          <h5 className="mb-0 fw-medium">Freedom AI Assistant</h5>
        </div>
        <div className="d-flex align-items-center">
          <button
            onClick={() => setIsMinimized(!isMinimized)}
            className="btn btn-sm me-1"
            style={{ background: 'transparent', border: 'none' }}
          >
            {isMinimized ? (
              <Maximize2 size={16} color="#6c757d" />
            ) : (
              <Minimize2 size={16} color="#6c757d" />
            )}
          </button>
          <button
            onClick={() => setIsOpen(false)}
            className="btn btn-sm"
            style={{ background: 'transparent', border: 'none' }}
          >
            <X size={16} color="#6c757d" />
          </button>
        </div>
      </div>

      {!isMinimized && (
        <>
          {/* Messages */}
          <div style={styles.messageContainer}>
            {messages.map((message) => (
              <div key={message.id} style={message.type === 'user' ? styles.userMessage : styles.botMessage}>
                <div className="mb-1">{message.text}</div>
                <div style={styles.timestamp}>
                  {message.timestamp.toLocaleTimeString()}
                </div>
              </div>
            ))}
            {messages.length === 0 && (
              <div className="d-flex flex-column align-items-center justify-content-center h-100 text-muted">
                <MessageSquare size={48} className="mb-2" />
                <div>No messages yet</div>
              </div>
            )}
          </div>

          {/* Input Area */}
          <div className="border-top p-3">
            <div className="input-group">
              <button
                onClick={clearChat}
                className="btn"
                style={{ background: 'transparent', border: 'none' }}
                title="Clear chat"
              >
                <Trash2 size={18} />
              </button>
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                placeholder="Type your message..."
                className="form-control mx-2"
                style={{borderRadius: '20px'}}
              />
              <button
                onClick={handleSendMessage}
                disabled={!inputMessage.trim()}
                className="btn"
                style={{
                  backgroundColor: '#1f9ad6',
                  color: 'white',
                  borderRadius: '50%',
                  padding: '10px 14px',
                  border: 'none'
                }}
              >
                <div>
                  <Send size={18} />
                </div>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}