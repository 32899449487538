// SessionDetail.js
import React, { useEffect, useRef, useState } from "react";
import {
  ArrowLeft,
  LinkIcon,
  MessageSquare,
  Pin,
  Video

} from "lucide-react";
import SessionVideo from "./sessiondetails-components/SessionVideo";
import SessionRating from "./sessiondetails-components/SessionRating";
import SessionScore from "./sessiondetails-components/SessionScore";
import TopicsCovered from "./sessiondetails-components/TopicsCovered";
import SessionSummary from "./sessiondetails-components/SessionSummary";
import SessionStrength from "./sessiondetails-components/SessionStrength";
import SessionWeakness from "./sessiondetails-components/SessionWeakness";
import SessionRecommendations from "./sessiondetails-components/SessionRecommendations";
import SessionFlags from "./sessiondetails-components/SessionFlags";
import SessionConclusion from "./sessiondetails-components/SessionConclusion";
import FeedbackButton from "./FeedbackButton";
import PerformanceSummary from "./PerformanceSummary";
import { CriteriaList } from "./CriteriaList";
import CriterionCard from "./sessiondetails-components/CriterionCard";
import { TagsSections } from "./TagSections";
import { ChatbotPopup } from "./ChatBotPopup";
import "../component/ui-components/chatBotOpenButton.css"
export function SessionDetail({ session,setTagsFlag,
  tagsFlag ,listUniqueTags}) {
  const [isSticky, setIsSticky] = useState(false);
  const [doneCount, setDoneCount] = useState(0);
  const [notDoneCount, setNotDoneCount] = useState(0);
  const [tags,setTags] = useState([])
  const [activeTab, setActiveTab] = useState('performance');
  const [openChatBot,setOpenChatBot] = useState(false)
  const performanceRef = useRef(null);
  const mockCriteriaData = {
    "Introduction": {
      score: session?.introduction.Rating, // Score out of 5
      analysis: session?.introduction.Comments,
      strengths: session?.introduction.Strengths,
      areasForImprovement: session?.introduction["Areas for Improvement"],
    },
    "Clash Flow Tour": {
      score: session?.cashFlowTour.Rating, // Score out of 5
      analysis: session?.cashFlowTour.Comments,
      strengths: session?.cashFlowTour.Strengths,
      areasForImprovement: session?.cashFlowTour["Areas for Improvement"],

    },
   
    "Closing And Follow Up": {
      score: session?.closingAndFollowUp.Rating, // Score out of 5
      analysis: session?.closingAndFollowUp.Comments,
      strengths: session?.closingAndFollowUp.Strengths,
      areasForImprovement: session?.closingAndFollowUp["Areas for Improvement"],
    },
    "Freedom Method Representation": {
      score: session?.FreedomMethodPresentation.Rating, // Score out of 5
      analysis: session?.FreedomMethodPresentation.Comments,
      strengths: session?.FreedomMethodPresentation.Strengths,
      areasForImprovement: session?.FreedomMethodPresentation["Areas for Improvement"],
    },
    "Member Understanding And Journey": {
      score: session?.memberUnderstandingAndJourney.Rating, // Score out of 5
      analysis: session?.memberUnderstandingAndJourney.Comments,
      strengths: session?.memberUnderstandingAndJourney.Strengths,
      areasForImprovement: session?.memberUnderstandingAndJourney["Areas for Improvement"],
    },
    "Preparation": {
      score: session?.preparation.Rating, // Score out of 5
      analysis: session?.preparation.Comments,
      strengths: session?.preparation.Strengths,
      areasForImprovement: session?.preparation["Areas for Improvement"],
    },
    "The Gap": {
      score: session?.theGap.Rating, // Score out of 5
      analysis: session?.theGap.Comments,
      strengths: session?.theGap.Strengths,
      areasForImprovement: session?.theGap["Areas for Improvement"],
    },
    "Unpack": {
      score: session?.unpack.Rating, // Score out of 5
      analysis: session?.unpack.Comments,
      strengths: session?.unpack.Strengths,
      areasForImprovement: session?.unpack["Areas for Improvement"],
    },
  };
  const handleCriterionClick = (criterion) => {
    const element = document.getElementById(`criterion-${criterion.toLowerCase().replace(/\s+/g, '-')}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    let done = 0;
    let notDone = 0;

    const criteria = [
      session?.introduction,
      session?.cashFlowTour,
      session?.closingAndFollowUp,
      session?.FreedomMethodPresentation,
      session?.memberUnderstandingAndJourney,
      session?.preparation,
      session?.theGap,
      session?.unpack,
    ];

    criteria.forEach(criterion => {
      if (criterion.Performance === "Done") {
        done++;
      } else {
        notDone++;
      }
    });

    setDoneCount(done);
    setNotDoneCount(notDone);
  }, []);

   useEffect(()=>{
    const parseTags = JSON.parse(session.tag)
    setTags(parseTags)
   },[])
  const handleUpdateTags = (newTags) => {
    setTags(prev => [...prev,newTags])
    setTagsFlag(prev=>!prev)

  };

  return (
    <div
   
    >
      <div className="modal-dialog ">
        <div className="modal-content">

          
          <div className="container-fluid" style={{"padding":"0px"}}>
            <div className="row g-0">
              {/* Left Column */}
              <div className="col-lg-8"  style={{"paddingLeft":"0px"}}>
                <SessionVideo session={session} 
             
                />
                <TagsSections tags={tags} setTagsFlag={setTagsFlag} listUniqueTags={listUniqueTags} setTags={setTags} onUpdateTags={handleUpdateTags} sessionType={"specialist"} sessionId={session.id.replace(/(strategist-|specialist-)/, "")}
                />
                <div
                  className="row"
                  style={{
                    margin: 0,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <SessionRating session={session} /> */}
                  {/* <SessionScore session={session} />
                  <TopicsCovered session={session} /> */}


                </div>

                {/* <SessionSummary session={session} /> */}
  
                <div>
                <div className="card mb-4 shadow-sm" >
      <div className="card-body">
          <h2 className="h4 fw-medium text-dark mb-0">Summary</h2>
          <div className="mb-3 mt-2">
          <p className="text-dark mb-0 small"   style={{ lineHeight: '1.5', color: 'black', fontWeight: 'normal' }}
          >{session?.summary.toLowerCase().replace(/(?:^|\.\s*)([a-z])/g, (match) => match.toUpperCase()) || "No Summary"}</p>
          </div>
          </div>
          </div>

                </div>
                <div>
                               
                      <CriterionCard criterionId={session?.id} title={"Introduction"} isDone={(session?.introduction.Performance == "Done") ? true : false} rating={session?.introduction.Rating} comments={session?.introduction.Comments} strengths={session?.introduction.Strengths} areasForImprovement={session?.introduction["Areas for Improvement"] }  percent={10}/>
                      </div>

                      <div>
                               
                               <CriterionCard criterionId={session?.id} title={"Clash Flow Tour"} isDone={(session?.cashFlowTour.Performance == "Done") ? true : false} rating={session?.cashFlowTour.Rating} comments={session?.cashFlowTour.Comments} strengths={session?.cashFlowTour.Strengths} areasForImprovement={session?.cashFlowTour["Areas for Improvement"] } percent={10}/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session?.id} title={"Closing And Follow Up"} isDone={(session?.closingAndFollowUp.Performance == "Done") ? true : false} rating={session?.closingAndFollowUp.Rating} comments={session?.closingAndFollowUp.Comments} strengths={session?.closingAndFollowUp.Strengths} areasForImprovement={session?.closingAndFollowUp["Areas for Improvement"] } percent={15}/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session?.id} title={"Freedom Method Representation"} isDone={(session?.FreedomMethodPresentation.Performance == "Done") ? true : false} rating={session?.FreedomMethodPresentation.Rating} comments={session?.FreedomMethodPresentation.Comments} strengths={session?.FreedomMethodPresentation.Strengths} areasForImprovement={session?.FreedomMethodPresentation["Areas for Improvement"] } percent={10}/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session?.id} title={"Member Understanding And Journey"} isDone={(session?.memberUnderstandingAndJourney.Performance == "Done") ? true : false} rating={session?.memberUnderstandingAndJourney.Rating} comments={session?.memberUnderstandingAndJourney.Comments} strengths={session?.memberUnderstandingAndJourney.Strengths} areasForImprovement={session?.memberUnderstandingAndJourney["Areas for Improvement"] } percent={5}/>
                               </div>

                               <div>
                               
                               <CriterionCard criterionId={session?.id} title={"Preparation"} isDone={(session?.preparation.Performance == "Done") ? true : false} rating={session?.preparation.Rating} comments={session?.preparation.Comments} strengths={session?.preparation.Strengths} areasForImprovement={session?.preparation["Areas for Improvement"] } percent={5}/>
                               </div>

                               <div>
                               
                               <CriterionCard criterionId={session?.id} title={"The Gap"} isDone={(session?.theGap.Performance == "Done") ? true : false} rating={session?.theGap.Rating} comments={session?.theGap.Comments} strengths={session?.theGap.Strengths} areasForImprovement={session?.theGap["Areas for Improvement"] } percent={20}/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session?.id} title={"Unpack"} isDone={(session?.unpack.Performance == "Done") ? true : false} rating={session?.unpack.Rating} comments={session?.unpack.Comments} strengths={session?.unpack.Strengths} areasForImprovement={session?.unpack["Areas for Improvement"] } percent={25}/>
                               </div>
                               
                {/* <div
                  className="row mb-4"
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 className="h2 fw-medium mb-4" style={{ color: "rgb(17, 24, 39)", fontFamily: "system-ui" }}
                  >Performance Analysis</h3>

                  <SessionStrength session={session} />

                  <SessionWeakness session={session} />
                </div> */}

                {/* <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                  <SessionRecommendations session={session} />
                </div>

                <div className="row mt-4" style={{ marginLeft: 0, marginRight: 0 }}>
                  <SessionFlags session={session} />
                </div>

                <div className="row mt-4" style={{ marginLeft: 0, marginRight: 0 }}>
                  <SessionConclusion session={session} />

                </div> */}
              </div>
              <div className="col-lg-4 " style={{"padding":"0px","paddingLeft":"24px"}}>
              <div className="card" style={{"position":"sticky","top":"5.5rem"}}>
              {/* Tab Navigation - Fixed */}
              <div style={{"borderTopLeftRadius":"0.5rem","borderTopRightRadius":"0.5rem","borderBottomWidth":"1px","borderBottomStyle":"solid","backgroundColor":"#ffffff","borderBottomColor":"#D1D5DB"}}>
                <div style={{"display":"flex","textAlign":"center"}}>
                  <div
                    onClick={() => setActiveTab('transcript')}
                    style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"1rem","paddingRight":"1rem","flex":"1 1 0%","borderBottomWidth":"2px","borderBottomStyle":"solid","fontSize":"0.875rem","cursor":"pointer","lineHeight":"1.25rem","fontWeight":500,...( activeTab == 'transcript'
                      ? {"borderColor":"#1f9ad6","color":"#1f9ad6"} : {"borderColor":"transparent","color":"#6B7280",":hover":{"borderColor":"#D1D5DB","color":"#374151"}})}}
               
                  >
                    Read Transcript
                  </div>
                  <div
                    onClick={() => setActiveTab('performance')}
                    style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"1rem","borderBottomStyle":"solid","paddingRight":"1rem","flex":"1 1 0%","borderBottomWidth":"2px","fontSize":"0.875rem","cursor":"pointer","lineHeight":"1.25rem","fontWeight":500,...( activeTab === 'performance' ? {"borderColor":"#1f9ad6","color":"#1f9ad6"} : {"borderColor":"transparent","color":"#6B7280",":hover":{"borderColor":"#D1D5DB","color":"#374151"}})}}
                  
                  >
                    Performance
                  </div>
                </div>
                      </div>
                     {activeTab == "performance" ?  <div  style={{"borderBottomRightRadius":"0.5rem","borderBottomLeftRadius":"0.5rem","backgroundColor":"#ffffff","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)", height: 'calc(100vh - 180px) ' }}>
                      <div style={{"overflowY":"auto","padding":"1rem","height":"100%"}}>

                      <PerformanceSummary
                        averageScore={session?.rating}
                        doneTasks={doneCount}
                        notDoneTasks={notDoneCount}
                      />
                      <CriteriaList criteria={mockCriteriaData} onCriterionClick={handleCriterionClick} />
                      </div>

                      </div> : <div  style={{"borderBottomRightRadius":"0.5rem","borderBottomLeftRadius":"0.5rem","backgroundColor":"#ffffff","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)", height: 'calc(100vh - 180px) ' }}>
                      <div style={{"overflowY":"auto","padding":"1rem","height":"100%"}}>
                        Read transcript </div> </div>}
                      </div>
                      </div>
                      </div>
                      </div>
                      <ChatbotPopup/>

                    </div>
                    {/* {
                      openChatBot ? <div
                      onClick={() => setOpenChatBot(true)}
                      className="chatBotOpenButton"
                    >
                      <MessageSquare className="h-6 w-6" />
                    </div> : <ChatbotPopup/>
                    } */}
                  </div>

                



    </div>
  );
}
