import React from "react";
import { format, formatDistanceToNow } from "date-fns";
import { Users, Clock, Star, CheckCircle2, X, Calendar } from "lucide-react";

export function SessionCard({ session, onClick, viewType }) {
  let totalScore = 0;
  let count = 0;
  if(session.representative.role == "Strategist")
  // Calculate days ago
{

const sections = [
  session.rapportBuild.Rating,
  session.qualifyingAppointment.Rating,
  session.setTheToneEarly.Rating,
  session.setAnAgenda.Rating,
  session.unpackDiscovery.Rating,
  session.freedomKnowledge.Rating,
  session.personalStory.Rating,
  session.financeAssessment1.Rating,
  session.financeAssessment2.Rating,
  session.lazyEquity.Rating,
  session.cashFlow.Rating,
  session.ultimateProperty.Rating,
  session.freedomCriteria.Rating,
  session.recapDecision.Rating,
  session.securePropertySession.Rating,
  session.askForReferral.Rating,
  session.expectations_decision.Rating,
  session.finalRecap.Rating
];

sections.forEach(score => {
  if (score !== undefined) { // Ensure the score is valid
    totalScore += Number(score);
    count++;
  }
});
}
const averageScore = totalScore / count;

  const sessionDate = new Date(session.date);
  const daysAgo = formatDistanceToNow(sessionDate, { addSuffix: true });

  // Updated formatDate function
  function formatDate(dateString) {
    return format(new Date(dateString), "EEE, do MMMM, yyyy"); // e.g., "Tue, 8th January, 2025"
  }

  const getScoreColor = (score) => {
    if (score >= 3) return 'bg-success-subtle text-success';
    return 'bg-danger-subtle text-danger';
  };
  const getValidityColor = (validity) => {
    if (validity == "valid") return 'bg-success-subtle text-success';
    return 'bg-danger-subtle text-danger';
  };
const tags = JSON.parse(session.tag)
  return (
    <div
      className={`card h-100 cursor-pointer shadow-sm hover-effect ${
        viewType === "list" ? "d-flex flex-row" : ""
      }`}
      onClick={() => onClick(session.id)}
      style={{ cursor: "pointer" }}
    >
      <div className="card-body p-3 d-flex flex-column flex-grow-1">
        {/* Header */}
        <div className="d-flex align-items-center justify-content-between mb-3">
          {/* Avatar and Representative Info */}
          <div className="d-flex align-items-center">
            <div
              className=" text-white rounded-circle p-2 me-3 d-flex align-items-center justify-content-center"
              style={{ width: "36px", height: "36px", flexShrink: 0, backgroundColor: "#1f9ad6" }}
            >
              <span className="small fw-medium">
                {session.representative.name
                  .split(" ")
                  .map((n) => n[0])
                  .join("")}
              </span>
            </div>
            <div className="flex-grow-1">
              <div style={{"fontWeight":500,"color":"#111827"}}>
                {/* {session.representative.name}{" "}
                <span className="fw-semibold">/</span> */}
                {session.title}
              </div>
              <div className="text-muted small">
                {session.representative.role}
              </div>
            </div>
          </div>

          {/* List View Specific Info */}
          {viewType === "list" && (
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex align-items-center">
                <Clock size={14} className="text-primary me-1" />
            
                <span className="text-body-secondary small">
                  {formatDate(session.date)}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <Users size={14} className="text-primary me-1" />
                <span className="text-body-secondary small">
                  {session.participants.length}
                </span>
              </div>
              <div
                className={`rounded px-3 py-2 ${getScoreColor(session.representative.role=="Specialist" ? session.rating : averageScore)}`}
                role="button"
              >
                 <div className="d-flex align-items-center gap-1">
             {session.validity == "valid" ? <CheckCircle2 size={16}/> : <X size={16}/>}
                <span className="fw-semibold" style={{fontSize:14.8}}>{session.validity == "valid" ? "Valid" : "Invalid"}
</span>
              </div>
            </div>
            
            <div
                className={`rounded px-2 py-1 ${getScoreColor(session.representative.role=="Specialist" ? session.rating : averageScore)}`}
                role="button"
              >
                <div className="d-flex align-items-center gap-1">
                  <Star size={16} />
                  <span style={{fontSize:14.8}} className="fw-semibold">{session.representative.role=="Specialist" ? session.rating ? session.rating : null : averageScore ? averageScore.toFixed(1) : null}
</span>
                </div>
              </div>
              
            </div>
          )}
            {viewType !== "list" && 
            <div style={{"display":"flex","gap":"0.5rem","marginBottom":"30px","marginLeft":"5px"}}>
              <div
              className={`rounded px-2 py-1 ${getValidityColor(session.validity)}`}
              role="button"
            >
              <div className="d-flex align-items-center gap-1">
             {session.validity == "valid" ? <CheckCircle2 size={16}/> : <X size={16}/>}
                <span className="fw-semibold" style={{fontSize:14.8}}>{session.validity == "valid" ? "Valid" : "Invalid"}
</span>
              </div>
            </div>
            
            <div
                className={`rounded px-2 py-1 ${getScoreColor(session.representative.role=="Specialist" ? session.rating : averageScore)}`}
                role="button"
              >
                <div className="d-flex align-items-center gap-1">
                  <Star size={16} />
                  <span style={{fontSize:14.8}} className="fw-semibold">{session.representative.role=="Specialist" ? session.rating ? session.rating : null : averageScore ? averageScore.toFixed(1) : null}
</span>
                </div>
              </div>
              
            </div>
              }
        </div>

        {/* Footer - Non-List View Info */}
        {viewType !== "list" && (
          <div className="d-flex align-items-center gap-3">
            <div className="d-flex align-items-center">
              <Clock size={14} className="text-primary me-1" />
              <span className="text-body-secondary small">
                  {session.duration} mins
                </span>
            
            </div>
            <div className="d-flex align-items-center">

            <Calendar className="text-primary me-1" style={{"marginRight":"0.25rem","width":"0.875rem","height":"0.875rem"}}/> 
            <span className="text-body-secondary small" >
                 
                 {formatDate(session.date)}
                </span>
                </div>

            <div className="d-flex align-items-center">
              <Users size={14} className="text-primary me-1" />
              <span className="text-body-secondary small">
                {session.participants.length}
              </span>
            </div>
           
          </div>
          
        )}
         <div style={{"display":"flex","marginTop":"0.5rem","flexWrap":"wrap","gap":"0.375rem"}}>
      {tags?.map((tag, index) => (
        <span
          key={index}
          style={{"paddingTop":"0.125rem","paddingBottom":"0.125rem","paddingLeft":"0.375rem","paddingRight":"0.375rem","borderRadius":"9999px","fontSize":"0.75rem","lineHeight":"1rem","color":"#2563EB","backgroundColor":"#EFF6FF"}}
        >
          {tag}
        </span>
      ))}
    </div>
      </div>
    </div>
  );
}
