import React, { useEffect } from 'react';
import { Info } from 'lucide-react';
import { Tooltip } from 'bootstrap';

export const CriteriaList = ({ criteria, onCriterionClick }) => {
    useEffect(() => {
        // Initialize all tooltips
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggerList.forEach(element => {
            new Tooltip(element, {
                html: true,
                placement: 'right',
                trigger: 'hover',
                template: `
          <div class="tooltip" role="tooltip">
            <div class="tooltip-inner p-3 bg-dark text-white" style="max-width: 300px; text-align: left; box-shadow: 0 2px 8px rgba(0,0,0,0.2); border-radius: 6px;">
              <div class="tooltip-content"></div>
            </div>
          </div>
        `
            });
        });

        // Cleanup tooltips on unmount
        return () => {
            tooltipTriggerList.forEach(element => {
                const tooltip = Tooltip.getInstance(element);
                if (tooltip) {
                    tooltip.dispose();
                }
            });
        };
    }, [criteria]);

    const getStatusBadge = (score) => {
        return score >= 3 ? (
            <span className="badge bg-success me-2">Done</span>
        ) : (
            <span className="badge bg-danger me-2">Not Done</span>
        );
    };

    const getAnalysisContent = (criterion) => {
        const data = criteria[criterion] || {
            analysis: "Analysis not available",
            strengths: "Not evaluated",
            areasForImprovement: "Not evaluated"
        };
    
        return `
          <div class="mb-3">
            <div class="fw-medium mb-2">Analysis:</div>
            <div class="text-white-75 mb-3">${data.analysis || "No analysis available"}</div>
            <div class="fw-medium mb-2">Strengths:</div>
            <div class="text-white-75 mb-3">${data.strengths || "No strengths listed"}</div>
            <div class="fw-medium mb-2">Areas for Improvement:</div>
            <div class="text-white-75">${data.areasForImprovement || "No areas for improvement"}</div>
          </div>
        `;
    };

    return (
        <>
            {Object.entries(criteria).map(([criterion, { score }]) => (
                <div
                    key={criterion}
                    className="mb-4 cursor-pointer"
                    onClick={() => onCriterionClick?.(criterion)}
                    style={{ transition: 'transform 0.1s' }}
                    onMouseDown={e => e.currentTarget.style.transform = 'scale(0.98)'}
                    onMouseUp={e => e.currentTarget.style.transform = 'scale(1)'}
                    onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
                    role="button"
                    tabIndex={0}
                >
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="d-flex align-items-center">
                            {getStatusBadge(score)}
                            <span className="fw-medium me-2">{criterion}</span>
                            <div className="position-relative" style={{ cursor: 'help' }}>
                                <Info
                                    size={14}
                                    className="text-muted"
                                    data-bs-toggle="tooltip"
                                    data-bs-html="true"
                                    title={getAnalysisContent(criterion)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </div>
                        </div>
                        <span className="badge bg-primary" style={{marginLeft:"5px"}}>{score}/5</span>
                    </div>
                    <div className="progress" style={{ height: '6px' }}>
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${(score / 5) * 100}%` }}
                            aria-valuenow={score}
                            aria-valuemin={0}
                            aria-valuemax={5}
                        ></div>
                    </div>
                </div>
            ))}
        </>
    );
};
