import React, { useEffect, useState } from "react";
import "./Settings.css"
import { deleteSettingUser, getuserdata, sendUser } from "../../api/settingApi";
import { Check, Clipboard, X } from "lucide-react";
import exportLoadingGif from "../../assets/exportloading.gif"

const Settings = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [passwordResponse,setPasswordResponse] = useState("")
  const [showModal,setShowModal] = useState(false)
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);

useEffect(()=>{
  const responsedata = async()=>{
    const data= await getuserdata()
    setUsers(data)
  }
  responsedata()
},
  [newUser,showModal])


  const handleCopy = () => {
    navigator.clipboard.writeText(passwordResponse.password);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
const senduserFunction = async()=>{
  try{
    setShowModal(true)

    const responsePassword = await sendUser(newUser.name,newUser.email,newUser.role)
    setPasswordResponse(responsePassword)
    
} catch (error) {
  console.error("Error exporting data:", error);
}
}

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newUser.name && newUser.email && newUser.role) {
      senduserFunction()
      // setUsers([...users, { ...newUser, invitationStatus: "Pending" }]);
      setNewUser({ name: "", email: "", role: "" });
    }
  };

  const handleDelete = async(id) => {
    console.log(id)
    setLoading(true); 

    try{

   await deleteSettingUser(id);
   setNewUser({ name: "", email: "", role: "" });

    }
    catch(error){
      console.error("Error getting data:", error);

    }finally {
      setLoading(false); 
  }

  }

  const styles = {
    container: {
      padding: "24px",
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
    },
    card:{"padding":"1.5rem","borderRadius":"0.5rem","backgroundColor":"#ffffff","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)"},
    title: {
      fontSize: "20px",
      fontWeight: "600",
      color: "#1a202c",
      marginBottom: "24px",
    },
    formGroup: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gap: "16px",
    },
    label: {
      display: "block",
      fontSize: "14px",
      fontWeight: "500",
      color: "#4a5568",
      marginBottom: "4px",
    },
    input: {
      width: "100%",
      padding: "8px 12px",
      border: "1px solid #d2d6dc",
      "border-radius": "0.375rem",
      fontSize: "14px",
    },
    select: {
      width: "100%",
      padding: "8px 12px",
      border: "1px solid #d2d6dc",
      "border-radius": "0.375rem",
      fontSize: "14px",
    },
    button: {
      padding: "8px 16px",
      backgroundColor: "#1f9ad6",
      color: "#fff",
      "border-radius": "0.375rem",
      fontSize: "14px",
      fontWeight: "500",
      cursor: "pointer",
      border: "none",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginTop: "16px",
    },
    th: {
      textAlign: "left",
      padding: "12px",
      fontSize: "12px",
      fontWeight: "600",
      color: "#718096",
      backgroundColor: "#f9fafb",
      textTransform: "uppercase",
    },
    td: {
      padding: "12px",
      fontSize: "14px",
      color: "#4a5568",
    },
    statusAccepted: {
      padding: "4px 8px",
      backgroundColor: "#c6f6d5",
      color: "#276749",
      borderRadius: "12px",
      fontSize: "12px",
    },
    statusPending: {
      padding: "4px 8px",
      backgroundColor: "#fefcbf",
      color: "#b7791f",
      borderRadius: "12px",
      fontSize: "12px",
    },
    deleteButton: {
      color: "#e53e3e",
      cursor: "pointer",
      background: "none",
      border: "none",
      padding: "0",
    },
  };

  return (
    <div style={styles.container}>
      {/* Invite User Form */}
      <div style={styles.card}>
        <h2 style={styles.title}>Invite New User</h2>
        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <div>
              <label style={styles.label} htmlFor="name">
                Name
              </label>
              <input
                style={styles.input}
                type="text"
                id="name"
                value={newUser.name}
                onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                placeholder="Enter name"
                required
              />
            </div>
            <div>
              <label style={styles.label} htmlFor="email">
                Email
              </label>
              <input
                style={styles.input}
                type="email"
                id="email"
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                placeholder="Enter email"
                required
              />
            </div>
            <div>
              <label style={styles.label} htmlFor="role">
                Role
              </label>
              <select
                style={styles.select}
                id="role"
                value={newUser.role}
                onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                required
              >
                <option value="">Select role...</option>
                <option value="User">User</option>
                <option value="Reviewer">Reviewer</option>
                <option value="Admin">Admin</option>
              </select>
            </div>
          </div>
          <div style={{ marginTop: "16px", textAlign: "right" }}>
            <button style={styles.button} type="submit">
              Send Invite
            </button>
          </div>
        </form>
      </div>
      {
        showModal &&     <div
        className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 50,
          fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        }}
      >
        <div
          className="bg-white rounded shadow"
          style={{
            width: "320px",
            padding: "12px",
            borderRadius: "12px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* Header */}
          <div
            className="d-flex align-items-center justify-content-between border-bottom"
            style={{
              paddingBottom: "8px",
              marginBottom: "8px",
              borderBottom: "1px solid #e5e7eb",
            }}
          >
            <div
              className="d-block"
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#374151",
              }}
            >
              Invite Sent Successfully
            </div>
            <X
              className="ms-auto"
              style={{
                cursor: "pointer",
                color: "#9CA3AF",
              }}
              onMouseOver={(e) => (e.target.style.color = "#374151")}
              onMouseOut={(e) => (e.target.style.color = "#9CA3AF")}
              onClick={() => setShowModal(false)}
            />
          </div>
      
          {/* Content */}
          <div
            className="d-flex align-items-center"
            style={{
              gap: "8px",
              fontSize: "14px",
              color: "#374151",
            }}
          >
            <div
              className="d-block"
              style={{
                fontWeight: "500",
              }}
            >
              Password:
            </div>
            <div
              className="d-block"
              style={{
                fontWeight: "500",
              }}
            >
              ••••••••
            </div>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                color: copied ? "#10B981" : "#6B7280",
              }}
              onClick={handleCopy}
              onMouseOver={(e) => (e.target.style.color = copied ? "#059669" : "#374151")}
              onMouseOut={(e) => (e.target.style.color = copied ? "#10B981" : "#6B7280")}
            >
              {copied ? (
                <>
                  <Check size={16} />
                  Copied
                </>
              ) : (
                <Clipboard size={16} />
              )}
            </div>
          </div>
        </div>
      </div>
      
      }
      {/* Users Table */}
      <div style={{"overflow":"hidden","borderRadius":"0.5rem","backgroundColor":"#ffffff","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)"}}>
        <h2 style={{"padding":"1.5rem","paddingBottom":"1rem","fontSize":"1.25rem","lineHeight":"1.75rem","fontWeight":600,"color":"#111827"}}>Users</h2>
        <table style={{"width":"100%"}}>
          <thead style={{"backgroundColor":"#F9FAFB"}}>
            <tr>
              <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"1.5rem","paddingRight":"1.5rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Name</th>
              <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"1.5rem","paddingRight":"1.5rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Email</th>
              <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"1.5rem","paddingRight":"1.5rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Role</th>
              <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"1.5rem","paddingRight":"1.5rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Invitation</th>
              <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"1.5rem","paddingRight":"1.5rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"right","color":"#6B7280","textTransform":"uppercase"}}>Action</th>
            </tr>
          </thead>
          <tbody style={{"backgroundColor":"#ffffff"}} >
            {users.map((user) => (
              
              <tr key={user.id} style={{"border-bottom": "1px solid #e5e7eb"}} className="settingstablebody">
                <td style={{"paddingTop":"1rem","paddingBottom":"1rem","paddingLeft":"1.5rem","paddingRight":"1.5rem","fontSize":"0.875rem","lineHeight":"1.25rem","color":"#111827","whiteSpace":"nowrap"}}>{user.name}</td>
                <td style={{"paddingTop":"1rem","paddingBottom":"1rem","paddingLeft":"1.5rem","paddingRight":"1.5rem","fontSize":"0.875rem","lineHeight":"1.25rem","color":"#6B7280","whiteSpace":"nowrap"}}>{user.email}</td>
                <td style={{"paddingTop":"1rem","paddingBottom":"1rem","paddingLeft":"1.5rem","paddingRight":"1.5rem","fontSize":"0.875rem","lineHeight":"1.25rem","color":"#6B7280","whiteSpace":"nowrap"}}>{user.role}</td>
                <td style={{"paddingTop":"1rem","paddingBottom":"1rem","paddingLeft":"1.5rem","paddingRight":"1.5rem","fontSize":"0.875rem","lineHeight":"1.25rem","color":"#111827","whiteSpace":"nowrap"}}>
                  <span
                    style={
                      user.role === "Accepted"
                        ? {"display":"inline-flex","paddingTop":"0.25rem","paddingBottom":"0.25rem","paddingLeft":"0.5rem","paddingRight":"0.5rem","borderRadius":"9999px","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"color":"#065F46","backgroundColor":"#D1FAE5"}
                        : {"display":"inline-flex","paddingTop":"0.25rem","paddingBottom":"0.25rem","paddingLeft":"0.5rem","paddingRight":"0.5rem","borderRadius":"9999px","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"color":"#92400E","backgroundColor":"#FEF3C7"}
                    }
                  >
                    Pending
                  </span>
                </td>
                <td style={{"paddingTop":"1rem","paddingBottom":"1rem","paddingLeft":"1.5rem","paddingRight":"1.5rem","fontSize":"0.875rem","lineHeight":"1.25rem","textAlign":"right","whiteSpace":"nowrap"}}>
                  {loading ?        <img src={exportLoadingGif} alt="Loading..." width="20"  />
                   : <button
                    style={styles.deleteButton}
                    onClick={() => handleDelete(user.id)}
                  >
                    Delete
                  </button>}
                </td>
              </tr>
                  
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Settings;
