import React from "react";
import Next from "../assets/next.svg";
import Pre from "../assets/previous.svg";
import "./PaginationControl.css"
const PaginationControl = ({
    currentPage,
    totalPages,
    tempPage,
    setCurrentPage,
    updateTempPage,
    itemsPerPage,
    setItemsPerPage
}) => {

    // Function to handle next page
      const nextPage = () => {
        if (currentPage < totalPages) {
          updateTempPage(currentPage + 1);
          setCurrentPage(currentPage + 1);
        }
      };
    
      // Function to handle previous page
      const prevPage = () => {
        if (currentPage > 1) {
          updateTempPage(currentPage - 1);
          setCurrentPage(currentPage - 1);
        }
      };
    
    return (
        <div className="row">
            <div className="col-md-6"></div>
            <div className="bottom-filter-div">
                <p style={{ color: "black", fontSize: 12, marginBottom: 0 }}>
                    Rows:
                </p>
                &nbsp;
                <select
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                    className="form-control"
                    style={{ width: 70, fontSize: 12 }}
                >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                &nbsp;&nbsp;
                <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className="btn btn-sm"
                    style={{
                        backgroundColor: currentPage === 1 ? "#9ECFE8" : "#1F9AD6",
                        border: 0,
                    }}
                >
                    <img src={Pre} alt="Previous" />
                </button>
                <div
                    style={{
                        width: 80,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <p style={{ fontSize: 12, marginBottom: 0, color: "black" }}>
                        {tempPage} of {totalPages}
                    </p>
                </div>
                <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages || totalPages === 0}
                    className="btn btn-sm"
                    style={{
                        backgroundColor:
                            currentPage === totalPages || totalPages === 0
                                ? "#9ECFE8"
                                : "#1F9AD6",
                        border: 0,
                    }}
                >
                    <img src={Next} alt="Next" />
                </button>
                &nbsp;&nbsp;
                <div className="btn btn-sm specific-page-div">
                    <p className="bottom-filter-string">Page</p>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="1"
                        value={tempPage}
                        onChange={(e) => {
                            const numValue = parseInt(e.target.value, 10);
                            if (!isNaN(numValue)) {
                                if (numValue > totalPages) {
                                    updateTempPage(totalPages);
                                } else if (numValue < 1) {
                                    updateTempPage(1);
                                } else {
                                    updateTempPage(numValue);
                                }
                            } else {
                                updateTempPage("");
                            }
                        }}
                        style={{ width: 62, fontSize: 12, borderRadius: 0, border: 0 }}
                    />
                </div>
                <button
                    onClick={() => {
                        const pageNum = parseInt(tempPage, 10);
                        if (!isNaN(pageNum) && pageNum >= 1 && pageNum <= totalPages) {
                            setCurrentPage(pageNum);
                        }
                    }}
                    className="btn btn-sm"
                    style={{ backgroundColor: "#1f9ad6", color: "white" }}
                >
                    Go
                </button>
            </div>
        </div>
    )
}

export default PaginationControl