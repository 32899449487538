import { FileText, Send, Upload, X } from "lucide-react"
import { useEffect, useRef, useState } from "react";
import { sendSpecificChat } from "../api/legalaiApi";

  

const LegalChatBar = ({messages,setActiveTab,setChatApiErrorCase,
  setSaveInputMessage,
  currentChatId,
  setMessages,
  inputMessage,
  setGetSpecificMessageLoading,
  setInputMessage,
  files,
  setFiles,
  selectedThumbnail,
  setSelectedThumbnail,
  selectedDocument,
  setSelectedDocument,
  markingDocument,setMarkingDocument,
  chatDocuments,
  setChatDocuments}) =>{
  
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false); 
    const textareaRef = useRef(null);
    const [fileExceed,setFileExceed] = useState('')

    useEffect(() => {
      // Adjust height based on content
      if (textareaRef.current) {
        textareaRef.current.style.height = "24px"; // Initial single-line height
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        
      }
    }, [inputMessage]);
    // const handleFileUpload = (event) => {
    //     const newFiles = Array.from(event.target.files || []);
    //     setFiles(prev => [...prev, ...newFiles]);
        
    //     newFiles.forEach((file) => {
    //       const url = URL.createObjectURL(file);
    //         setSelectedThumbnail(url);
          
    //     });
    //     setMarkingDocument((files.length))
    //     setActiveTab("documents")

    //   };

    const handleFileUpload = (event) => {
      const maxFileSize = 10 * 1024 * 1024; 
      const newFiles = Array.from(event.target.files || []);
  
      const validFiles = newFiles.filter(file => {
          if (file.size > maxFileSize) {
              return false;
          }
          return true;
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      if (validFiles.length === 0) 
      {
        setFileExceed("File is greater than 10 MB.")
   
        return;
      }
  
      setFiles((prev) => {
        const updatedFiles = [...prev, ...validFiles];
        setMarkingDocument(updatedFiles.length - 1); 
        return updatedFiles;
      });  
      validFiles.forEach((file) => {
          const url = URL.createObjectURL(file);
          setSelectedThumbnail(url);
      });
  
      // setMarkingDocument(files.length);
      setActiveTab("documents");
  };
  
      console.log("this is the current  chat id",inputMessage)
      const handleSendMessage = async() => {
        const userid=  localStorage.getItem("user_id")
        const options = {
          weekday: 'short',
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZone: 'Australia/Sydney',
          timeZoneName: 'short',
        };
        
        const currentDate = new Date().toLocaleString('en-AU', options);
        if (!inputMessage.trim() && files.length === 0) return;
        setSaveInputMessage({message:inputMessage,files:files,timing:currentDate})
        setLoading(true); 
        // setGetSpecificMessageLoading(true)
        const directMessage = inputMessage
        const directFiles = files
        try {
          setInputMessage("");
          setFiles([]);
          const sendingMessageToApi = await sendSpecificChat(currentChatId, directMessage, directFiles,userid);
    
          const newAttachments = files.map((file) => ({
            name: file.name,
            type: file.type,
            size: `${(file.size / 1024).toFixed(1)} KB`,
            url: URL.createObjectURL(file),
          }));
    
          const newMessage = {
            id: Date.now().toString(),
            type: "user",
            content: inputMessage,
            timestamp: new Date(),
            attachments: newAttachments,
          };
    
          setMessages((prev) => [...prev, sendingMessageToApi]);
          setChatDocuments((prev) => [...prev, ...newAttachments]);
          setSaveInputMessage('')
        } catch (error) {
          setChatApiErrorCase(true)
          console.error("Error sending message:", error);
        } finally {
          setLoading(false); // Re-enable send button
          // setGetSpecificMessageLoading(false)
          setActiveTab("documents")
        }
       
          
         
      };

      
  const removeFile = (index) => {
    setFiles(prev => prev.filter((_, i) => i !== index));
    setSelectedDocument(null);
    setSelectedThumbnail(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const setThumbnailFunction = (file,index) =>{
    const url = URL.createObjectURL(file);
    setMarkingDocument(index)
setSelectedThumbnail(url)
      setActiveTab("documents");

  }
  useEffect(() => {
    if (fileExceed) {
      const timer = setTimeout(() => setFileExceed(''), 2000);
      return () => clearTimeout(timer);
    }
  }, [fileExceed]);
  console.log("this is selected thumbnail",selectedThumbnail)
    return(
        <div style={{"padding":"1rem","borderTop":"1px solid rgba(0, 0, 0, 0.12)","backgroundColor":"#ffffff"}}>
        {fileExceed != "" ?
        <div style={{"paddingTop":"0.25rem","paddingBottom":"0.25rem","paddingLeft":"0.75rem","paddingRight":"0.75rem"}}>
        <span style={{"maxWidth":"20rem","overflow":"hidden","textOverflow":"ellipsis","whiteSpace":"nowrap"}}>{fileExceed}</span> 
        </div>
        : files.length > 0 && (
          <div style={{"display":"flex","marginBottom":"1rem","flexWrap":"wrap","gap":"0.5rem"}}>
            { files.map((file, index) => (
              <div
              onClick={()=>setThumbnailFunction(file,index)}
                key={index}
                style={{"display":"flex",...(markingDocument === index && {"border":"1px solid rgba(0, 0, 0, 0.12)"}),"cursor":"pointer","paddingTop":"0.25rem","paddingBottom":"0.25rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","gap":"0.5rem","alignItems":"center","borderRadius":"9999px","fontSize":"0.875rem","lineHeight":"1.25rem","backgroundColor":"#F3F4F6"}}
              >
                <FileText style={{"width":"1rem","height":"1rem","color":"#4B5563"}} />
                <span style={{"maxWidth":"20rem","overflow":"hidden","textOverflow":"ellipsis","whiteSpace":"nowrap"}}>{file.name}</span>
                <button
                   onClick={(event) => {
                    event.stopPropagation(); 
                    removeFile(index);
                  }}
style={{"padding":"0.25rem","borderRadius":"9999px",":hover":{"backgroundColor":"#E5E7EB"},"border":"none"}
}                >
                  <X style={{"width":"0.75rem","height":"0.75rem","color":"#4B5563"}} />
                </button>
              </div>
            ))}
          </div>
        )}
        <div style={{"display":"flex","gap":"0.5rem","alignItems":"center"}}>
          <button
            onClick={() => fileInputRef.current?.click()}
            style={{"padding":"0.5rem","borderRadius":"9999px",":hover":{"backgroundColor":"#F3F4F6"},"border":"none","background":"none"}}
          >
            <Upload style={{"width":"1.25rem","height":"1.25rem","color":"#4B5563"}} />
          </button>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            onChange={handleFileUpload}
            style={{"display":"none"}}
            accept=".pdf,.doc,.docx,.xls,.xlsx"
          />
          <textarea
                  ref={textareaRef}

  value={inputMessage}
  onChange={(e) => setInputMessage(e.target.value)}
  onKeyDown={(e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  }}
  placeholder="Type your message..."
  rows="1"
  style={{
    width: "100%",
    padding: "10px",
    paddingBottom:"12px",
    borderRadius: "20px",
    border: "1px solid #D1D5DB",
    outline: "none",
    fontSize: "16px",
    lineHeight: "1.5",
    resize: "none",
    overflow: "hidden",
    minHeight: "40px",
    boxShadow: "none",
    
  }}
/>
          <button
            onClick={handleSendMessage}
            disabled={loading || !inputMessage.trim() && files.length === 0}
style={{"padding":"0.5rem","paddingLeft":"10px","paddingRight":"10px","borderRadius":"9999px","color":"#ffffff","background":"#1f9ad6",":hover":{"background":"#1785bd"},"border":"none"}}
          >
            <Send style={{"width":"1.25rem","height":"1.25rem"}} />
          </button>
        </div>
      </div>
    )
}

export default LegalChatBar