import React from 'react';
import { Calendar } from 'lucide-react';

function SummaryDateRangeFilter({ fromDate, toDate, onFromDateChange, onToDateChange }) {
  const containerStyle = {"padding":"1rem","borderRadius":"0.5rem","backgroundColor":"#ffffff","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)"};

  const flexStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  };

  const inputStyle = {
    padding: '6px 12px',
    fontSize: '14px',
    border: '1px solid #d1d5db',
    "borderRadius":"0.375rem",
  };

  

  const labelStyle = {
    fontSize: '14px',
    fontWeight: '500',
    color: '#4b5563',
  };

  return (
    <div style={containerStyle}>
      <div style={flexStyle}>
        <Calendar style={{ height: '20px', width: '20px', color: '#1f9ad6' }} />
        <div style={flexStyle}>
          <div style={flexStyle}>
            <label style={labelStyle}>From:</label>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => onFromDateChange(e.target.value)}
              style={inputStyle}
            />
          </div>
          <div style={flexStyle}>
            <label style={labelStyle}>To:</label>
            <input
              type="date"
              value={toDate}
              onChange={(e) => onToDateChange(e.target.value)}
              style={inputStyle}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SummaryDateRangeFilter;
