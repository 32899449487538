import { CheckCircle2 } from "lucide-react";
import { useState } from "react";

const ValidityFilter = ({validityFilter,setValidityFilter})=>{
    // const [validityFilter, setValidityFilter] = useState('all');

    return(
        <div className="card shadow-sm mb-3">
        <div className="card-body">
            <div className="d-flex align-items-center mb-3">
          <CheckCircle2 style={{"marginRight":"0.5rem","width":"1.25rem","height":"1.25rem","color":"#1f9ad6"}} />
          <span style={{"fontWeight":500}}>Validity</span>
        </div>
        <select 
                  className="form-select"

          style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingLeft":"0.5rem","paddingRight":"0.5rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","width":"100%","fontSize":"0.875rem","lineHeight":"1.25rem"}}
          value={validityFilter}
          onChange={(e) => setValidityFilter(e.target.value)}
        >
          <option value="">All</option>
          <option value="valid">Valid</option>
          <option value="invalid">Invalid</option>
        </select>
      </div>
      </div>
    )
}

export default ValidityFilter

