import React, { useState, useEffect } from "react";
import ChatList from "../component/inbox-components/ChatList";
import ChatBox from "../component/inbox-components/ChatBox";

import useSocket from "../hooks/useSocket";

function Inbox() {
  const [selectedChat, updateSelectedChat] = useState({ type: null, index: 0 });
  const [chatList, updateChatList] = useState([]);
  const [broadcastChatList, updateBroadcastChatList] = useState([]);
  const [showItem,SetShowItem] = useState(true)
  const [makeHover, setMakeHover] = useState(false)
  const trigger = useSocket()

  useEffect(() => {
    console.log("Triggered Message Is", trigger);
  }, [trigger]);
 useEffect(()=>{
   setMakeHover(false)
 },[selectedChat])
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth < 611 ) {
          SetShowItem(false);
          } 
          else{
            SetShowItem(true)
          }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Trigger initially

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="row">
      <div className="cont">
       {showItem ? <ChatList
          selectedChat={selectedChat}
          updateSelectedChat={updateSelectedChat}
          chatList={chatList}
          updateChatList={updateChatList}
          broadcastChatList={broadcastChatList}
          updateBroadcastChatList={updateBroadcastChatList}
          trigger={trigger}
        /> : <button className="" onClick={()=>setMakeHover(true)}  style={{
          padding: "10px 20px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          height:"40px",
          whiteSpace: "nowrap",
          position:"fixed"

        }}> show list </button>}
        {makeHover && <div className="" style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim background
      zIndex: 1000, // Bring it above other elements
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}> 
    <div onClick={()=>setMakeHover(false)} style={{
          padding: "10px 20px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          height:"40px",
          whiteSpace: "nowrap",
          position:"fixed",
          top:"0",
          left:"0"

        }}> X</div>
          <ChatList
          selectedChat={selectedChat}
          updateSelectedChat={updateSelectedChat}
          chatList={chatList}
          updateChatList={updateChatList}
          broadcastChatList={broadcastChatList}
          updateBroadcastChatList={updateBroadcastChatList}
          trigger={trigger}
        /> </div>}
        <ChatBox
          selectedChat={selectedChat}
          chatList={chatList}
          broadcastChatList={broadcastChatList}
          trigger={trigger}
        />
      </div>
    </div>
  );
}

export default Inbox;
