import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import './CallTranscriptDashboard.css';

// Icons for sidebar
import {
  Search,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import Pagination from '../Pagination';
import PaginationControl from '../PaginationControl';

const CallAnalysisDashboard = () => {
  // State variables
  const [callData, setCallData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedCall, setSelectedCall] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState('All Agents');

  // Properly format date with padding for API
  function formatDate(date) {
    const d = date ? new Date(date) : new Date();

    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Pad with leading zero
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  // Set default date range
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);

  const [filters, setFilters] = useState({
    call_id: '',
    date_from: formatDate(tenDaysAgo).split('T')[0], // Just the date part for the input
    date_to: formatDate(today).split('T')[0]
  });

  // Search query for the search bar
  const [searchQuery, setSearchQuery] = useState('');

  // API base URL - change this to match your backend
  const API_BASE_URL = 'http://localhost:6004';

  // Fetch data when page, perPage or filters change
  useEffect(() => {
    fetchData();
  }, [currentPage, perPage]);

  // Function to fetch data from the API
  const fetchData = async () => {
    setLoading(true);
    try {
      // Build query parameters
      const params = new URLSearchParams({
        page: currentPage,
        per_page: perPage
      });

      // Add filters if they are provided
      if (filters.call_id) params.append('call_id', filters.call_id);

      // Format dates properly for API with time component
      if (filters.date_from) {
        const fromDate = new Date(filters.date_from);
        params.append('date_from', `${formatDate(fromDate).split('T')[0]}T00:00:00`);
      }

      if (filters.date_to) {
        const toDate = new Date(filters.date_to);
        params.append('date_to', `${formatDate(toDate).split('T')[0]}T23:59:59`);
      }

      // Make API request
      const response = await axios.get(`/dummy_data.json`);
      //   await axios.get(`${API_BASE_URL}/data?${params.toString()}`);

      if (response.data.success) {
        // Transform the API data to match the UI format expected
        const transformedData = response.data.data.map(call => ({
          id: call.call_id,
          dateTime: formatDateForDisplay(call.created_at, true),
          duration: call.metadata?.duration ? `${Math.floor(call.metadata.duration / 60)}:${String(call.metadata.duration % 60).padStart(2, '0')}` : 'N/A',
          agent: call.metadata?.caller?.split(' ')[0] || 'Unknown',
          from: call.metadata?.recipient || call.metadata?.caller || 'N/A',
          scores: {
            // Generate random scores for UI matching - in real app these would come from your analysis
            motivation: Math.ceil(Math.random() * 4),
            financial: Math.ceil(Math.random() * 4),
            timeline: Math.ceil(Math.random() * 4),
            script: Math.ceil(Math.random() * 4),
            confidence: Math.ceil(Math.random() * 4),
            listening: Math.ceil(Math.random() * 4),
            duration: Math.ceil(Math.random() * 4),
            talkTime: Math.ceil(Math.random() * 4),
            nextSteps: Math.ceil(Math.random() * 4),
            readiness: Math.ceil(Math.random() * 4)
          },
          total: Math.ceil(Math.random() * 30) + 10, // Random total for demo
          level: ['low', 'cold', 'warm', 'high'][Math.floor(Math.random() * 4)], // Random level for demo
          analysis: call.analysis,
          metadata: call.metadata,
          transcript: call.transcript,
          created_at: call.created_at
        }));



        setCallData(transformedData);
        setTotalPages(response.data.total_pages);
      } else {
        setError('Failed to fetch data: ' + (response.data.error || 'Unknown error'));
      }
    } catch (err) {
      setError('Error fetching data: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch a single call's details
  const fetchCallDetails = async (callId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/data/${callId}`);
      if (response.data.success) {
        setSelectedCall(response.data.data);
      } else {
        setError('Failed to fetch call details: ' + (response.data.error || 'Unknown error'));
      }
    } catch (err) {
      setError('Error fetching call details: ' + err.message);
    }
  };

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  // Apply filters and reset to first page
  const applyFilters = () => {
    setCurrentPage(1);
    fetchData();
  };

  // Reset filters
  const resetFilters = () => {
    setFilters({
      call_id: '',
      date_from: formatDate(tenDaysAgo).split('T')[0],
      date_to: formatDate(today).split('T')[0]
    });
    setCurrentPage(1);
    fetchData();
  };

  // Handle pagination
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Format date for display
  const formatDateForDisplay = (dateString, forTable = false) => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);

    if (forTable) {
      // Format like "01 Feb 2024 07:30 AM"
      const day = String(date.getDate()).padStart(2, '0');
      const month = date.toLocaleString('en-US', { month: 'short' });
      const year = date.getFullYear();
      const hours = date.getHours() % 12 || 12;
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

      return `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
    }

    return date.toLocaleString();
  };

  // Get sentiment color based on sentiment score
  const getSentimentColor = (analysis) => {
    if (!analysis || !analysis.sentiment || !analysis.sentiment.score) return '#888';

    const score = analysis.sentiment.score;
    if (score > 0.5) return '#4CAF50'; // Positive - green
    if (score > 0) return '#8BC34A';   // Slightly positive - light green
    if (score === 0) return '#9E9E9E';  // Neutral - gray
    if (score > -0.5) return '#FFC107'; // Slightly negative - amber
    return '#F44336';  // Negative - red
  };

  // Helper function to render score cell with appropriate color
  const renderScoreCell = (score) => {
    let bgClass = '';

    if (score >= 4) {
      bgClass = 'MediumSeaGreen';
    } else if (score === 3) {
      bgClass = 'bg-warning';
    } else {
      bgClass = 'Tomato';
    }

    return (
      <td>
        <div className={`${bgClass} text-white px-2 rounded mx-auto text-center`} style={{ width: '45px', backgroundColor: bgClass }}>
          {score}
        </div>
      </td>
    );
  };

  // Helper function to render level badge
  const renderLevelBadge = (level) => {
    let badgeClass = '';

    switch (level) {
      case 'high':
        badgeClass = 'bg-danger';
        break;
      case 'warm':
        badgeClass = 'bg-warning';
        break;
      case 'cold':
        badgeClass = 'bg-primary';
        break;
      case 'low':
        badgeClass = 'bg-secondary';
        break;
      default:
        badgeClass = 'bg-secondary';
    }

    return (
      <td className="p-2 text-center">
        <div className={`px-2  rounded text-white ${badgeClass}`} style={{ paddingTop: '1px', paddingBottom: '1px' }}>
          {level}
        </div>
      </td>
    );
  };

  return (
    <div className="d-flex for-callanalysis" style={{paddingLeft:"1.5rem",paddingRight:"1.5rem"}}>

      {/* Main Content */}
      <div className="flex-grow-1 d-flex flex-column overflow-hidden">

        {/* Filters */}
        <div className=" py-4  d-flex justify-content-between align-items-center" style={{ backgroundColor: "#F9FAFB", fontSize: "0.94rem" }}>
          <div className="d-flex align-items-center gap-4" >
            <div className="d-flex align-items-center border rounded p-1" >
              <Search size={16} className="text-muted me-2" />
              <input
                type="text"
                placeholder="Search by Number / From / Call ID"
                className="border-0"
                style={{ outline: 'none' }}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setFilters(prev => ({ ...prev, call_id: e.target.value }));
                }}
              />
            </div>

            <div className="d-flex align-items-center border rounded p-2">
              <select
                className="border-0"
                style={{ outline: 'none' }}
                value={selectedAgent}
                onChange={(e) => setSelectedAgent(e.target.value)}
              >
                <option value="All Agents">All Agents</option>
                <option value="John">John</option>
                <option value="Sarah">Sarah</option>
                <option value="Mike">Mike</option>
                <option value="Chris">Chris</option>
                <option value="Emma">Emma</option>
              </select>
            </div>
          </div>

          <div className="d-flex align-items-center gap-4">
            <div className="d-flex align-items-center">
              <span className="me-2">From:</span>
              <div className="d-flex align-items-center border rounded">
                <input
                  type="date"
                  className="p-2 border-0"
                  style={{ outline: 'none' }}
                  value={filters.date_from}
                  onChange={(e) => setFilters(prev => ({ ...prev, date_from: e.target.value }))}
                />
              </div>
            </div>

            <div className="d-flex align-items-center">
              <span className="me-2">To:</span>
              <div className="d-flex align-items-center border rounded">
                <input
                  type="date"
                  className="p-2 border-0"
                  style={{ outline: 'none' }}
                  value={filters.date_to}
                  onChange={(e) => setFilters(prev => ({ ...prev, date_to: e.target.value }))}
                />
              </div>
            </div>

            <button
              className="btn btn-primary px-3 py-1"
              style={{ fontSize: "0.94rem" }}
              onClick={applyFilters}
            >
              Apply
            </button>
          </div>
        </div>

        {/* Error display */}
        {error && <div className="alert alert-danger m-4">{error}</div>}

        {/* Table */}
        <div className="overflow-auto border rounded-top" style={{ height: '72vh' }}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="fs-5 text-muted">Loading data...</div>
            </div>
          ) : (
            <table style={{ "borderTopWidth": "1px", "borderColor": "#E5E7EB", "minWidth": "100%" }}>
              <thead style={{ backgroundColor: "#F9FAFB", position: "sticky", top: 0, zIndex: 10 }}>

                <tr >
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Date/Time</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Duration</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Agent</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>From</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Motivation</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Financial</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Timeline</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Script</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Confidence</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Listening</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Duration</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Talk Time</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Next Steps</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Readiness</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Total</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Level</th>
                  <th style={{ "paddingTop": "0.75rem", "paddingBottom": "0.75rem", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "fontSize": "0.75rem", "lineHeight": "1rem", "fontWeight": 500, "letterSpacing": "0.05em", "textAlign": "left", "color": "#6B7280", "textTransform": "uppercase" }}>Summary</th>
                </tr>
              </thead>
              <tbody style={{ "borderTopWidth": "1px", "borderColor": "#E5E7EB", "backgroundColor": "#ffffff" }}>
                {callData.map((call) => (
                  <tr key={call.id} className='tablebody' style={{
                    "border-bottom": "1px solid #e5e7eb"
                  }}>
                    <td style={{
                      paddingLeft: '0.75rem',
                      paddingRight: '0.75rem',
                      paddingTop: '0.75rem',
                      paddingBottom: '0.75rem',
                      whiteSpace: "nowrap",
                      fontSize: '0.875rem',
                      color: '#1f2937',

                    }}>
                      <div>{call.dateTime.split(' ').slice(0, 3).join(' ')}</div>
                      <div className="small text-muted">{call.dateTime.split(' ').slice(3).join(' ')}</div>
                    </td>
                    <td style={{
                      paddingLeft: '0.75rem',
                      paddingRight: '0.75rem',
                      paddingTop: '0.75rem',
                      paddingBottom: '0.75rem',
                      whiteSpace: 'normal',
                      fontSize: '0.875rem',
                      color: '#1f2937'
                    }}>{call.duration}</td>
                    <td style={{
                      paddingLeft: '0.75rem',
                      paddingRight: '0.75rem',
                      paddingTop: '0.75rem',
                      paddingBottom: '0.75rem',
                      whiteSpace: 'normal',
                      fontSize: '0.875rem',
                      color: '#1f2937'
                    }}>{call.agent}</td>
                    <td style={{
                      paddingLeft: '0.75rem',
                      paddingRight: '0.75rem',
                      paddingTop: '0.75rem',
                      paddingBottom: '0.75rem',
                      whiteSpace: 'normal',
                      fontSize: '0.875rem',
                      color: '#1f2937'
                    }}>{call.from}</td>
                    {renderScoreCell(call.scores.motivation)}
                    {renderScoreCell(call.scores.financial)}
                    {renderScoreCell(call.scores.timeline)}
                    {renderScoreCell(call.scores.script)}
                    {renderScoreCell(call.scores.confidence)}
                    {renderScoreCell(call.scores.listening)}
                    {renderScoreCell(call.scores.duration)}
                    {renderScoreCell(call.scores.talkTime)}
                    {renderScoreCell(call.scores.nextSteps)}
                    {renderScoreCell(call.scores.readiness)}
                    <td style={{
                      paddingLeft: '0.75rem',
                      paddingRight: '0.75rem',
                      paddingTop: '0.75rem',
                      paddingBottom: '0.75rem',
                      whiteSpace: 'normal',
                      fontSize: '0.875rem',
                      color: '#1f2937'
                    }}>{call.total}</td>
                    {renderLevelBadge(call.level)}
                    <td style={{
                      paddingLeft: '0.75rem',
                      paddingRight: '0.75rem',
                      paddingTop: '0.75rem',
                      paddingBottom: '0.75rem',
                      whiteSpace: 'normal',
                      fontSize: '0.875rem',
                      color: '#1f2937'
                    }}>
                      <div
                        className="btn btn-primary btn-sm"
                        onClick={() => fetchCallDetails(call.id)}
                        style={{ paddingTop: "2px", paddingBottom: "2px" }}
                      >
                        Summary
                      </div>
                    </td>
                  </tr>
                ))}
                {
                  callData.length === 0 && (
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <div className="fs-5 text-muted">No call records found.</div>
                    </div>
                  )
                }
              </tbody>
            </table>
          )}
        </div>

        {/* Pagination */}
        <div className="border-top w-100 p-1 bg-white border-bottom border-end border-start rounded-bottom mb-2">
          <PaginationControl
            currentPage={currentPage}
            totalPages={totalPages}
            tempPage={currentPage}
            setCurrentPage={setCurrentPage}
            updateTempPage={setCurrentPage}
            itemsPerPage={perPage}
            setItemsPerPage={setPerPage}
          />
        </div>

      </div>

      {/* Call details modal */}
      {selectedCall && (
        <div className="position-fixed top-0 start-0 w-100 h-100 bg-dark bg-opacity-50 d-flex align-items-center justify-content-center p-4" style={{ zIndex: 1050 }}>
          <div className="bg-white rounded shadow-lg w-100" style={{ maxWidth: '66rem', maxHeight: '100vh', overflow: 'auto' }}>
            <div className="p-4 border-bottom d-flex justify-content-between align-items-center">
              <h2 className="fs-4 fw-semibold mb-0">Call Details</h2>
              <button
                className="btn-close"
                onClick={() => setSelectedCall(null)}
              ></button>
            </div>
            <div className="p-4">
              <div className="row g-4 mb-4">
                <div className="col-md-6">
                  <div className="border rounded p-4">
                    <h3 className="fs-5 fw-semibold mb-3">Call Information</h3>
                    <table className="table table-borderless">
                      <tbody>
                        <tr className="border-bottom">
                          <td className="py-2 fw-medium">Call ID:</td>
                          <td className="py-2">{selectedCall.call_id}</td>
                        </tr>
                        <tr className="border-bottom">
                          <td className="py-2 fw-medium">Caller:</td>
                          <td className="py-2">{selectedCall.metadata?.caller || 'N/A'}</td>
                        </tr>
                        <tr className="border-bottom">
                          <td className="py-2 fw-medium">Recipient:</td>
                          <td className="py-2">{selectedCall.metadata?.recipient || 'N/A'}</td>
                        </tr>
                        <tr className="border-bottom">
                          <td className="py-2 fw-medium">Duration:</td>
                          <td className="py-2">{selectedCall.metadata?.duration ? `${Math.round(selectedCall.metadata.duration / 60)} mins` : 'N/A'}</td>
                        </tr>
                        <tr className="border-bottom">
                          <td className="py-2 fw-medium">Date:</td>
                          <td className="py-2">{formatDateForDisplay(selectedCall.created_at)}</td>
                        </tr>
                        <tr className="border-bottom">
                          <td className="py-2 fw-medium">Status:</td>
                          <td className="py-2">{selectedCall.metadata?.activity_status || 'N/A'}</td>
                        </tr>
                        <tr>
                          <td className="py-2 fw-medium">Campaign:</td>
                          <td className="py-2">{selectedCall.metadata?.campaign_name || 'N/A'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="border rounded p-4">
                    <h3 className="fs-5 fw-semibold mb-3">Analysis Summary</h3>
                    {selectedCall.analysis && selectedCall.analysis.sentiment ? (
                      <div>
                        <div className="mb-4">
                          <h4 className="fw-medium">Sentiment:</h4>
                          <div
                            className="fw-semibold"
                            style={{ color: getSentimentColor(selectedCall.analysis) }}
                          >
                            {selectedCall.analysis.sentiment?.overall || 'N/A'}
                            {selectedCall.analysis.sentiment?.score !== undefined &&
                              ` (${selectedCall.analysis.sentiment.score.toFixed(2)})`
                            }
                          </div>
                        </div>

                        <div className="mb-4">
                          <h4 className="fw-medium">Topics:</h4>
                          {selectedCall.analysis.topics && selectedCall.analysis.topics.length > 0 ? (
                            <ul className="ps-4">
                              {selectedCall.analysis.topics.slice(0, 3).map((topic, index) => (
                                <li key={index}>{topic}</li>
                              ))}
                              {selectedCall.analysis.topics.length > 3 && (
                                <li>+ {selectedCall.analysis.topics.length - 3} more...</li>
                              )}
                            </ul>
                          ) : (
                            <p className="text-muted">No topics identified</p>
                          )}
                        </div>

                        <div>
                          <h4 className="fw-medium">Key Issues:</h4>
                          {selectedCall.analysis.issues && selectedCall.analysis.issues.length > 0 ? (
                            <ul className="ps-4">
                              {selectedCall.analysis.issues.slice(0, 2).map((issue, index) => (
                                <li key={index}>
                                  {issue.description}
                                  <span className="ms-1 small text-danger">({issue.severity})</span>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p className="text-muted">No issues identified</p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <p className="text-muted">No analysis available</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="border rounded p-4 mb-4">
                <h3 className="fs-5 fw-semibold mb-3">Transcript</h3>
                <div className="bg-light p-3 rounded" style={{ maxHeight: '16rem', overflowY: 'auto' }}>
                  {selectedCall.transcript ? (
                    <p className="white-space-pre-line">{selectedCall.transcript}</p>
                  ) : (
                    <p className="text-muted">No transcript available</p>
                  )}
                </div>
              </div>

              {selectedCall.analysis && (
                <div className="border rounded p-4">
                  <h3 className="fs-5 fw-semibold mb-4">Detailed Analysis</h3>

                  <div className="row g-4">
                    <div className="col-md-6">
                      <h4 className="fw-medium mb-2">Action Items</h4>
                      {selectedCall.analysis.action_items && selectedCall.analysis.action_items.length > 0 ? (
                        <ul className="ps-4">
                          {selectedCall.analysis.action_items.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-muted">No action items identified</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <h4 className="fw-medium mb-2">Call Quality</h4>
                      {selectedCall.analysis.call_quality ? (
                        <div>
                          <div className="mb-2">
                            <span className="fw-semibold">Rating: </span>
                            <span>{selectedCall.analysis.call_quality.rating}/5</span>
                          </div>

                          <div className="mb-2">
                            <h5 className="fw-medium">Strengths:</h5>
                            {selectedCall.analysis.call_quality.strengths &&
                              selectedCall.analysis.call_quality.strengths.length > 0 ? (
                              <ul className="ps-4">
                                {selectedCall.analysis.call_quality.strengths.map((strength, index) => (
                                  <li key={index}>{strength}</li>
                                ))}
                              </ul>
                            ) : (
                              <p className="text-muted">No areas for improvement identified</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <p className="text-muted">No call quality assessment available</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="p-4 border-top d-flex justify-content-end">
              <button
                className="btn btn-primary"
                onClick={() => setSelectedCall(null)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Process data modal - Add this if you need the processing functionality */}
      <div className="d-none">
        <div className="process-container">
          <h2>Process Call Data</h2>
          <div className="process-form">
            <div className="date-group">
              <label htmlFor="process_date_from">From Date:</label>
              <input
                type="date"
                id="process_date_from"
                name="process_date_from"
                value={filters.date_from}
                onChange={handleFilterChange}
              />
            </div>

            <div className="date-group">
              <label htmlFor="process_date_to">To Date:</label>
              <input
                type="date"
                id="process_date_to"
                name="process_date_to"
                value={filters.date_to}
                onChange={handleFilterChange}
              />
            </div>

            <button
              className="process-button"
              onClick={async () => {
                try {
                  const response = await axios.post(`${API_BASE_URL}/process`, {
                    fromDate: `${filters.date_from}T00:00:00Z`,
                    toDate: `${filters.date_to}T23:59:59Z`
                  });

                  if (response.data.success) {
                    alert('Processing started successfully!');
                  } else {
                    setError('Failed to start processing: ' + (response.data.error || 'Unknown error'));
                  }
                } catch (err) {
                  setError('Error starting processing: ' + err.message);
                }
              }}
            >
              Process Data
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallAnalysisDashboard;