import React from 'react';
import { Star, Clock, AlertTriangle, Info } from 'lucide-react';
import "./KeyMetrics.css"
const Tooltip = ({ content }) => (
<div style={{ position: "relative", display: "inline-block" }}>
    <div className="tooltip-wrapper">
      <Info
        style={{
          width: "1rem",
          height: "1rem",
          color: "#9CA3AF",
          cursor: "help",
          marginBottom: 4,
        }}
        className="info-icon"
      />
      <div className="tooltip-content">
        {content}
        <div className="tooltip-arrow"></div>
      </div>
    </div>
  </div>
);

export function KeyMetrics({ metrics }) {
  const highRatings = 45;
  const moderateRatings = 35;
  const lowRatings = 20;

  console.log(metrics)
  return (
    <div className="mainkeymetricdiv" style={{fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
      <div className="mainkeymetricdiv2">
        <div className="mainkeymetricdiv3">
            <div>
              <div className="mainkeymetricdiv4">
              <span style={{"fontSize":"0.875rem","lineHeight":"1.25rem","color":"#4B5563"}}>Average Score</span>
              <Tooltip content="Average rating of sales team" />
              </div>
              <span style={{"marginTop":"0.25rem","fontSize":"1.5rem","lineHeight":"2rem","fontWeight":600,"color":"#1f9ad6"}}>{metrics.sessions?.average_score || 0}/5</span>
              {/* <span style={{"marginTop":"0.25rem","fontSize":"1.5rem","lineHeight":"2rem","fontWeight":600,"color":"#1f9ad6"}}>4.2/5</span> */}

            </div>
            <Star className="starticon"  />
          </div>
        </div>

        <div className="mainkeymetricdiv2">
        <div className="mainkeymetricdiv3">
            <div style={{"width":"100%"}}> 
              <div className="mainkeymetricdiv4">
              <span style={{"fontSize":"0.875rem","lineHeight":"1.25rem","color":"#4B5563"}}>Session Ratings Distribution</span>
              <Tooltip content={`Distribution of sessions:\n4–5: High ratings\n3–4: Moderate ratings\nUnder 3: Low ratings`} />
            </div>
            <div style={{"display":"flex","marginTop":"0.5rem","justifyContent":"space-between","alignItems":"center"}}>
              <div style={{"display":"flex","flexDirection":"column","alignItems":"center"}}>
                <span style={{"fontSize":"0.875rem","lineHeight":"1.25rem","color":"#6B7280"}}>High</span>
                <span style={{"fontSize":"1.125rem","lineHeight":"1.75rem","fontWeight":600,"color":"#059669"}}>{metrics.sessions?.score_rating_destribution?.high || 0}</span>
                {/* <span style={{"fontSize":"1.125rem","lineHeight":"1.75rem","fontWeight":600,"color":"#059669"}}>45</span> */}

              </div>
              <div style={{"display":"flex","flexDirection":"column","alignItems":"center"}}>
                <span style={{"fontSize":"0.875rem","lineHeight":"1.25rem","color":"#6B7280"}}>Moderate</span>
                <span style={{"fontSize":"1.125rem","lineHeight":"1.75rem","fontWeight":600,"color":"#D97706"}}>{metrics.sessions?.score_rating_destribution?.medium || 0}</span>
                {/* <span style={{"fontSize":"1.125rem","lineHeight":"1.75rem","fontWeight":600,"color":"#D97706"}}>35</span> */}

              </div>
              <div  style={{"display":"flex","flexDirection":"column","alignItems":"center"}}>
                <span style={{"fontSize":"0.875rem","lineHeight":"1.25rem","color":"#6B7280"}}>Low</span>
                <span style={{"fontSize":"1.125rem","lineHeight":"1.75rem","fontWeight":600,"color":"#DC2626"}}>{metrics.sessions?.score_rating_destribution?.low || 0}</span>
                {/* <span style={{"fontSize":"1.125rem","lineHeight":"1.75rem","fontWeight":600,"color":"#DC2626"}}>20</span> */}

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mainkeymetricdiv2">
        <div className="mainkeymetricdiv3">
            <div>
              <div className="mainkeymetricdiv4">
                <span style={{"fontSize":"0.875rem","lineHeight":"1.25rem","color":"#4B5563"}}>Average Duration</span>
                <Tooltip content="Average duration of sessions" />
              </div>
              <span style={{"marginTop":"0.25rem","fontSize":"1.5rem","lineHeight":"2rem","fontWeight":600,"color":"#1f9ad6"}}>{metrics.sessions?.average_duration || 0} mins</span>
            </div>
            <Clock style={{"width":"2rem","height":"2rem","color":"#1f9ad6"}} />
          </div>
        </div>

        <div className="mainkeymetricdiv2">
        <div className="mainkeymetricdiv3">
            <div>
              <div className="mainkeymetricdiv4">
                <span style={{"fontSize":"0.875rem","lineHeight":"1.25rem","color":"#4B5563"}}>Worst Criteria Overall</span>
                <Tooltip content="Lowest performing criteria across all sessions" />
              </div>
              <div className="mt-1">
                <span style={{"fontSize":"0.875rem","lineHeight":"1.25rem","color":"#374151"}}>{metrics.sessions?.worst_criteria.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
    }</span>
                <div style={{"display":"flex","alignItems":"center"}}>
                  <Star style={{"marginRight":"0.25rem","width":"1rem","height":"1rem","color":"#ffd700"}} />
                  <span style={{"fontSize":"1.25rem","lineHeight":"1.75rem","fontWeight":600,"color":"#DC2626"}}>{metrics.sessions?.worst_criteria_rating || 0}</span>
                </div>
              </div>
            </div>
            <AlertTriangle style={{"width":"2rem","height":"2rem","color":"#EF4444"}} />
          </div>
        </div>
      </div>
  );
}
