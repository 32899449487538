// DateRangeFilter.js
import { Calendar } from 'lucide-react';
import React from 'react';

export function DateRangeFilter({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) {
  return (
    <div className="card shadow-sm mb-3">
      <div className="card-body" >
        <div className="d-flex align-items-center mb-3">
          <Calendar className="me-2 " size={20} style={{"color":"#1f9ad6"}}/>

          <label htmlFor="startDate" style={{ fontWeight:500 }}>Date Range</label>
        </div>
        <div className="row g-2">
          <div className="col-6">
            <div className="input-group">
              <input
                type="date"
                id="startDate"
                className="form-control"
                value={startDate}
                onChange={(e) => onStartDateChange(e.target.value)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  
                  width: '100%',
                  padding: '0.375rem 0.75rem',
                  fontSize: '0.875rem',
                  borderRadius: '0.375rem'
                }}
              />
            </div>
          </div>
          <div className="col-6 mb-1">
            <div className="input-group">
              <input
                type="date"
                id="endDate"
                className="form-control"
                value={endDate}
                onChange={(e) => onEndDateChange(e.target.value)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  
                  width: '100%',
                  padding: '0.375rem 0.75rem',
                  fontSize: '0.875rem',
                  borderRadius: '0.375rem'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
