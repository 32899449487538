const LeadFilter = ({setFilterOption,filterOption})=>{
    return(
 
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom:"16px"
          }}
        >
          <p className="filter-string">Filter Type:&nbsp;</p>
          <select
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
            className="form-control"
            style={{ width: 180, fontSize: 12 }}
          >
            <option value="All">All Appointments</option>
            <option value="Upcoming">Upcoming Appointments</option>
            <option value="Past Appointments">Past Appointments</option>
          </select>
        </div>
      
    )
}

export default LeadFilter