import { useLocation, useParams } from "react-router-dom";
import Profile from "../../assets/profile.svg";
import styles from "./TopNav.module.css"; // Import CSS module
import { useContext, useEffect } from "react";
import { ZoomSessionContext } from "../../hooks/zoomSessionContext";
import {
    ArrowLeft,
    LinkIcon,
    Pin,
    Video
  
  } from "lucide-react";
import FeedbackButton from "../FeedbackButton";
const TopNav = ({ toggleMenu }) => {
    const location = useLocation();
        const { urlSessionId } = useParams();
 const {sessionContext, setSessionContext,
    onBackFunction } = useContext(ZoomSessionContext);    
    const isLegalaiChat = location.pathname === "/dashboard/chat" || `/dashboard/chat/${urlSessionId}`;
    let pathname = window.location.pathname
    useEffect(() => {
      console.log("this is the orutes",pathname)
  
      
      if (pathname != "/dashboard/zoom-analysis") {
        onBackFunction()
        setSessionContext("");  
      }
    }, [pathname]);
    return (
        <div className={`row ${styles.customTopNav}`} style={{ zIndex: 20, paddingLeft : isLegalaiChat? "12px":undefined}}>
            <div className={`col-md-12 ${styles.topNavWithName}`}>
                <div className={`${styles.pageHeading}`}>
                    {location.pathname === "/dashboard/" ||
                        location.pathname === "/dashboard"
                        ? "Analytics"
                        : location.pathname === "/dashboard/users"
                            ? "Users"
                            : location.pathname === "/dashboard/inbox"
                                ? "Inbox"
                                : location.pathname === "/dashboard/leads"
                                    ? "Leads"
                                    : location.pathname === "/dashboard/zoom-analysis"
                                        ? sessionContext == "" ? "Zoom Analysis" :  <div style={{"display":"flex","justifyContent":"space-between","alignItems":"center","borderBottomWidth":"1px","height":"4rem","backgroundColor":"#ffffff","borderColor":"#0000001f","flexGrow":1,"paddingRight":"20px"
                                        }}>
           
         <div>
             
           <button
                    onClick={onBackFunction}
                    className="btn btn-link text-decoration-none p-0  d-flex align-items-center"
                  style={{fontSize:"14px"}}
                  >
                    <ArrowLeft className="me-1" size={14} />
                    Back to Sessions
                  </button>
                                        <h1 style={{"fontSize":"1.25rem","lineHeight":"1.75rem","fontWeight":600,"color":"#1F2937"}}>{sessionContext?.title}</h1>
                                       </div>
                                        {sessionContext?.videoLink && (
                                          <div style={{"display":"flex","gap":"0.75rem","fontSize":"16px"}}>
                                             <a
                                                                  href={sessionContext?.videoLink}
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  className="btn btn-primary d-flex align-items-center"
                                            
                                                                >
                                                                  <Video size={16} className="me-2" />
                                            
                                                                  Watch Recording
                                            
                                                                  <LinkIcon size={16} />
                                                                </a>
                                                                <FeedbackButton
                    strategistEmail={(sessionContext?.representative.email)}
                    sessionTitle={sessionContext?.title}
                  />
                                          </div>
                                        )}
                                                                                  </div>

                                         : location.pathname === "/dashboard/sessionsummary"
                                        ? "Summary Analysis"
                                         : location.pathname === "/dashboard/chat"
                                        ? "Chat"
                                         : location.pathname === `/dashboard/chat/${urlSessionId}`
                                        ? "Chat"
                                         : location.pathname === "/dashboard/documenthistory"
                                        ? "Document History"
                                         : location.pathname === "/dashboard/legalanalytics"
                                        ? "Legal Analytics"
                                        : location.pathname === "/dashboard/settings"
                                            ? "Settings"
                                            : location.pathname === "/dashboard/call-analysis"
                                            ? "Call Analysis"
                                            : ""}
                </div>
                {/* <img
                    src={Profile}
                    alt="profile"
                    className={styles.profileIconNav}
                /> */}
                <button className={styles.collapsedNav} onClick={toggleMenu}>
                    <div
                        className={styles.line}
                    ></div>
                    <div
                        className={styles.line}
                    ></div>
                    <div
                        className={styles.line}
                    ></div>
                </button>
            </div>
        </div>
    )
}

export default TopNav