// components/KpiCard.js
import React, { useEffect, useState } from "react";

const KpiCard = ({ title, count, subtitle }) => {
    const [colClass, setColClass] = useState("col-md-4");
          
            useEffect(() => {
              const handleResize = () => {
                if (window.innerWidth < 996) {
                  setColClass("col-md-12 mt-1");
                } else {
                  setColClass("col-md-3");
                }
              };
          
              window.addEventListener("resize", handleResize);
              handleResize(); // Trigger initially
          
              return () => {
                window.removeEventListener("resize", handleResize);
              };
            }, []);
    return (
        <div className={`${colClass}`} style={{ paddingTop: 5 }}>
            <div style={{ border: "1px solid rgba(0,0,0,0.1)", backgroundColor: "white", boxShadow: "0 3px 6px rgba(0, 0, 0, 0.05)", borderRadius: 5 }}>
                <p style={{ color: "black", margin: 0, padding: 12, fontSize: 12, textAlign: "center" }}>
                    {title}
                </p>
                <p style={{ fontSize: 34, color: "black", margin: 12, textAlign: "center" }}>
                    {count ?? 0}
                </p>
                <p style={{ textAlign: "center", fontSize: 12, color: "grey" }}>
                    {subtitle}
                </p>
            </div>
        </div>
    );
};

export default KpiCard;
