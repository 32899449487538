import Two from "../../assets/2.svg";
import Six from "../../assets/6.svg";
import Recycle from "../../assets/recycle.svg";
import Settings from "../../assets/settings.svg";
import Lead from "../../assets/lead.svg";
import { Link } from "react-router-dom";
import Zoom from "../../assets/zoom.svg";
import Seven from "../../assets/7.svg";
import aisms from "../../assets/aisms.png";

import styles from "./SidebarMobile.module.css";
import "../ui-components/icnImage.css"
import SessionList from "../../assets/sessionlist.svg"
import SummaryAnalysis from "../../assets/summaryanalysis.svg"
import { useState } from "react";
import { BarChart, History, MessageCircle, Scale } from "lucide-react";

const SidebarMobile = ({ setIsMenuOpen }) => {
  const [showAISMSSubmenu, setShowAISMSSubmenu] = useState(false);
     const [showZoomSubmenu,setShowZoomSubmenu] = useState(false)
     const [showLegalAI,setShowLegalAI] = useState(false)
     const toggleAISMSSubmenu = () => {
         setShowAISMSSubmenu(!showAISMSSubmenu);
     };
     const toggleZoomSubmenu = ()=>{
         setShowZoomSubmenu(!showZoomSubmenu)
     }
     const toggleLegalAI = ()=>{
         setShowLegalAI(!showLegalAI)
     }
    return (
        <div className={styles.mobileMenuOverlay}>
            <div className={styles.mobileMenu}>
                <button className={styles.closeButton} onClick={() => setIsMenuOpen(false)}>
                    &times;
                </button>
                <div className={styles.mobileMenuLinks} >
                    <div className={styles.navMobileList}>
                      <span onClick={toggleAISMSSubmenu} className="menu-item aisms"  style={{fontSize:14}}>
                                    <img src={aisms} alt="AiSms" className="icn" />
                    
                                        AI SMS
                                        <span className={`arrow ${showAISMSSubmenu ? 'up' : 'down'}`}>  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 2 L8 6 L3 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </span>
                    
                                    </span>
                      {showAISMSSubmenu && (
                                         <div className="submenu ms-5">
                                             <span>
                                                 <Link to="./"  style={{fontSize:14}}>
                                                     <img src={Two} alt="Analysis" className="icn" />
                                                     Analysis
                                                 </Link>
                                             </span>
                                             <span>
                                                 <Link to="./users"  style={{fontSize:14}}>
                                                     <img src={Six} alt="Contacts" className="icn" />
                                                     Contacts
                                                 </Link>
                                             </span>
                                             <span>
                                                 <Link to="./inbox"  style={{fontSize:14}}>
                                                     <img src={Recycle} alt="Inbox" className="icn" />
                                                     Inbox
                                                 </Link>
                                             </span>
                                             <span>
                                                 <Link to="./leads"  style={{fontSize:14}}>
                                                     <img src={Lead} alt="Leads" className="icn" />
                                                     Leads
                                                 </Link>
                                             </span>
                                         </div>
                                     )}
                          <span onClick={toggleZoomSubmenu} className="menu-item aisms"  style={{fontSize:14}}>
                <img src={Zoom} alt="Zoom Analysis" className="icn" />

                Zoom Analysis
                    <span className={`arrow ${showZoomSubmenu ? 'up' : 'down'}`}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3 2 L8 6 L3 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg></span>

                </span>
                {/* AI SMS Submenu */}
                {showZoomSubmenu && (
                    <div className="submenu ms-5 mt-2">
                {/* Other Menu Items */}
                <span>
                    <Link to="./zoom-analysis" style={{fontSize:14}}>
                        <img src={SessionList} alt="Session List" className="icn" />
                        Session Analysis
                    </Link>
                    <Link to="./sessionsummary"  style={{fontSize:14}}>
                        <img src={SummaryAnalysis} alt="Summary Analysis" className="icn" />
                        Summary Analysis
                    </Link>
                </span>
                </div>
                )}
                 <span onClick={toggleLegalAI} className="menu-item aisms"  style={{fontSize:14}}>
                <Scale className="icn" />

                Legal AI
                    <span className={`arrow ${showLegalAI ? 'up' : 'down'}`}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3 2 L8 6 L3 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg></span>

                </span>
                {/* AI SMS Submenu */}
                {showLegalAI && (
                    <div className="submenu ms-5">
                {/* Other Menu Items */}
                <span>
                    <Link to="./chat" style={{fontSize:14}}>
                    <MessageCircle className="icn" />
                        Chat
                    </Link>
                    <Link to="./documenthistory"  style={{fontSize:14}}>
                    <History className="icn" />
                                            Document History
                    </Link>
                    <Link to="./legalanalytics"  style={{fontSize:14}}>
                    <BarChart className="icn"/>                   
                         Legal Analytics
                    </Link>
                </span>
                </div>
                )}
                        <span className="menu-item aisms">
                                           <Link to="./settings"  style={{fontSize:14}}>
                                               <img src={Settings} alt="Settings" className="icn" />
                                               Settings
                                           </Link>
                                       </span>
                    </div>
                    <span
                        onClick={() => {
                            // Implement logout functionality here
                            window.location.href = "/";
                        }}
                        className="menu-item aisms"
                    >
                        Logout&nbsp;<img src={Seven} alt="Logout" className="icn-2" />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SidebarMobile