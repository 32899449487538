import { axiosInstance } from "../utills"

export const LoginUser = async(email,password) =>{
    try{
        const response = await axiosInstance.get(
            `https://zoom.kuru.ai/authenticate_user?email=${email}&password=${password}`
        );
        console.log("this is login",response.data)

        // Check if login is successful
       
            return response.data;
         }

    catch(error)
    {
        console.error('Error exporting data:', error);
        if (error.response) {
            console.error('Server responded with:', error.response.data);  
        }
        throw new Error('Failed to export data. Please try again later.');
    
    }
}