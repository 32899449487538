import { useEffect, useRef, useState } from "react";
import LegalChatBar from "../component/LegalChatBar"
import LegalUserChat from "../component/LegalUserChat";
import { Check, CheckCheck, CheckSquare, CrossIcon, Delete, DeleteIcon, Edit, FileText, History, MessageCircle, PlusCircle, Settings, Trash2, XSquare, XSquareIcon } from "lucide-react";
import { createLegalChatHistory, createLegalRule, deleteLegalChatHistory, deleteLegalRule, getLegalChatHistory, getLegalRules, getSepcificChatApi, updateLegalRule } from "../api/legalaiApi";
import exportLoadingGif from "../assets/exportloading.gif"; 
import { useAuth } from "../AuthContext";
import { useNavigate, useParams } from "react-router-dom";

const LegalaiChat = ()=>{
    const sidebarRef = useRef(null);
    const { urlSessionId } = useParams();
    const userid = localStorage.getItem("user_id");

    const [isResizing, setIsResizing] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [selectedThumbnail, setSelectedThumbnail] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [activeTab,setActiveTab] = useState('history')
    const [chatDocuments, setChatDocuments] = useState(
        [

        ]
    )
    const [chatHistory,setChatHistory] = useState([])
    const [currentChatId, setCurrentChatId] = useState(urlSessionId || '');
    const [newRuleName, setNewRuleName] = useState('');
    const [newRuleDescription, setNewRuleDescription] = useState('');
    const [chats,setChats] = useState([])
    const [sidebarWidth, setSidebarWidth] = useState(450);
    const [deleteFlag,setDeleteFlag] = useState(true)
    const [getHistoryLoading, setGetHistoryLoading] = useState(false);
    const [createHistoryLoading,setCreateHistoryLoading] = useState(false)
    const [getSpecificMessageLoading,setGetSpecificMessageLoading] = useState(false)
    const [deleteHistoryLoading,setDeleteHistoryLoading] = useState(false)
    const [flagGetHistory,setFlagGetHistory] = useState(false)
    const [markingDocument,setMarkingDocument] = useState('')
    const [saveInputMessage,setSaveInputMessage] = useState('')
    const [rules, setRules] = useState([ 
  ]);
  const [chatApiErrorCase,setChatApiErrorCase] = useState(false)
  const [updateTagName,setUpdateTagName] = useState('')
  const [updateTagDesc,setUpdateTagDesc] = useState('')
  const [showUpdateTag,setShowUpdateTag] = useState('')
  const [updateTagId,setUpdateTagId] = useState('')
  const [flagRule,setFlagRule]= useState(false)
  const { logout } = useAuth();
  const navigate = useNavigate();
  const toggleRule = (ruleId) => {
    setRules(prev => prev.map(rule => 
      rule.id === ruleId ? { ...rule, isActive: !rule.isActive } : rule
    ));
  };

  const handleAddRule = async() => {
    if (!newRuleName.trim() || !newRuleDescription.trim()) return;

    const newRule = {
      rule_id: Date.now().toString(),
      rule_name: newRuleName,
      rule_description: newRuleDescription,
      isActive: true
    };

    setRules(prev => [...prev, newRule]);
    setNewRuleName('');
    setNewRuleDescription('');
   const addRule =  await createLegalRule(userid,newRuleName,newRuleDescription)
   if(addRule)
   {
    setFlagRule(prev=>!prev)
   }
  };

  const handleDeleteRule = async(ruleId) => {
    setRules(prev => prev.filter(rule => rule.rule_id !== ruleId));
     await deleteLegalRule(ruleId)
  };

    useEffect(() => {
        const handleMouseMove = (e) => {
          if (!isResizing) return;
          
          e.preventDefault();
          document.body.style.userSelect = 'none';
          
          const newWidth = document.body.clientWidth - e.clientX;
          if (newWidth > 300 && newWidth < 800) {
            setSidebarWidth(newWidth);
          }
        };
    
        const handleMouseUp = () => {
          setIsResizing(false);
          document.body.style.userSelect = '';
        };
    
        if (isResizing) {
          document.addEventListener('mousemove', handleMouseMove);
          document.addEventListener('mouseup', handleMouseUp);
        }
    
        return () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
          document.body.style.userSelect = '';
        };
      }, [isResizing]);



      const createNewChat = async () => {
        setCreateHistoryLoading(true); 
      
        try {
          const creatingNewChat = await createLegalChatHistory(userid);
      
          setChats(prev => [creatingNewChat, ...prev]);
          setCurrentChatId(creatingNewChat.session_id);
          setMessages(creatingNewChat.chats);
          setSelectedThumbnail(null)
          setFlagGetHistory(prev => !prev)
          navigate(`/dashboard/chat/${creatingNewChat.session_id}`);

        } catch (error) {
          console.error("Error creating new chat:", error);
        } finally {
          setCreateHistoryLoading(false);
        }
      };
      const handleDocumentPreview = (file) => {
        // const url = URL.createObjectURL(file)
        setSelectedDocument(file);
        setMarkingDocument('')
        const url = file.url;
        setSelectedThumbnail(url);     
           console.log("hey this is the file",file.url)
      };
    useEffect(()=>{
      const getChatHistoryData = async () => {
        setGetHistoryLoading(true);
       
        try {
          const userid = localStorage.getItem("user_id");
         
          const getHistoryData = await getLegalChatHistory(userid);
          console.log("this is gethistory data",getHistoryData)
          getHistoryData.message ? setChatHistory([]) : setChatHistory(getHistoryData);
       if(getHistoryData.message)
       {
        createNewChat()
        return;
       }
          if(currentChatId=='' && !getHistoryData.message || !getHistoryData.some(chat => urlSessionId == chat.session_id))
          {
          setCurrentChatId(getHistoryData[0]?.session_id)
          getSpecificChatMessages(getHistoryData[0]?.session_id)
          }
          else{
            getSpecificChatMessages(urlSessionId)
          
      
          }

        } catch (error) {
          console.error("Error fetching chat history:", error);
        } finally {
          setGetHistoryLoading(false);
        }
      };
      
      getChatHistoryData();
      }, [flagGetHistory, deleteFlag]);
      

    const getSpecificChatMessages = async (session_id) => {
      setGetSpecificMessageLoading(true); 
    
      try {
        const getSpecificChat = await getSepcificChatApi(session_id);
        setCurrentChatId(session_id);
        setMessages(getSpecificChat.chats);
        setSelectedThumbnail(null)
        navigate(`/dashboard/chat/${session_id}`);

      } catch (error) {
        console.error("Error fetching chat messages:", error);
      } finally {
        setGetSpecificMessageLoading(false); 
      }
    };
    const deleteHistoryFunction = async (session_id) => {
      setDeleteHistoryLoading(true); 
    
      try {
        if (currentChatId === session_id) {
          setCurrentChatId('');
          setMessages([]);
        }
    
        await deleteLegalChatHistory(session_id);
        setDeleteFlag(prev => !prev);
      } catch (error) {
        console.error("Error deleting chat history:", error);
      } finally {
        setDeleteHistoryLoading(false); 
      }
    };

  
   
      console.log("url sessionid",urlSessionId)

    useEffect(()=>{
      if(!(localStorage.getItem('user_id')))
      {
        logout();
        navigate("/");
      }
    },[])
    // useEffect(()=>{
    //   if(!chatHistory.some(chat => urlSessionId == chat.session_id))
    //     {
    //       setCurrentChatId('')
    //     }
    // },[urlSessionId])
    useEffect(()=>{
    const gettingLegalRules = async()=>{
      const getRuleData = await getLegalRules(userid)
      setRules(getRuleData)
    }
    gettingLegalRules()
    },[flagRule])
    const handleUpdaterule = (rule)=>{
      setShowUpdateTag('')
      setShowUpdateTag(rule.rule_id)
      setUpdateTagName(rule.rule_name)
      setUpdateTagDesc(rule.rule_description)
      setUpdateTagId(rule.rule_id)

    }
    const handleFinalUpdaterule = async (rule) => {
      const newRule = {
        rule_id: rule.rule_id,
        rule_name: updateTagName,
        rule_description: updateTagDesc,
      };
    
      setRules((prev) => {
        const index = prev.findIndex((r) => r.rule_id === rule.rule_id);
        if (index === -1) return prev; 
    
        const updatedRules = [...prev];
        updatedRules[index] = newRule; 
    
        return updatedRules;
      });
      setShowUpdateTag('');

      await updateLegalRule(rule.rule_id, updateTagName, updateTagDesc, userid);
    };
    
return(
    <div style={{"display":"flex","height":"100%","fontFamily": 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'}}>
        <div style={{"display":"flex","flexDirection":"column","flex":"1 1 0%","height":"100%"}}>
        <LegalUserChat messages={messages} chatApiErrorCase={chatApiErrorCase} setMarkingDocument={setMarkingDocument} getSpecificMessageLoading={getSpecificMessageLoading} saveInputMessage={saveInputMessage}  setSelectedThumbnail={setSelectedThumbnail} setActiveTab={setActiveTab}/>     
        <LegalChatBar messages={messages} setChatApiErrorCase={setChatApiErrorCase} setActiveTab={setActiveTab} markingDocument={markingDocument} setMarkingDocument={setMarkingDocument} setSaveInputMessage = {setSaveInputMessage}
        currentChatId={currentChatId} setMessages={setMessages} setGetSpecificMessageLoading={setGetSpecificMessageLoading} inputMessage={inputMessage} setInputMessage={setInputMessage} files={files} setFiles={setFiles} selectedThumbnail={selectedThumbnail} setSelectedThumbnail={setSelectedThumbnail} selectedDocument={selectedDocument} setSelectedDocument={setSelectedDocument} chatDocuments={chatDocuments} setChatDocuments={setChatDocuments}/>
     
        </div>
        <div 
        ref={sidebarRef}
        style={{ width: `${sidebarWidth}px` ,"display":"flex","position":"fixed", top: 0,right:12,
        background: "white",
        maxWidth: "60%", 
         
        zIndex: 50,"flexDirection":"column","borderLeft":"1px solid rgba(0, 0, 0, 0.12)","backgroundColor":"#ffffff"}}
      >
        <div
style={{"position":"absolute","top":"0","bottom":"0","left":"0","width":"0.25rem","cursor":"col-resize",":hover":{"opacity":0.5,"background":"#1f9ad6"}}}
          onMouseDown={(e) => {
            e.preventDefault();
            setIsResizing(true);
          }}
        />
   
        <div style={{"display":"flex","borderBottomWidth":"1px","marginTop":"18px"}}>
          <button
            onClick={() => setActiveTab('documents')}
            style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","background":"none",
              "paddingLeft":"1rem","paddingRight":"1rem","flex":"1 1 0%",
              "borderRight":"none","borderLeft":"none","borderTop":"none",
              "borderBottomWidth":"2px","borderBottomStyle":"solid","fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,
              ...( activeTab === 'documents' ? {"borderColor":"#1f9ad6","color":"#1f9ad6"} : {"borderColor":"transparent","color":"#6B7280",":hover":{"borderColor":"#D1D5DB","color":"#374151"}})}}
      
          >
            <div style={{"display":"flex","gap":"0.5rem","justifyContent":"center","alignItems":"center"}}>
              <FileText style={{"width":"1rem","height":"1rem"}}/>
              Documents
            </div>
          </button>
          <button
            onClick={() => setActiveTab('rules')}
            style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","background":"none",
              "paddingLeft":"1rem","paddingRight":"1rem","flex":"1 1 0%","borderRight":"none","borderLeft":"none","borderTop":"none",
              "borderBottomWidth":"2px","borderBottomStyle":"solid","fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,
              ...( activeTab === 'rules' ? {"borderColor":"#1f9ad6","color":"#1f9ad6"} : {"borderColor":"transparent","color":"#6B7280",":hover":{"borderColor":"#D1D5DB","color":"#374151"}})}}
          >
            <div style={{"display":"flex","gap":"0.5rem","justifyContent":"center","alignItems":"center"}}>
              <Settings style={{"width":"1rem","height":"1rem"}} />
              Rules
            </div>
          </button>
          <button
            onClick={() => setActiveTab('history')}
            style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","background":"none",
              "paddingLeft":"1rem","paddingRight":"1rem","flex":"1 1 0%","borderRight":"none","borderLeft":"none","borderTop":"none",
              "borderBottomWidth":"2px","borderBottomStyle":"solid","fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,
              ...( activeTab === 'history' ? {"borderColor":"#1f9ad6","color":"#1f9ad6"} : {"borderColor":"transparent","color":"#6B7280",":hover":{"borderColor":"#D1D5DB","color":"#374151"}})}}
          >
            <div style={{"display":"flex","gap":"0.5rem","justifyContent":"center","alignItems":"center"}}>
              <History style={{"width":"1rem","height":"1rem"}} />
              History
            </div>
          </button>
        </div>
        </div>
        <div 
        ref={sidebarRef}
        style={{ width: `${sidebarWidth}px` ,"display":"flex","position":"relative","flexDirection":"column","borderLeft":"1px solid rgba(0, 0, 0, 0.12)","backgroundColor":"#ffffff"}}
      >
        <div
style={{"position":"absolute","top":"0","bottom":"0","left":"0","width":"0.25rem","cursor":"col-resize",":hover":{"opacity":0.5,"background":"#1f9ad6"}}}
          onMouseDown={(e) => {
            e.preventDefault();
            setIsResizing(true);
          }}
        />
   
        <div style={{"overflowY":"auto","flex":"1 1 0%"}}>
          {activeTab === 'history' && (
            <div style={{"padding":"1rem","overflowY":"auto","height":"80vh"}}>
              <div style={{"display":"flex","marginBottom":"1rem","justifyContent":"space-between","alignItems":"center"}}>
                <span style={{"fontWeight":500}}>Chat History</span>
              { createHistoryLoading ?         <img src={exportLoadingGif} alt="Loading..." width="30"  />
               : <button
                  onClick={createNewChat}
                  style={{"display":"flex","gap":"0.5rem","border":"none","background":"none","alignItems":"center","fontSize":"0.875rem","lineHeight":"1.25rem","color":"#1f9ad6",":hover":{"color":"#1785bd"}}}
                >
                  <PlusCircle style={{"width":"1rem","height":"1rem"}} />
                  New Chat
                </button>}
              </div>
              <div  style={{
    position: "relative",
    marginTop: "0.25rem",
    minHeight: "150px",
  }}>
                 {getHistoryLoading ?            <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 0.7)", 
        zIndex: 10,
      }}
    >
      <img src={exportLoadingGif} alt="Loading..." width="30" />
    </div>
               : chatHistory.map(chat => (
                  <div
                    key={chat.session_id}
                    onClick={() => getSpecificChatMessages(chat.session_id)}
                    style={{"display":"flex","padding":"0.75rem","width":"100%","textAlign":"left",
                      "transitionProperty":"color, background-color, border-color, text-decoration-color, fill, stroke",
                      "transitionTimingFunction":"cubic-bezier(0.4, 0, 0.2, 1)","transitionDuration":"300ms","cursor":"pointer",
                    ...(currentChatId == chat.session_id ? {"background":"rgba(31,154,214,0.1)","color":"#1f9ad6"}:{":hover":{"backgroundColor":"#F3F4F6"}})}}
                 
                  >
                    <div>
                    <div style={{"display":"flex","gap":"0.5rem","alignItems":"center"}}>
                      <MessageCircle style={{"width":"1.25rem","height":"1.25rem"}} />
                      <span style={{"fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,"overflow":"hidden","textOverflow":"ellipsis","whiteSpace":"nowrap"}}>{chat.session_name}</span>
                    </div>
                    <span style={{"marginTop":"0.25rem","fontSize":"0.75rem","lineHeight":"1rem","opacity":0.75}}>
                      {chat.timestamp}
                    </span>
                    </div>
                    <div style={{"marginLeft":"auto"}} onClick={(event) => {
    event.stopPropagation(); // Prevents triggering parent onClick
    deleteHistoryFunction(chat.session_id);
}}>
                    <Delete style={{"width":"1.25rem","height":"1.25rem","marginLeft":"auto","marginTop":"10px"}} />
                    </div>

                  </div>
                ))} 
              </div>
            </div>
          )}

{activeTab === 'documents' && (
            <div style={{"display":"flex","flexDirection":"column","height":"100%"}}>
              <div style={{"borderBottomWidth":"1px"}}>
                  <div style={{"overflowX":"auto","paddingTop":"0.5rem","paddingBottom":"0.5rem","paddingLeft":"1rem","paddingRight":"1rem"}}>
                    <div style={{"display":"flex","gap":"0.5rem"}}>
              {messages.map(chatDocuments=> chatDocuments.documents.length > 0 && (
                
                      chatDocuments.documents.map((doc, index) => (
                        <div
                          key={index}
                          onClick={() => handleDocumentPreview(doc)}
                          style={{"display":"flex","paddingTop":"0.25rem","cursor":"pointer","paddingBottom":"0.25rem","paddingLeft":"0.5rem","paddingRight":"0.5rem","gap":"0.5rem","alignItems":"center","borderRadius":"0.25rem","transitionProperty":"all","transitionTimingFunction":"cubic-bezier(0.4, 0, 0.2, 1)","transitionDuration":"300ms",
                            "border":"none","background":"none",...(selectedThumbnail?.includes(doc.url)
                              && {"color":"#2563EB","backgroundColor":"#EFF6FF"}
                            )
                          }}
                        
                        >
                          <FileText style={{"width":"0.75rem","height":"0.75rem"}} />
                          <div style={{"display":"flex","flexDirection":"column"}}>
                            <span style={{"fontSize":"0.75rem","lineHeight":"1rem","whiteSpace":"nowrap"}}>{doc.name}</span>
                            <span style={{"fontSize":"0.75rem","lineHeight":"1rem","color":"#6B7280"}}>{doc.size}</span>
                          </div>
                        </div>
                      ))
                    
              ))}
</div>
                  </div>
                </div>
              {selectedThumbnail ? (
                <div style={{"flex":"1 1 0%"}}>
                  <iframe
                    src={selectedThumbnail}
                    style={{"borderWidth":"0","width":"100%","height":"100%"}}
                    title="Document Preview"
                  />
                </div>
              ) : (
                <div style={{"display":"flex","flex":"1 1 0%","justifyContent":"center","alignItems":"center","color":"#6B7280"}}>
                  <div style={{"textAlign":"center"}}>
                    <FileText style={{"marginBottom":"0.5rem","width":"3rem","height":"3rem","color":"#9CA3AF"}} />
                    <span>No document selected</span>
                  </div>
                </div>
              )}
            </div>
          )}

           {activeTab === 'rules' && (
            <div style={{"padding":"1rem"}}>
              <div style={{"paddingBottom":"1rem","borderBottom":"1px solid #D1D5DB"}}>
                <input
                  type="text"
                  value={newRuleName}
                  onChange={(e) => setNewRuleName(e.target.value)}
                  placeholder="Rule name"
                  style={{
                    padding: "0.5rem 0.75rem",
                    borderRadius: "0.375rem",
                    border: "1px solid #D1D5DB",
                    width: "100%",
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                    outlineColor:"#1785bd"
                  }}                />
                <textarea
                  value={newRuleDescription}
                  onChange={(e) => setNewRuleDescription(e.target.value)}
                  placeholder="Rule description"
                  style={{
                    width: "100%",
                    marginTop:"6px",
                    padding: "10px",
                    paddingBottom:"12px",
                    "borderRadius":"0.375rem",
                    border: "1px solid #D1D5DB",
                    fontSize: "16px",
                    lineHeight: "1.5",
                    resize: "none",
                    overflow: "hidden",
                    minHeight: "40px",
                    boxShadow: "none",
                    outlineColor:"#1785bd"

                    
                  }}      
                              rows={2}
                />
                <div
                  onClick={handleAddRule}
                  disabled={!newRuleName.trim() || !newRuleDescription.trim()}
style={{"cursor":"pointer","marginTop":"5px","paddingTop":"0.5rem","textAlign":"center","paddingBottom":"0.5rem","paddingLeft":"1rem","paddingRight":"1rem","borderRadius":"0.375rem","fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,"color":"#ffffff","background":"#1f9ad6",":hover":{"background":"#1785bd"}}}                >
                  Add Rule
                </div>
              </div>

              {rules.map((rule) => (
                showUpdateTag == rule.rule_id ? 
<div style={{"display":"flex","justifyContent":"space-between","alignItems":"center" ,"marginTop":"20px"}}>
<div style={{"display":"flex","gap":"0.5rem","alignItems":"center"}}>                 
   <div>
                <input
                  type="text"
                  value={updateTagName}
                  onChange={(e) => setUpdateTagName(e.target.value)}
                  placeholder="Rule name"
                  style={{
                    padding: "0.5rem 0.75rem",
                    borderRadius: "0.375rem",
                    border: "1px solid #D1D5DB",
                    width: "100%",
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                    outlineColor:"#1785bd"

                  }}                />
                <textarea
                  value={updateTagDesc}
                  onChange={(e) => setUpdateTagDesc(e.target.value)}
                  placeholder="Rule description"
                  style={{
                    width: "100%",
                    marginTop:"6px",
                    padding: "10px",
                    paddingBottom:"12px",
                    "borderRadius":"0.375rem",
                    border: "1px solid #D1D5DB",
                    fontSize: "16px",
                    lineHeight: "1.5",
                    resize: "none",
                    overflow: "hidden",
                    minHeight: "40px",
                    boxShadow: "none",
                    outlineColor:"#1785bd"

                    
                  }}      
                              rows={2}
                />
                </div>
                </div>
                <div style={{"display":"flex","gap":"5px"}}>

                <div
onClick={()=>handleFinalUpdaterule(rule)}
disabled={!updateTagName.trim() || !updateTagDesc.trim()}                
      style={{"cursor":"pointer","padding":"0.25rem","borderRadius":"0.25rem","color":"#1F9AD6",":hover":{"backgroundColor":"#F3F4F6"}}}
                    >
                <CheckSquare
                  
                  style={{"width":"1rem","height":"1rem"}}           
                       />
                       </div>
                       <div
onClick={()=>setShowUpdateTag('')}
            
style={{"cursor":"pointer","padding":"0.25rem","borderRadius":"0.25rem","color":"#EF4444",":hover":{"backgroundColor":"#F3F4F6"}}}
>
                <XSquare                  
                  style={{"width":"1rem","height":"1rem"}}           
                       />
                       </div>
                       </div>

              </div>
                
                :
                <div key={rule.rule_id} style={{"marginTop":"0.5rem"}}>
                  <div style={{"display":"flex","justifyContent":"space-between","alignItems":"center"}}>
                    <div style={{"display":"flex","gap":"0.5rem","alignItems":"center"}}>
                      <input
                        type="checkbox"
                        checked={rule.isActive}
                        onChange={() => toggleRule(rule.rule_id)}
                        style={{"borderRadius":"0.25rem","borderColor":"#D1D5DB","width":"1rem","height":"1rem","color":"#1f9ad6"}}
                      />
                      <div style={{"marginLeft":"5px"}}>
                      <span style={{"fontWeight":500}}>{rule.rule_name}</span>
                      <div style={{"fontSize":"0.875rem","lineHeight":"1.25rem","color":"#4B5563"}}>{rule.rule_description}</div>

                      </div>
                    </div>
                    <div style={{"display":"flex","gap":"5px"}}>
                    <div
                      onClick={() => handleUpdaterule(rule)}
                      style={{"cursor":"pointer","padding":"0.25rem","borderRadius":"0.25rem","color":"#1F9AD6",":hover":{"backgroundColor":"#F3F4F6"}}}
                    >
                      <Edit style={{"width":"1rem","height":"1rem"}} />
                    </div>
                    <div
                      onClick={() => handleDeleteRule(rule.rule_id)}
                      style={{"cursor":"pointer","padding":"0.25rem","borderRadius":"0.25rem","color":"#EF4444",":hover":{"backgroundColor":"#F3F4F6"}}}
                    >
                      <Trash2 style={{"width":"1rem","height":"1rem"}} />
                    </div>
                    </div>
                  </div>
                 
                </div>
              ))}
              

            </div>
          )}
          </div>
        </div>
    
    </div>
)
}


export default LegalaiChat