import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import PasswordToggle from "../../component/PasswordToggle";
import Logo from "../../component/ui-components/Logo";
import styles from "./Login.module.css";
import { LoginUser } from "../../api/LoginApi";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      alert("Please fill in both username and password fields.");
      return;
    }
    const loginUserApi = await LoginUser(username,password)
    console.log("this is login response",loginUserApi)
    if (
      loginUserApi.message == `Authentiation succesfull for ${username}`
    ) {
      localStorage.setItem("user_id", loginUserApi.id);

      login();
      navigate("/dashboard");
    } else if (
      username === "zoom@freedompropertyinvestors.com.au" &&
      password === "zoom@123"
    ) {
      login();
      navigate("/zoom-dashboard");
    } else {
      alert("Check Username and Password");
    }
  };

  return (
    <div className={`login-page ${styles.loginPage}`}>
        <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-4">
            <div className="card border-0 shadow-lg">
              <div className="card-body p-5">
                <div className="text-center mb-4">
                  <Logo />

                  <h5 className={`text-secondary ${styles.textSecondary}`} >Freedom Performance Manager</h5>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      placeholder="Email *"
                      required
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">

                    <PasswordToggle showPassword={showPassword} setShowPassword={setShowPassword} password={password} setPassword={setPassword} />

                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="rememberMe"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="rememberMe"
                      >
                        Remember Me
                      </label>
                    </div>
                    <a
                      href="#"
                      className={`text-decoration-none ${styles.textDecorationNone}`}

                    >
                      Forgot Password?
                    </a>
                  </div>
                  <button
                    type="submit"
                    className={`btn btn-primary w-100 py-2 ${styles.btnPrimary}`}

                  >
                    Login
                  </button>
                </form>
              </div>
            </div>
            <div className="text-center mt-4 text-secondary small">
              <p className={`mb-1 ${styles.paragraph}`} >
                © 2024 - Freedom Zoom Analysis - v1.0.2
              </p>
              <p className={`mb-0 ${styles.paragraph}`} >
                Crafted by{" "}
                <a
                  href="#"
                  className={`text-decoration-none ${styles.textDecorationNone}`}
                >
                  Freedom Property Investors
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

 
