import { useEffect, useState } from "react";
import { KeyMetrics } from "../component/KeyMetrics";
import SessionViews from "../component/SessionViews";
import SummaryDateRangeFilter from "../component/SummarDateRangeFilter";
import { PerformanceTrend } from "../component/PerformanceTrend";
import { PerformanceDetails } from "../component/PerformanceDetails";
import { getSummaryAnalysis, kpiApi, lastPerformanceTrend, mainPerformanceTrend } from "../api/summaryAnalysisApi";

const performanceMetrics = {
    averageScore: 4.2,
    totalSessions: 156,
    averageDuration: '45 mins',
    topPerformers: ['John Doe', 'Sarah Williams', 'Mike Johnson', 'Emma Davis', 'Alex Thompson'],
    monthlyTrend: [3.8, 4.0, 4.2, 4.5, 4.2, 4.3],
    criteriaBreakdown: [
        { name: 'Introduction', score: 4.5 },
        { name: 'Technical Knowledge', score: 4.2 },
        { name: 'Communication', score: 4.3 },
        { name: 'Problem Solving', score: 4.0 },
        { name: 'Client Engagement', score: 4.4 }
    ]
};


const SessionSummary = ()=>{
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [discoverySessions,setDiscoverySessions] = useState([])
  const [strategistSessions,setStrategistSessions] = useState([])
  const [loading, setLoading] = useState(true);
  const [performanceData,setPerformanceData] = useState(true)
  const [mainPerformanceDetails,setmainPerformanceDetails] = useState(true)
  const [performanceDetails,setPerformanceDetails] = useState([])
  const [activeTab, setActiveTab] = useState('specialist');
  const [selectedUser, setSelectedUser] = useState("");
const [selectAllUser,setSelectAllUser] = useState([{name:"All Users",email:""}])
const [performanceTrendActiveTab,setPerformanceTrendActiveTab] = useState("discovery")
  function formatDate(date) {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  }
  

  useEffect(() => {
    setLoading(true); 
  
    const formattedStartDate = fromDate !== "" ? formatDate(fromDate) : "";
    const formattedEndDate = toDate !== "" ? formatDate(toDate) : "";
  
    const fetchData = async () => {
      try {
        const [discoveryData, strategistData] = await Promise.all([
          getSummaryAnalysis(formattedStartDate, formattedEndDate, "discovery"),
          getSummaryAnalysis(formattedStartDate, formattedEndDate, "strategy"),
        ]);
        setDiscoverySessions(discoveryData);
        setStrategistSessions(strategistData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  const fetchKpiData =  async()=>{
    const performanceMetricsData = await kpiApi(formattedStartDate,formattedEndDate)
    setPerformanceData(performanceMetricsData)

  }
const fetchMainPerformanceDetails = async()=>{
  const mainPerformanceDetails = await mainPerformanceTrend(formattedStartDate,formattedEndDate,selectedUser)
  setmainPerformanceDetails(mainPerformanceDetails)
  console.log("Active Tab:", performanceTrendActiveTab);

     const allUsers= performanceTrendActiveTab == "discovery" ? [{name:"All Users",email:""}, ...mainPerformanceDetails.specialist_users]: [{name:"All Users",email:""},...mainPerformanceDetails.strategist_users]

           
            setSelectAllUser(allUsers)
}
  const fetchPerformanceDetails = async ()=>{
    const performanceDetailsData = await lastPerformanceTrend(formattedStartDate,formattedEndDate,activeTab)
    setPerformanceDetails(performanceDetailsData)
  }
  fetchKpiData()
  fetchPerformanceDetails()
  fetchMainPerformanceDetails()
    fetchData();
  }, [fromDate, toDate,activeTab,selectedUser,performanceTrendActiveTab]);

 
  
console.log("checking for users",mainPerformanceDetails)
    return(
      <div style={{"padding":"1.5rem"}}>
        <div style={{fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' ,display: 'flex',
  flexDirection: 'column',
  gap: '24px'}}>
                 <SummaryDateRangeFilter fromDate={fromDate}
        toDate={toDate}
        onFromDateChange={setFromDate}
        onToDateChange={setToDate} />
                 <KeyMetrics metrics={performanceData} />
                 
          <SessionViews discoverySessions={discoverySessions}
        strategistSessions={strategistSessions}
        loading={loading}
        />

        {/* <SessionTables discoverySessions={discoverySessions}
        strategistSessions={strategistSessions}/> */}
        <PerformanceTrend mainPerformanceDetails={mainPerformanceDetails} performanceTrendActiveTab={performanceTrendActiveTab} setPerformanceTrendActiveTab={setPerformanceTrendActiveTab} selectedUser={selectedUser} setSelectedUser={setSelectedUser} selectAllUser={selectAllUser}/>
        <PerformanceDetails
        criteriaBreakdown={performanceDetails.performance_trend}
        topPerformers={performanceDetails.performers?.Top}
        worstPerformers={performanceDetails.performers?.Worst}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        // topPerformers={performanceDetails.performers.Top}
      />
     </div>
     </div>
    )
}


export default SessionSummary