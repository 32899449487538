import { createContext, useEffect, useState } from "react";

export const ZoomSessionContext = createContext();

export const ZoomSessionContextProvider = ({ children }) => {
  const [sessionContext, setSessionContext] = useState("");
  const [selectedSession, setSelectedSession] = useState(null);
  const [flagValidUpdate, setFlagValidUpdate] = useState(false);

  const onBackFunction = () => {
    setSelectedSession(null);
    setFlagValidUpdate((prev) => !prev);
    setSessionContext("");
  };
  return (
    <ZoomSessionContext.Provider value={{ sessionContext, setSessionContext, selectedSession,
      setSelectedSession,
      flagValidUpdate,
      setFlagValidUpdate,
      onBackFunction }}>
      {children}
    </ZoomSessionContext.Provider>
  );
};
