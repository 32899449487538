import { Tag, X } from "lucide-react";

const TagFilter = ({selectedTags,setSelectedTags,availableTags}) =>{
  const TagPill = ({ tag, onRemove }) => (
    <div style={{"display":"flex","paddingBottom":"0.25rem","paddingLeft":"0.5rem","paddingRight":"0.5rem","gap":"0.25rem","alignItems":"center","borderRadius":"9999px","fontSize":"0.875rem","lineHeight":"1.25rem","color":"#1D4ED8","backgroundColor":"#DBEAFE"}}>
      <span>{tag}</span>
      <div onClick={onRemove} style={{"cursor":"pointer","padding":"0.125rem","borderRadius":"9999px",":hover":{"backgroundColor":"#BFDBFE"}}}>
        <X style={{"width":"0.75rem","height":"0.75rem"}} />
      </div>
    </div>
  );
      console.log("available tags",availableTags)
    return(
        <div className="card shadow-sm mb-3">
        <div className="card-body">
            <div className="d-flex align-items-center mb-3">
            <Tag style={{"marginRight":"0.5rem","width":"1.25rem","height":"1.25rem","color":"#1f9ad6"}} />
            <span style={{"fontWeight":500}}>Tags</span>
          </div>
          <select 
                            className="form-select"

          style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingLeft":"0.5rem","paddingRight":"0.5rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","width":"100%","fontSize":"0.875rem","lineHeight":"1.25rem"}}
          onChange={(e) => {
              if (e.target.value !== "") {
                setSelectedTags([...selectedTags, e.target.value]);
                e.target.value = "";
              }
            }}
          >
            <option value="">Select tags...</option>
            {availableTags?.map((tag) => (
              <option key={tag} value={tag}>
                {tag}
              </option>
            ))}
          </select>
          {selectedTags?.length > 0 && (
            <div style={{"display":"flex","marginTop":"0.5rem","flexWrap":"wrap","gap":"0.5rem"}}>
              {selectedTags.map((tag) => (
                <TagPill
                  key={tag}
                  tag={tag}
                  onRemove={() => setSelectedTags(selectedTags.filter(t => t !== tag))}
                />
              ))}
            </div>
          )}
        </div>
        </div>

    )
}

export default TagFilter
