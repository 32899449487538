import "../component/ui-components/filterString.css"
const Searching = ({ value, onChange, placeholder }) => (
    <div className="col-md-3 filter-user-container" style={{height:"32px"}}>
      <p className="filter-string">Search By:</p>
      &nbsp;&nbsp;
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        style={{ width: "calc(100% - 100px)", fontSize: 12 }}
        />
    </div>
  );
  
  export default Searching