import { FileText } from "lucide-react";
import { useEffect, useRef } from "react";
import exportLoadingGif from "../assets/exportloading.gif"; 
import typeloading from "../assets/typeloading.gif"
const LegalUserChat = ({messages,chatApiErrorCase,getSpecificMessageLoading,setSelectedThumbnail,setActiveTab,setMarkingDocument,saveInputMessage}) =>{
    const chatContainerRef = useRef(null);
    useEffect(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
      }, [messages,saveInputMessage]);

      
      console.log("these are the messages",messages)

      const showPreviewFunction= (url)=>{
        const mainurl = url.includes("http") ? url : `https://legal.kuru.ai/chat/${url}`;
        setSelectedThumbnail(mainurl)
        setMarkingDocument('')
        setActiveTab("documents")
      }

      console.log("this is the saved input message",saveInputMessage)


      const formatFileSize = (bytes) => {
        if (bytes < 1024) {
          return `${bytes} bytes`;
        } else if (bytes < 1024 * 1024) {
          return `${(bytes / 1024).toFixed(2)} KB`;
        } else {
          return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
        }
      };


      function formatResponse(text) {
        if (!text) return "";
        return text
        .replace(/^- | - /gm, "• ") 
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Bold for **text**
          .replace(/\*(.*?)\*/g, "<strong>$1</strong>") // Bold for *text*
          .replace(/\/n/g, "<br />") // Replace /n with <br>
          .replace(/<br\s*\/?>/g, "<br />"); // Ensure existing <br> tags are correctly rendered
      }
    return(
      <div 
  ref={chatContainerRef}
  style={{
    position: "relative", 
    overflowY: "auto",
    padding: "1.5rem",
    marginTop: "1rem",
    flex: "1 1 0%",
    minHeight: "200px" 
  }}
>
  {getSpecificMessageLoading ? (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 0.7)", 
        zIndex: 10, 
      }}
    >
      <img src={exportLoadingGif} alt="Loading..." width="50" />
    </div>
  ) : (
    <div style={{ height: "100px" }}>
      {messages.map((message) => (
        <div key={message.session_id} style={{ display: "flex", flexDirection: "column", marginTop: "0.5rem" }}>
          {/* User Message */}
           
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "0.5rem" }}>
              <div
                style={{
                  padding: "1rem",
                  borderRadius: "0.5rem",
                  maxWidth: "42rem",
                  color: "#ffffff",
                  background: "#1f9ad6",
                }}
              >
                {message.documents && message.documents.length > 0 && (
                  <div style={{ marginBottom: "0.75rem", marginTop: "0.5rem" }}>
                    {message.documents.map((file, index) => (
                      <div
                        key={index}
                        onClick={()=>showPreviewFunction(file.url)}
                        style={{
                          display: "flex",
                          padding: "0.5rem",
                          gap: "0.5rem",
                          alignItems: "center",
                          borderRadius: "0.25rem",
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          cursor:"pointer"
                        }}
                      >
                        <FileText style={{ width: "1rem", height: "1rem" }} />
                        <span
                          style={{
                            flex: "1 1 0%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {file.name}
                        </span>
                        <span style={{ fontSize: "0.75rem", lineHeight: "1rem", opacity: 0.75 }}>
                          {file.size}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
               
                <p style={{ color: "#ffffff", fontWeight: 400 }}>{message.user_message}</p>
                <div
                  style={{
                    marginTop: "0.5rem",
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    opacity: 0.75,
                    textAlign: "right",
                  }}
                >
                  {message.timestamp || message.created_at}
                </div>
              </div>
            </div>
          

          {/* AI Response */}
          
            <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "0.5rem" }}>
              <div
                style={{
                  padding: "1rem",
                  borderRadius: "0.5rem",
                  maxWidth: "42rem",
                  backgroundColor: "#ffffff",
                  boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                }}
              >
                <p style={{ color: "#1F2937", fontWeight: 400 }}>{formatResponse(message.ai_response)}</p>
                <div
                  style={{
                    marginTop: "0.5rem",
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    opacity: 0.75,
                  }}
                >
                  {message.timestamp || message.created_at}
                </div>
              </div>
            </div>
          
        </div>
      ))}
      {
                  saveInputMessage !="" && (
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "0.5rem",marginRight:"0.2rem" }}>
                    {/* User Message */}
                     
                      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "0.5rem" }}>
                        <div
                          style={{
                            padding: "1rem",
                            borderRadius: "0.5rem",
                            maxWidth: "42rem",
                            color: "#ffffff",
                            background: "#1f9ad6",
                          }}
                        >
{saveInputMessage.files && saveInputMessage.files.length > 0 && (
                  <div style={{ marginBottom: "0.75rem", marginTop: "0.5rem" }}>
                    {saveInputMessage.files.map(filedoc=>
                      <div
                        style={{
                          display: "flex",
                          padding: "0.5rem",
                          gap: "0.5rem",
                          alignItems: "center",
                          borderRadius: "0.25rem",
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          cursor:"pointer"
                        }}
                      >
                        <FileText style={{ width: "1rem", height: "1rem" }} />
                        <span
                          style={{
                            flex: "1 1 0%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {filedoc.name}
                        </span>
                        <span style={{ fontSize: "0.75rem", lineHeight: "1rem", opacity: 0.75 }}>
                        {formatFileSize(filedoc.size)}
                        </span>
                      </div>
                    )
}
</div>
                        
)
}
<p style={{ color: "#ffffff", fontWeight: 400 }}>{saveInputMessage.message}</p>
                <div
                  style={{
                    marginTop: "0.5rem",
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    opacity: 0.75,
                    textAlign: "right",
                  }}
                >
                  {saveInputMessage.timing}
                </div>


                        </div>

                          </div>
                          <div
                  style={{
                    marginTop: "0.5rem",
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    opacity: 0.75,
                    textAlign: "right",
                  }}
                >
                  Sending...
                </div>
                      </div>
                  )
                }

                {
                  saveInputMessage !="" && (
                    chatApiErrorCase ?  <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "0.5rem" }}>
                    <div
                      style={{
                        padding: "1rem",
                        borderRadius: "0.5rem",
                        maxWidth: "42rem",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <p style={{ color: "#1F2937", fontWeight: 400 }}>This service is currently unavailable.</p>
                      </div>
                      </div>
                       :
                   <img
                   src={typeloading} 
                   alt="Typinggif"
                   style={{
                     width: '40px',
                     height: '40px',
                     imageRendering: 'auto', 
                   }}
                 />
                  )
                }
    </div>
  )}
</div>


    )
}

export default LegalUserChat