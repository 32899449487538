// Dashboard.jsx
import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.css"

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import {
  fetchUserCountGraphData,
  fetchDoctorCountGraphData,
  fetchBookedAppointmentGraphData,
  fetchBroadcastChannels,
  fetchKpiStats,
  fetchUserActivityOverTime,
  fetchLeadStatusDistribution,
  fetchAppointmentTrendsData,
  fetchMessageVolumeOverTime,
  fetchResponseTimeDistribution,
} from "../../api/dashboardApi";
import UserActivityChart from "../../component/charts/UserActivityChart";
import LeadStatusChart from "../../component/charts/LeadStatusChart";
import AppointmentTrendsChart from "../../component/charts/AppointmentTrendsChart";
import ResponseTimeChart from "../../component/charts/ResponseTimeChart";
import MessageVolumeChart from "../../component/charts/MessageVolumeChart";
import KpiCard from "../../component/KpiCard";
import useFetchData from "../../hooks/useFetchData";
import DownloadReport from "../../component/DownloadReport";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function Dashboard() {
  // Existing state variables
  const [userCountGraphData, setUserCountGraphData] = useState([]);
  const [doctorCountGraphData, setDoctorCountGraphData] = useState([]);
  const [bookedAppointmentCountGraphData, setBookedAppointmentCountGraphData] =
    useState([]);

  // New state variables
  const [channels, setChannels] = useState([]);
  const [selectedBroadcastId, setSelectedBroadcastId] = useState("All");
  const [data, updateData] = useState(null);

  // New state variables for user activity over time
  const [userActivityData, setUserActivityData] = useState([]);
  const [activityPeriod, setActivityPeriod] = useState("daily"); // Default period is 'daily'

  // New state variable for lead status distribution
  const [leadStatusData, setLeadStatusData] = useState([]);

  // New state variables for appointment trends
  const [appointmentTrendsData, setAppointmentTrendsData] = useState([]);
  const [appointmentPeriod, setAppointmentPeriod] = useState("daily"); // Default period is 'daily'

  // New state variable for response time distribution
  const [responseTimeData, setResponseTimeData] = useState([]);

  // **New State Variables for Message Volume Over Time**
  const [messageVolumeData, setMessageVolumeData] = useState([]);
  const [messageVolumePeriod, setMessageVolumePeriod] = useState("daily"); // Default period is 'daily'

  useFetchData(fetchBroadcastChannels, setChannels);
  useFetchData(fetchUserCountGraphData, setUserCountGraphData);
  useFetchData(fetchDoctorCountGraphData, setDoctorCountGraphData);
  useFetchData(fetchBookedAppointmentGraphData, setBookedAppointmentCountGraphData);
  useFetchData(fetchLeadStatusDistribution, setLeadStatusData);
  useFetchData(fetchResponseTimeDistribution, setResponseTimeData);

  useEffect(() => {
    // Fetch KPI stats whenever the selected broadcastId changes

    const fetchData = async () => {
      try {
        const kipStats = await fetchKpiStats(selectedBroadcastId);
        updateData(kipStats)
      } catch (error) {
        console.error("Error fetching dashboard data", error);
      }
    }
    fetchData()
  }, [selectedBroadcastId]);

  useEffect(() => {
    // Fetch user activity data whenever the activityPeriod changes
    const fetchData = async () => {
      try {
        const activityData = await fetchUserActivityOverTime(activityPeriod);
        setUserActivityData(activityData);
      } catch (error) {
        console.error("Error fetching dashboard data", error);
      }
    }
    fetchData()
  }, [activityPeriod]);

  useEffect(() => {
    // Fetch appointment trends data whenever the appointmentPeriod changes
    const fetchData = async () => {
      try {
        const appointmentTrends = await fetchAppointmentTrendsData(appointmentPeriod);
        setAppointmentTrendsData(appointmentTrends);
      } catch (error) {
        console.error("Error fetching dashboard data", error);
      }
    }
    fetchData()
  }, [appointmentPeriod]);

  useEffect(() => {
    // Fetch message volume data whenever the messageVolumePeriod changes
    const fetchData = async () => {
      try {
        const messageVolume = await fetchMessageVolumeOverTime(messageVolumePeriod);
        setMessageVolumeData(messageVolume);
      } catch (error) {
        console.error("Error fetching dashboard data", error);
      }
    }
    fetchData()
  }, [messageVolumePeriod]);



  const handleChannelChange = (event) => {
    const broadcastId = event.target.value;
    setSelectedBroadcastId(broadcastId);
    // fetchKpiStats will be called automatically due to the useEffect dependency
  };

  const handleActivityPeriodChange = (event) => {
    const period = event.target.value.toLowerCase();
    setActivityPeriod(period);
    // fetchUserActivityOverTime will be called automatically due to the useEffect dependency
  };
  const handleAppointmentPeriodChange = (event) => {
    const period = event.target.value.toLowerCase();
    setAppointmentPeriod(period);
    // fetchAppointmentTrendsData will be called automatically due to the useEffect dependency
  };

  // **New Handler for Message Volume Period Change**
  const handleMessageVolumePeriodChange = (event) => {
    const period = event.target.value.toLowerCase();
    setMessageVolumePeriod(period);
    // fetchMessageVolumeOverTime will be called automatically due to the useEffect dependency
  };


  // Removed fetchSubscribeUserCountGraphData and fetchRevenueGeneratedGraphData

  console.log("acitivityperiod,", activityPeriod)

  return (
    <div className="main " style={{"padding": "1.5rem"}}>
      {/* Channel Selection */}
      <div className={`row `}>
        <div
          className={`col-md-4 ${styles.channelSelection}`}

        >
          <p className={styles.channelLabel}>Channels:&nbsp;&nbsp;</p>
          <select
            className="form form-control"
            value={selectedBroadcastId}
            onChange={handleChannelChange}
          >
            <option value="All">All</option>
            {channels.map((channel) => (
              <option key={channel.broadcastId} value={channel.broadcastId}>
                {channel.channelName}
              </option>
            ))}
          </select>
        </div>
        <DownloadReport />
      </div>

      {/* KPI Metrics */}
      <div className={`row ${styles.kpiRow}`} >
        <KpiCard
          title="Broadcast Outreach"
          count={data?.message_count}
          subtitle="Unique Users"
        />
        <KpiCard
          title="User Response"
          count={data?.activity_count}
          subtitle="Based on Last Activity Date"
        />
        <KpiCard
          title="Opted-Out Users"
          count={data?.opt_out_count}
          subtitle="Users Unsubscribed"
        />
        {data?.block_count !== null && (
          <KpiCard
            title="Blocked Users"
            count={data?.block_count}
            subtitle="Restricted on Platform"
          />
        )}
      </div>

      {/* User Activity and Lead Status Distribution */}
      <div className={`row ${styles.chartRow}`}>
        {/* User Activity Over Time Chart */}
        <UserActivityChart
          userActivityData={userActivityData}
          activityPeriod={activityPeriod}
          handleActivityPeriodChange={handleActivityPeriodChange}
        />


        {/* Lead Status Distribution Chart (Pie Chart) */}
        <LeadStatusChart
          leadStatusData={leadStatusData}
          colorMapping={{
            Completed: "rgb(4, 170, 109)",
            Pending: "rgb(255, 165, 0)",
            Rescheduled: "rgb(255, 99, 71)",
          }}
        />
      </div>

      {/* Appointment Trends and Response Time Distribution */}
      <div className={`row ${styles.chartRow} ${styles.chartRowTop}`}>
        {/* Appointment Trends Chart */}
        <AppointmentTrendsChart appointmentTrendsData={appointmentTrendsData}
          appointmentPeriod={appointmentPeriod}
          handleAppointmentPeriodChange={handleAppointmentPeriodChange} />

        {/* Response Time Distribution Histogram */}
        <ResponseTimeChart responseTimeData={responseTimeData} />
        <MessageVolumeChart messageVolumePeriod={messageVolumePeriod} handleMessageVolumePeriodChange={handleMessageVolumePeriodChange} messageVolumeData={messageVolumeData} />

      </div>
      {/* Message Volume Over Time and Additional Charts */}
      {/* <div className={`row ${styles.chartRow}`}> */}
        {/* Message Volume Over Time Chart */}

        {/* Additional Chart Placeholder (Optional) */}
        {/* You can add more charts here if needed */}
        {/* <div
          className={`col-md-4 ${styles.chartColumn}`}
        ></div> */}
      {/* </div> */}
    </div >
  );
}


export default Dashboard;
