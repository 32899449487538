import Seven from "../assets/7.svg";
import "./ui-components/icnImage.css"
import "./Logout.css"
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { LogOut, PanelLeftClose } from "lucide-react";

const Logout = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
  
    const handleLogout = () => {
      logout();
      navigate("/");
    };

    return (
        <div style={{"padding":"1.15rem","borderTopWidth":"1px","borderColor":"#1785bd","borderTopStyle": "solid"}}>
        <div style={{"display":"flex","justifyContent":"space-between","alignItems":"center"}}>
                <div
                  onClick={handleLogout}
                  style={{"cursor":"pointer","display":"flex","padding":"0.5rem","alignItems":"center","borderRadius":"0.25rem","color":"#ffffff",":hover":{"background":"#1785bd"}}}
                >
                  <LogOut style={{"shrink":"0","width":"1.25rem","height":"1.25rem"}} />
                  <span style={{"marginLeft":"0.5rem"}}>Logout</span>
                </div>
                <div
                //   onClick={() => setSidebarCollapsed(true)}
                  style={{"cursor":"pointer","padding":"0.5rem","borderRadius":"0.25rem","color":"#ffffff",":hover":{"background":"#1785bd"}}}
                >
                  <PanelLeftClose style={{"width":"1.25rem","height":"1.25rem"}} />
                </div>
              </div>  
              </div>
    )
}

export default Logout