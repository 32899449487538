import React, { useEffect, useState } from 'react';
import { Download, Clock, Star } from 'lucide-react';
import * as XLSX from "xlsx";
import exportLoadingGif from "../assets/exportloading.gif"; 

import "./SessionTables.css"
import PaginationControl from './PaginationControl';
import SummaryAnalysisPagination from './SummaryAnalysisPagination';
import usePagination from '../hooks/usePagination';
export function SessionTables({ 
  discoverySessions, 
  strategistSessions,
  activeTab,
  loading,
  onTabChange
}) {
  
  const [isHoveredTable, setIsHoveredTable] = useState(false);
  const [duration,setDuration] = useState("all")
  const [score,setScore] = useState("all")
  const [filteredDiscovery,setFilteredDiscovery] = useState([])
  const [filteredStrategist,setFilteredStrategist] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [tempPage, updateTempPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [discoveryPaginatedData, setDiscoveryPaginatedData] = useState([]);
  const [strategyPaginatedData, setStrategistPaginatedData] = useState([]);

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    if (discoverySessions.length >= 0) {
      let filteredData = discoverySessions;
  
      // Apply duration filter
      if (duration !== "all") {
        if (duration === "90+") {
          filteredData = filteredData.filter(
            (data) => Number(data.Average_duration) > 90
          );
        }
        else if (duration == "60-90") {
          filteredData = filteredData.filter(
            (data) =>
              Number(data.Average_duration) >= 60 &&
              Number(data.Average_duration) <= 90
          );}
        else if (duration === "45-60") {
          filteredData = filteredData.filter(
            (data) =>
              Number(data.Average_duration) < 60 &&
              Number(data.Average_duration) > 45
          );
        } else if (duration === "<45") {
          filteredData = filteredData.filter(
            (data) => Number(data.Average_duration) < 45
          );
        }
      }
  
      // Apply score filter
      if (score !== "all") {
        if (score === "4-5") {
          filteredData = filteredData.filter(
            (data) => Number(data.Overall_Average) >= 4 && Number(data.Overall_Average) <= 5
          );
        } else if (score === "3-4") {
          filteredData = filteredData.filter(
            (data) => Number(data.Overall_Average) >= 3 && Number(data.Overall_Average) < 4
          );
        } else if (score === "<3") {
          filteredData = filteredData.filter(
            (data) => Number(data.Overall_Average) < 3
          );
        }
      }
  
      setFilteredDiscovery(filteredData);
      
    }

    if (strategistSessions.length >= 0) {

      let filteredData = strategistSessions;
      if (duration !== "all") {
        if (duration === "90+") {
          filteredData = filteredData.filter(
            (data) => Number(data.Average_duration) > 90
          );
        } 
        else if (duration == "60-90") {
          filteredData = filteredData.filter(
            (data) =>
              Number(data.Average_duration) >= 60 &&
              Number(data.Average_duration) <= 90
          );}
        else if (duration === "45-60") {
          filteredData = filteredData.filter(
            (data) =>
              Number(data.Average_duration) <= 60 &&
              Number(data.Average_duration) >= 45
          );
        } else if (duration === "<45") {
          filteredData = filteredData.filter(
            (data) => Number(data.Average_duration) < 45
          );
        }
      }
  
      // Apply score filter
      if (score !== "all") {
        if (score === "4-5") {
          filteredData = filteredData.filter(
            (data) => Number(data.Overall_Average) >= 4 && Number(data.Overall_Average) <= 5
          );
        } else if (score === "3-4") {
          filteredData = filteredData.filter(
            (data) => Number(data.Overall_Average) >= 3 && Number(data.Overall_Average) < 4
          );
        } else if (score === "<3") {
          filteredData = filteredData.filter(
            (data) => Number(data.Overall_Average) < 3
          );
        }
      }
      setFilteredStrategist(filteredData);
      
   

    }
  }, [duration, score, discoverySessions,strategistSessions])
  useEffect(() => {
    setCurrentPage(1)
    updateTempPage(1)
  }, [filteredDiscovery, filteredStrategist]);
  const exportToSpecialistExcel = () => {
    const headers = [
      "Specialist",
      "Email",
      "Introduction",
      "Cash Flow Tour",
      "Closing & Follow Up",
      "Freedom Method",
      "Member Understanding",
      "Preparation",
      "The Gap",
      "Unpack",
      "Avg Duration",
      "Overall Score"
    ];
  
    // Convert table data
    const data = filteredDiscovery.map((session) => [
      session.name,
      session.email,
      (Math.floor(session.Criteria_Averages.introduction * 10)/10),
      (Math.floor(session.Criteria_Averages.cash_flow_tour * 10)/10),
      (Math.floor(session.Criteria_Averages.closing_and_follow_up * 10)/10),
      (Math.floor(session.Criteria_Averages.freedom_method_presentation * 10)/10),
      (Math.floor(session.Criteria_Averages.member_understanding_and_journey * 10)/10),
      (Math.floor(session.Criteria_Averages.preparation * 10)/10),
      (Math.floor(session.Criteria_Averages.the_gap * 10)/10),
      (Math.floor(session.Criteria_Averages.unpack * 10)/10),
      (Math.floor(session.Average_duration * 10)/10),
      (Math.floor(session.Overall_Average * 10)/10)
    ]);
  
    // Add headers
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Discovery Data");
  
    // Write file and trigger download
    XLSX.writeFile(workbook, "discovery_data.xlsx");
  };
  
  const exportToStrategistExcel = () => {
    const headers = [
      "Strategist",
      "Email",
      "Rapport Build",
      "Qualifying Appointment",
      "Set Tone Early",
      "Set Agenda",
      "UNPACK - Discovery",
      "Freedom Knowledge",
      "Personal Story",
      "Finance Assessment 1",
      "Finance Assessment 2",
      "Lazy Equity",
      "Cashflow Sheet",
      "Property Planner",
      "Freedom Criteria",
      "Recap Decision",
      "Session Time",
      "Ask for Referral",
      "Expectation",
      "Final Recap",
      "Avg Duration",
      "Overall Score"
    ];
    // Convert table data
    const data = filteredStrategist.map((session) => [
      session.name,
      session.email,
        (Math.floor(session.Criteria_Averages.rapport_building *10)/10),
        (Math.floor(session.Criteria_Averages.appointment_qualification *10)/10),
        (Math.floor(session.Criteria_Averages.set_tone_early *10)/10),
        (Math.floor(session.Criteria_Averages.set_agenda *10)/10),
        (Math.floor(session.Criteria_Averages.discovery_questions *10)/10),
        (Math.floor(session.Criteria_Averages.knowledge_check *10)/10),
        (Math.floor(session.Criteria_Averages.personal_story *10)/10),
        (Math.floor(session.Criteria_Averages.finance_assessment_page1 *10)/10),
        (Math.floor(session.Criteria_Averages.finance_assessment_page2 *10)/10),
        (Math.floor(session.Criteria_Averages.lazy_equity_smsf *10)/10),
        (Math.floor(session.Criteria_Averages.cashflow_sheet *10)/10),
        (Math.floor(session.Criteria_Averages.ultimate_property_planner *10)/10),
        (Math.floor(session.Criteria_Averages.freedom_criteria *10)/10),
        (Math.floor(session.Criteria_Averages.recap_decision *10)/10),
        (Math.floor(session.Criteria_Averages.secure_property_session *10)/10),
        (Math.floor(session.Criteria_Averages.ask_for_referral *10)/10),
        (Math.floor(session.Criteria_Averages.expectations_decision *10)/10),
        (Math.floor(session.Criteria_Averages.final_recap *10)/10),
        (Math.floor(session.Average_duration *10)/10),
        (Math.floor(session.Overall_Average *10)/10),
    ]);
  
    // Add headers
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Strategist Data");
  
    // Write file and trigger download
    XLSX.writeFile(workbook, "Strategy_data.xlsx");
  };
  
  usePagination(filteredDiscovery, currentPage, itemsPerPage, setStartIndex, setEndIndex, setDiscoveryPaginatedData, setTotalPages,totalPages,discoveryPaginatedData);
  usePagination(filteredStrategist, currentPage, itemsPerPage, setStartIndex, setEndIndex, setStrategistPaginatedData, setTotalPages, totalPages,strategyPaginatedData);

  return (
    <div style={{"padding":"1.5rem"}}>
     
         
          {activeTab === 'discovery' ? (
          <div>
             <div style={{"display":"flex","marginBottom":"1rem","gap":"1rem","justifyContent":"flex-end","alignItems":"center"}}>
            {/* Duration Filter */}
            <div style={{ position: 'relative' }}>
              <select
                style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingRight":"1rem","paddingLeft":"2rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","fontSize":"0.875rem","lineHeight":"1.25rem","backgroundColor":"#ffffff","appearance":"none",":hover":{"borderColor":"#9CA3AF"}}}
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              >
                <option value="all">All Duration</option>
                <option value="90+">More than 90 minutes</option>
                <option value="60-90">Between 60-90 minutes</option>
                <option value="45-60">Between 45-60 minutes</option>
                <option value="<45">Less than 45 minutes</option>
              </select>
              <Clock style={{ position: 'absolute', left: '8px', top: '50%', transform: 'translateY(-50%)', height: '16px', width: '16px', color: '#9ca3af' }} />
            </div>

            {/* Score Filter */}
            <div style={{ position: 'relative' }}>
              <select
               style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingRight":"1rem","paddingLeft":"2rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","fontSize":"0.875rem","lineHeight":"1.25rem","backgroundColor":"#ffffff","appearance":"none",":hover":{"borderColor":"#9CA3AF"}}}
               value={score}
                onChange={(e) => setScore(e.target.value)}
             >
                <option value="all">All Score</option>
                <option value="4-5">4-5</option>
                <option value="3-4">3-4</option>
                <option value="<3">Under 3</option>
              </select>
              <Star style={{ position: 'absolute', left: '8px', top: '50%', transform: 'translateY(-50%)', height: '16px', width: '16px', color: '#9ca3af' }} />
            </div>

            {/* Export Button */}
            <button
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        padding: "0.375rem 1rem",
        borderRadius: "0.375rem",
        borderWidth: "1px",
        borderColor: "#D1D5DB",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        backgroundColor: "#ffffff",
        cursor: "pointer",
        outline: "none",
        borderStyle: "solid",
        boxShadow: "none"
      }}
      onClick={() => exportToSpecialistExcel()}
    >
      Export Data
      <Download style={{ height: "16px", width: "16px", color: "#9ca3af" }} />
    </button>
          </div>
            <div  style={{ overflowX: "auto",overflowY:"auto", height:"80vh"  }} >
          <table   style={{"borderTopWidth":"1px","borderColor":"#E5E7EB","minWidth":"100%"}}>
          <thead style={{ backgroundColor: "#F9FAFB", position: "sticky", top: 0, zIndex: 10  }}>
                <tr>
                  <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase",position: 'sticky',  // Makes the column fixed while scrolling
  left: 0,             // Keeps it at the leftmost position
  background: '#F9FAFB', // Ensures content visibility over other rows
  zIndex: 1            // Keeps it above other elements
  }}>Specialist</th>
                  <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Introduction</th>
                  <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Cash Flow Tour</th>
                  <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Closing & Follow Up</th>
                  <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Freedom Method</th>
                  <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Member Understanding</th>
                  <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Preparation</th>
                  <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>The Gap</th>
                  <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Unpack</th>
                  <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Avg Duration</th>
                  <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Overall Score</th>
                </tr>
              </thead>
              <tbody style={{"borderTopWidth":"1px","borderColor":"#E5E7EB","backgroundColor":"#ffffff"}}>
                {loading ?        <tr>
      <td colSpan="100%">
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50px" }}>
          <img src={exportLoadingGif} alt="Loading..." width="40" />
        </div>
      </td>
    </tr>
                : filteredDiscovery.length == 0 ?      <tr>
                <td colSpan="100%">
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50px" }}>
                  No Data Available
                  </div>
                </td>
              </tr>   : discoveryPaginatedData.map((session, index) => (
                  <tr key={index} className='tablebody'  style={{
                    "border-bottom": "1px solid #e5e7eb" 
                  }}
                  onMouseEnter={() => setIsHoveredTable(true)}
                  onMouseLeave={() => setIsHoveredTable(false)}>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  position: 'sticky',  // Makes the column fixed while scrolling
  left: 0,             // Keeps it at the leftmost position
  background: 'white', // Ensures content visibility over other rows
  zIndex: 1            // Keeps it above other elements
}}>
                      <div style={{"fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,"color":"#111827",whiteSpace:"nowrap"}}>{session.name}</div>
                      {/* <div   style={{
    color: '#6b7280',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '170px', 
  }}>{session.email}</div> */}
                    </td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.introduction * 10)/10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.cash_flow_tour * 10)/10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.closing_and_follow_up * 10)/10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.freedom_method_presentation * 10)/10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.member_understanding_and_journey * 10)/10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.preparation * 10)/10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.the_gap * 10)/10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.unpack * 10)/10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Average_duration * 10)/10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  fontWeight: 500,
  color: '#2563eb' // Blue 600
}}>{(Math.floor(session.Overall_Average* 10)/10)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mt-2'>
          <SummaryAnalysisPagination
        currentPage={currentPage}
        totalPages={totalPages}
        tempPage={tempPage}
        setCurrentPage={setCurrentPage}
        updateTempPage={updateTempPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
     
      />
      </div>
          </div>
      ) : (
        <div>
        <div style={{"display":"flex","marginBottom":"1rem","gap":"1rem","justifyContent":"flex-end","alignItems":"center"}}>
        {/* Duration Filter */}
        <div style={{ position: 'relative' }}>
          <select
            style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingRight":"1rem","paddingLeft":"2rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","fontSize":"0.875rem","lineHeight":"1.25rem","backgroundColor":"#ffffff","appearance":"none",":hover":{"borderColor":"#9CA3AF"}}}
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          >
            <option value="all">All Duration</option>
            <option value="90+">More than 90 minutes</option>
            <option value="60-90">Between 60-90 minutes</option>
            <option value="45-60">Between 45-60 minutes</option>
            <option value="<45">Less than 45 minutes</option>
          </select>
          <Clock style={{ position: 'absolute', left: '8px', top: '50%', transform: 'translateY(-50%)', height: '16px', width: '16px', color: '#9ca3af' }} />
        </div>

        {/* Score Filter */}
        <div style={{ position: 'relative' }}>
          <select
           style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingRight":"1rem","paddingLeft":"2rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","fontSize":"0.875rem","lineHeight":"1.25rem","backgroundColor":"#ffffff","appearance":"none",":hover":{"borderColor":"#9CA3AF"}}}
           value={score}
            onChange={(e) => setScore(e.target.value)}
         >
            <option value="all">All Score</option>
            <option value="4-5">4-5</option>
            <option value="3-4">3-4</option>
            <option value="<3">Under 3</option>
          </select>
          <Star style={{ position: 'absolute', left: '8px', top: '50%', transform: 'translateY(-50%)', height: '16px', width: '16px', color: '#9ca3af' }} />
        </div>

        {/* Export Button */}
        <button
  style={{
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "0.375rem 1rem",
    borderRadius: "0.375rem",
    borderWidth: "1px",
    borderColor: "#D1D5DB",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    backgroundColor: "#ffffff",
    cursor: "pointer",
    outline: "none",
    borderStyle: "solid",
    boxShadow: "none"
  }}
  onClick={() => exportToStrategistExcel()}
>
  Export Data
  <Download style={{ height: "16px", width: "16px", color: "#9ca3af" }} />
</button>
      </div>
        <div  style={{ overflowX: "auto",overflowY:"auto", height:"80vh" }} >

        <table   style={{ width: "100%", tableLayout: "auto" }}>
        <thead style={{ backgroundColor: "#F9FAFB", position: "sticky", top: 0, zIndex: 10  }}>
          <tr>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase", position: 'sticky',  // Makes the column fixed while scrolling
  left: 0,             // Keeps it at the leftmost position
  background: '#F9FAFB', // Ensures content visibility over other rows
  zIndex: 1            // Keeps it above other elements
  }}>Strategist</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Rapport Build</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Qualifying Appointment</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Set Tone Early</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Set Agenda</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>UNPACK - Discovery</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Freedom Knowledge</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Personal Story</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Finance Assessment 1</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Finance Assessment 2</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Lazy Equity</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Cashflow Sheet</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Property Planner</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Freedom Criteria</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Recap Decision</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Session Time</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Ask for Referral</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Expectation</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Final Recap</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Avg Duration</th>
            <th style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","fontSize":"0.75rem","lineHeight":"1rem","fontWeight":500,"letterSpacing":"0.05em","textAlign":"left","color":"#6B7280","textTransform":"uppercase"}}>Overall Score</th>
          </tr>
        </thead>
        <tbody style={{"borderTopWidth":"1px","borderColor":"#E5E7EB","backgroundColor":"#ffffff" }}>
        {loading ?         <tr>
      <td colSpan="14%">
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50px" }}>
          <img src={exportLoadingGif} alt="Loading..." width="40" />
        </div>
      </td>
    </tr>
                : filteredStrategist.length == 0 ?     <tr>
                <td colSpan="14%">
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50px" }}>
                  No Data Available
                  </div>
                </td>
              </tr>   : strategyPaginatedData.map((session, index) => (
                  <tr key={index} className='tablebody'  style={{
                    "border-bottom": "1px solid #e5e7eb" 
                  }}
                  onMouseEnter={() => setIsHoveredTable(true)}
                  onMouseLeave={() => setIsHoveredTable(false)}>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  position: 'sticky',  // Makes the column fixed while scrolling
  left: 0,             // Keeps it at the leftmost position
  background: 'white', // Ensures content visibility over other rows
  zIndex: 1            // Keeps it above other elements
}}>
                      <div style={{"fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,"color":"#111827","whiteSpace":"nowrap"}}>{session.name}</div>
                      {/* <div style={{
    color: '#6b7280',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '170px', 
  }}>{session.email}</div> */}
                    </td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.rapport_building * 10)/ 10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.appointment_qualification * 10)/ 10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.set_tone_early * 10)/ 10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.set_agenda * 10)/ 10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.discovery_questions * 10)/ 10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.knowledge_check * 10)/ 10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.personal_story * 10)/ 10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.finance_assessment_page1 * 10)/ 10)}</td>
<td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.finance_assessment_page2 * 10)/ 10)}</td>
<td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.lazy_equity_smsf * 10)/ 10)}</td>
<td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.cashflow_sheet * 10)/ 10)}</td>
<td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.ultimate_property_planner * 10)/ 10)}</td>
<td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.freedom_criteria * 10)/ 10)}</td>
<td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.recap_decision * 10)/ 10)}</td>
<td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.secure_property_session * 10)/ 10)}</td>
<td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.ask_for_referral * 10)/ 10)}</td>
<td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.expectations_decision * 10)/ 10)}</td>
<td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Criteria_Averages.final_recap * 10)/ 10)}</td>

                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  color: '#1f2937'
}}>{(Math.floor(session.Average_duration * 10)/ 10)}</td>
                    <td style={{
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  whiteSpace: 'normal',
  fontSize: '0.875rem',
  fontWeight: 500,
  color: '#2563eb' // Blue 600
}}>{(Math.floor(session.Overall_Average * 10)/ 10)}</td>
                  </tr>
                ))}
              </tbody>
        </table>
        </div>
        <div className='mt-2'>
        <SummaryAnalysisPagination
        currentPage={currentPage}
        totalPages={totalPages}
        tempPage={tempPage}
        setCurrentPage={setCurrentPage}
        updateTempPage={updateTempPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
     
      />
      </div>
        </div>
      )}
     
    </div>
  );
}
