import React, { useState, useRef, useEffect } from "react";
import Papa from "papaparse";
import axios from "axios";
import { bulkUploadData } from "../../api/userApi";

const UploadData = ({ userDataUpdate }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [isUploadEnabled, setIsUploadEnabled] = useState(false);
  const [loading, updateLoading] = useState(false);

  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile.type !== "text/csv") {
      return alert("Please select a valid CSV file.");
    }
    if (selectedFile) {
      Papa.parse(selectedFile, {
        complete: (result) => {
          const csvData = result.data;
          const filteredData = csvData.filter((row) => {
            const phone_number = row["Phone"] ? row["Phone"].trim() : "";
            console.log("Phone Number in CSV: ", phone_number);
            if (!phone_number) {
              return false;
            }
            let isValidPhoneNumber = false;
            const phoneLength = phone_number.length;
            if (phoneLength === 9) {
              console.log("Number length 9");
              isValidPhoneNumber = /^\d+$/.test(phone_number);
              if (isValidPhoneNumber) {
                console.log("+61 Concatinated");
                row["Phone"] = "+61" + phone_number;
              }
            } else if (phoneLength === 11) {
              console.log("Number length 11");
              isValidPhoneNumber = /^\d+$/.test(phone_number);
              if (isValidPhoneNumber) {
                console.log("+ Concatinated");
                row["Phone"] = "+" + phone_number;
              }
            } else if (phoneLength === 12) {
              console.log("Number lenth 12");
              isValidPhoneNumber = phone_number.startsWith("+");
            } else {
              console.log("In Else PhoneLength ", phoneLength);
            }
            return isValidPhoneNumber;
          });
          setFilteredData(filteredData);
          console.log("File uploaded successfully:", selectedFile.name);
          console.log("Filtered Data:", filteredData);
          setIsUploadEnabled(true);
        },
        header: true,
      });
    } else {
      alert("Please select a CSV file to upload.");
    }
  };

  const handleUpload = async () => {
    if (isUploadEnabled) {
      updateLoading(true);
      try {
        const response = await bulkUploadData(filteredData);
        userDataUpdate(true);
        updateLoading(false);
        alert(response.data.message);
        fileInputRef.current.value = null;
      }
      catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
          alert(error.response.data.error);
        } else if (error.message) {
          alert(error.message);
        } else {
          alert("An error occurred");
        }
      }
    } else {
      alert("Please extract and filter the data first.");
    }
  };

  return (
    <>

      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        className="form-control"
        id="file-upload"
        style={{  fontSize: 12, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        ref={fileInputRef}
      />
      {loading === true ? (
        <button className="btn" disabled={true} style={{ fontSize: 12, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, backgroundColor: "#9ecfe8", width: 105, color: "black", border: 0 }}>UPLOADING...</button>
      ) : (
        <button className="btn" onClick={handleUpload} style={{ fontSize: 12, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, backgroundColor: "#1f9ad6", width: 105, color: "white", border: 0 }}>
          UPLOAD
        </button>
      )}
    </>
  );
};

export default UploadData;
